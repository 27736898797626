import { useEffect, useState } from 'react';
import AppDetailHeader from '../../components/AppHeaderDetail';
import Header from '../../components/Header';
import Metadata from './Metadata';
import './index.css';

import { useParams } from 'react-router-dom';
import { Store } from 'react-notifications-component';


import {
  Navigate
} from "react-router-dom";
import request from '../../helpers/request';
import appStore from '../../stores/appStores';
import SmartEditor from './SmartEditor';
import accountStore from '../../stores/accountStore';
import { observer } from 'mobx-react-lite';


function ButtonLoading({ color = "white" }) {
  return (
    <div class="lds-ring">
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
    </div>
  )
}

const handleKeyPress = (event) => {
  if ((event.metaKey || event.ctrlKey) && event.key === 's') {
    event.preventDefault(); // Tarayıcının varsayılan kaydetme işlemi engellenir.
    if (appStore.isEdited !== null) {
      appStore.save().then(() => {

      })
    } else {
      Store.removeAllNotifications()
      Store.addNotification({
        title: "Warning",
        message: `There are no changes to be saved`,
        container: "top-right",
        insert: "top",
        type: "warning",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }

    // Burada kendi özel kaydetme işleminizi yapabilirsiniz.
  }
}

const AppDetail = (props) => {
  const { id } = useParams()
  const [isReady, setIsReady] = useState(null);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const refresh = localStorage.getItem("token");
    if (refresh) {
      request("app/get/app/" + id).then(async (resp) => {
        if (!resp.done) {
          localStorage.removeItem("token");
          window.location = "/login";
        }

        appStore.setCurrent(resp.current);
        appStore.setEditableVersion(resp.current);
        appStore.setHistory(resp.history);
        appStore.setSelectedApp(resp.app);
        appStore.setLocale(resp.app.primaryLocale);
        accountStore.getAccount();
        if (appStore.apps.length === 0) {
          await appStore.getSecrets();
          await appStore.getApps();
        }
        setIsReady(true)
      })
      window.addEventListener('keydown', handleKeyPress)

    } else {
      setIsReady(false)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    }

  }, [id])


  if (isReady === false) {
    return (
      <Navigate to={"/login"} />
    )
  }

  return (
    <div className='app_detail_container'>
      <Header pathKey="detail" />
      {isReady === null ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 120 }} >
        <ButtonLoading />
      </div> : <>
        <AppDetailHeader type={"detail"} setTab={setTab} />
        {tab === 0 ? <Metadata /> : <SmartEditor />}
      </>}
    </div>
  );
}

export default AppDetail;
