import React, { useState } from 'react'
import "./index.css";
import KeywordInput from '../KeywordInput';
import copy_icon from "../../assets/copy-clickboard.webp";
import copy_done from "../../assets/copy-done.webp";
import { observer } from 'mobx-react-lite';
import appStore from '../../stores/appStores';
import question from "../../assets/question.webp";
import { event } from '../../helpers/events';
import mixpanel from '../../helpers/mixpanel';

const CustomTitle = observer(({ title, editKey }) => {

  const [hover, setHover] = useState(false)

  const { duplicates } = appStore;

  const showDot = editKey && duplicates[editKey].length > 0

  return (
    <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
      <span className='custom_input_title' >{title}</span>
      {showDot && <div onMouseEnter={() => {
        setHover(true)
      }}
        onMouseLeave={() => {
          setHover(false)
        }} style={{ position: "relative", backgroundColor: "#FFD43A", width: 10, height: 10, marginLeft: 8, borderRadius: 5 }}>
        {hover && <div className='duplicate_hover_container'>
          <span style={{ color: "white" }}>
            {duplicates[editKey].toString()}
          </span>

        </div>}
      </div>
      }

    </div>

  )
})

const FirstValue = observer(({ value, editKey }) => {
  const { current, locale, isEdited } = appStore;
  const txt = current.metadata[locale][editKey]
  if (!isEdited) return <div />;
  if (!isEdited[locale]?.hasOwnProperty(editKey)) return <div />
  if (txt === value) return <div />
  return (
    <span style={{ color: "rgba(255,255,255,0.5)", marginTop: 8 }}>
      {txt}
    </span>
  )
});

function CustomInput({ title, value, info, editable, onChange = () => { }, multiline, maxLength = -1, placeholder, separated, extraStyle, copy = true, flag = null, showEdited = false, editKey = null }) {

  const [hover, setHover] = useState(false);
  const [copyDone, setCopyDone] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const _value = value ? value : "";

  const renderInput = () => {
    if (separated) {
      let val = value?.trim().replace(/(^,)|(,$)/g, "")?.split(/[\s,]+/u);
      return (
        <KeywordInput
          maxLength={maxLength}
          value={val}
          editable={editable}
          onChange={onChange} />
      )
    }
    if (multiline) {
      return (
        <textarea
          onChange={(e) => onChange(e.target.value)}
          value={_value}
          placeholder={placeholder}
          disabled={!editable}
          maxLength={maxLength}
          className={editable ? 'custom_input_input custom_textarea' : 'custom_input_input custom_textarea custom_input_input_inactive'} />
      )
    } else {
      return (
        <input
          onChange={(e) => {
            onChange(e.target.value)
          }}
          value={_value}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={!editable}
          className={editable ? 'custom_input_input' : 'custom_input_input custom_input_input_inactive'} />
      )
    }
  }
  return (
    <div
      style={extraStyle}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      className='custom_input_container' >
      <CustomTitle title={title} editKey={editKey} />
      <span className='input-span-outer' >
        {flag && <img src={flag} className='input-span-flag' />}
        {renderInput()}
      </span>
      {!separated && (copy && hover) &&
        <div onClick={() => {
          mixpanel.track("ASO_COPY_CLICK", { field: title })
          window.navigator.clipboard.writeText(value);
          setCopyDone(true);
          setTimeout(() => {
            setCopyDone(false);
          }, 1000);
        }} style={{ top: 45 }} className='copy-to-clickboard-container cursor' >
          <img className='copy-to-clickboard-image' src={copyDone ? copy_done : copy_icon} />
        </div>}
      {info && <div
        onMouseLeave={() => {
          setShowInfo(false);
        }}
        onMouseEnter={() => {
          mixpanel.track(`ASO_CONNECT_DEVELOPER_HOVER_INFO${title.replaceAll(" ", "_").toUpperCase()}`)
          setShowInfo(true);
        }}
        style={{ top: 48 }} className='custom-input-question-container cursor' >
        {showInfo && info}
        <img className='custom-input-question-image' src={question} />
      </div>}
      <div style={{ display: "flex", flexDirection: "row", alignSelf: "stretch", justifyContent: showEdited ? "space-between" : "flex-end" }}>
        {showEdited && <FirstValue value={value} editKey={editKey} />}
        {!separated && maxLength > 0 && <span className='custom_input_maxlength' >{`${maxLength - (value?.length ? value?.length : 0)}/${maxLength}`}</span>}
      </div>

    </div>
  )
}

export default CustomInput