import { observer } from "mobx-react-lite"
import Search from "../Search"
import Selector from "../Selector"
import TableHeader from "../TableHeader"
import "./index.css"
import React, { useEffect, useRef, useState } from 'react'
import staticStore from "../../stores/staticStore"
import { FormattedNumber } from "react-intl"
import DropdownModal from "../DropdownModal"
import tick from "../../assets/tick.webp";
import DropLine from "../../assets/DropLine.webp";
import Button from "../Button"
import modalStore from "../../stores/modalStore"
import inAppStore from "../../stores/inAppStore"

function ButtonLoading({ color = "white" }) {
  return (
    <div class="lds-ring">
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
    </div>
  )
}

const PricingPointList = observer(({ item, data }) => {
  const [pricingPoints, setPricingPoints] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const points = await inAppStore.getPricingPoints(data.appId, data.inAppId, [item.territory]);
      setPricingPoints(points);
    }
    fetchData();
    return () => {
      console.log("dğştü")
    }
  }, [])

  if (!pricingPoints) {
    return (
      <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} >
        <ButtonLoading />
      </div>
    )
  }
  return (
    pricingPoints.map(e => {
      return <div className="all_apps_app_container" onClick={() => {
        inAppStore.setSubsPoint(item, e);
      }} >
        {e.pricing.customerPrice == item.pricing.customerPrice ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
        <span className="all_apps_app_text" >
          <FormattedNumber value={e.pricing.customerPrice}
            style="currency" currency={e.currency} />
        </span>
      </div>
    })
  )
})

const TableHeaderRow = ({ data }) => {
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <div className="pricing-edit-modal-table-header-row" >
        <Search onChange={(e) => {
          inAppStore.searchInappDetails(e.target.value);
        }} placeholder={"Search Country"} />
        <Button loading={loading} loadingColor="black" text="Save" color="white" textStyle={{ color: "black" }}
          onClick={async () => {
            setLoading(true);
            await inAppStore.saveDetails(data.appId, data.inAppId, data.basePrice);
            await inAppStore.getInApps(data.appId);
            setLoading(false);
            modalStore.setShow(false);
          }} />
      </div>
      <TableHeader
        firstItem={"Countries"}
        items={[
          "Current Price",
        ]} />
    </div>
  )
}

const TableRow = observer(({ item, data }) => {
  const { countries } = staticStore;
  const divRef = useRef()
  const [show, setShow] = useState(false);
  let flag = countries.findIndex(c => c.iso3 === item.territory);
  return (
    <div className="table-row-container">
      <img className="pricing-edit-modal_item_bottom_card_flag" src={countries[flag].flag} />
      <span className="table-row-item-title" style={{ flex: 2, marginLeft: 10 }} >
        {item.country}</span>
      <div className="table-header-right-container" style={{ flex: 1, marginLeft: -24 }} >
        <span ref={divRef} onClick={async () => {
          setShow(!show);
        }} className="table-row-item-title"  >
          {
            show ? <DropdownModal
              containerStyle={{ width: 240, zIndex: 999999999 }}
              data={() => {
                return <PricingPointList data={data} item={item} key={new Date().getTime()} />
              }}
              onClose={() => {
                setShow(false)
              }} divRef={divRef} right={150} top={50} >
            </DropdownModal> : null
          }
          <span className="price-edit-modal-table-row-item-price" >
            <FormattedNumber value={item.pricing.customerPrice}
              style="currency" currency={item.currency} />
            <img src={DropLine} className="price-edit-modal-table-row-item-dropline" />
          </span>
        </span>
      </div>
    </div >
  )
})

function InAppEditModal({ data }) {
  const { inAppDetails, filteredDetails } = inAppStore;
  let array = filteredDetails ? filteredDetails : inAppDetails;
  if (!inAppDetails) return null;
  return (
    <div className='pricing-edit-modal-container' style={{ overflow: "scroll" }} >
      <div className="pricing-edit-modal-inner-container" >
        <TableHeaderRow data={data} />
        {
          array?.prices?.map(e => {
            return <TableRow key={`${new Date().getTime()}####${array.length}###${e.country}`} data={data} item={e} />
          })
        }
      </div>
    </div>
  )
}

export default observer(InAppEditModal);