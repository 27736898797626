import "./index.css"
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import ratingStore from '../../stores/ratingStore';
import StarsFill from "../../assets/StarFill.webp"
import StarsEmpty from "../../assets/StarEmpty.webp"
import Button from "../Button";
import PrevIcon from "../../assets/PrevIcon.webp"
import NextIcon from "../../assets/NextIcon.webp"
import RatingTopButton from "../RatingTopButton";
import { SORT_ARRAY } from "../../helpers/statics";
import tick from "../../assets/tick.webp";
import DropLine from "../../assets/DropLine.webp";
import { Store } from "react-notifications-component";
import DropdownModal from "../DropdownModal";
import staticStore from "../../stores/staticStore";

const TranslateButton = observer(({ body, response, title, type, onClick, onCancel }) => {
  const divRef = useRef()
  const { google } = staticStore;
  const { language } = ratingStore

  let _language = google.find(e => e.code === language)
  const languages = type === "response" ? [{ language: "Customer Lang.", code: null }, ...google] : google;

  const [show, setShow] = useState(false);
  const [translated, setTranslated] = useState(false);
  if (type === "response") {
    return (
      <div
        ref={divRef}
        className="ratings_table_item_translate_button" style={{ marginTop: 16, }} >
        <div onClick={() => {
          if (translated) return
          setShow(!show);
        }} style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }} >
          <span style={{ opacity: translated ? .5 : 1 }} className="ratings_table_item_translate_language_title" >{translated ? translated.language : "Customer Lang."}</span>
          {!translated && <img className="ratings_table_item_translate_drop" src={DropLine} />}
        </div>
        <span onClick={async () => {
          if (translated) {
            onCancel();
            setTranslated(false);
          } else {
            const resp = await ratingStore.translateResponse(body, response);
            onClick(resp.text);
            setTranslated({ language: "Customer Lang.", code: null });
          }
        }} className="ratings_table_item_translate_translate_text" >
          {translated ? "Undo" : `Translate`}
        </span>
        {show ? <DropdownModal
          data={() => {
            return languages.map((item, index) => {
              let isSelected = language === item.code;
              return (
                <div onClick={async () => {
                  if (language !== item.code) {
                    if (item.code) {
                      ratingStore.setLanguage(item.code);
                    }
                  }
                  if (item.code) {
                    const resp = await ratingStore.translateReview(item.code, response);
                    onClick(resp.text);
                    setTranslated(item);
                    setShow(false);
                  } else {
                    const resp = await ratingStore.translateResponse(body, response);
                    onClick(resp.text);
                    setTranslated(item);
                    setShow(false);
                  }
                }} key={"flags#" + index} className="all_apps_app_container cursor">
                  {isSelected ? <div style={{ width: 32, height: 32, display: "flex", justifyContent: "center", alignItems: "center" }}><img style={{ width: 16, height: "auto", objectFit: "contain" }} src={tick} /></div> : <div style={{ width: 32, height: 32 }} />}
                  <span className="all_apps_app_text">
                    {item.language}
                  </span>
                </div>
              )
            })
          }}
          onClose={() => {
            setShow(false)
          }} divRef={divRef} right={-110} top={40} /> : null}
      </div>
    )
  } else {
    return (
      <div
        ref={divRef}
        className="ratings_table_item_translate_button" style={{ marginTop: 16, }} >
        <div onClick={() => {
          if (translated) return
          setShow(!show);
        }} style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }} >
          <span style={{ opacity: translated ? .5 : 1 }} className="ratings_table_item_translate_language_title" >{_language ? _language.language : "Select"}</span>
          {!translated && <img className="ratings_table_item_translate_drop" src={DropLine} />}
        </div>
        <span onClick={async () => {
          if (translated) {
            onCancel();
            setTranslated(false);
          } else if (!_language) {
            setShow(!show)
          } else {
            const resp = await ratingStore.translateReview(language, body);
            const second = await ratingStore.translateReview(language, title);
            onClick(resp.text, second.text);
            setTranslated(true);
          }
        }} className="ratings_table_item_translate_translate_text" >
          {translated ? "Undo" : `Translate`}
        </span>
        {show ? <DropdownModal
          data={() => {
            return languages.map((item, index) => {
              let isSelected = language === item.code;
              return (
                <div onClick={async () => {
                  ratingStore.setLanguage(item.code);
                  const resp = await ratingStore.translateReview(item.code, body);
                  const second = await ratingStore.translateReview(item.code, title);
                  onClick(resp.text, second.text);
                  setTranslated(true);
                  setShow(false);
                }} key={"flags#" + index} className="all_apps_app_container cursor">
                  {isSelected ? <div style={{ width: 32, height: 32, display: "flex", justifyContent: "center", alignItems: "center" }}><img style={{ width: 16, height: "auto", objectFit: "contain" }} src={tick} /></div> : <div style={{ width: 32, height: 32 }} />}
                  <span className="all_apps_app_text">
                    {item.language}
                  </span>
                </div>
              )
            })
          }}
          onClose={() => {
            setShow(false)
          }} divRef={divRef} right={-110} top={40} /> : null}
      </div>
    )
  }
})

const RatingCard = ({ item }) => {
  let _item = item;
  const stars = Array(item.rating).fill(0);
  const empty = Array(5 - item.rating).fill(0);
  let country = ratingStore.getReviewCountryData(item.territory);
  const [replyMode, setReplyMode] = useState(false);
  const [edit, setEdit] = useState(false);
  const [body, setBody] = useState(item.body);
  const [title, setTitle] = useState(item.title);
  const [response, setResponse] = useState(item.response?.body ? item.response?.body : "");
  const [original, setOriginal] = useState("");
  const [replied, setReplied] = useState(item.response);
  const [buttonLoading, setButtonLoading] = useState(false);
  let badge = null;
  let statusColor;
  if (item.response) {
    statusColor = "#39ac5d";
    badge = { text: "ANSWERED", bgColor: "rgba(57, 172, 93, .1)", color: "#39ac5d" }
  } else if (item.rating === 1 || item.rating === 2) {
    statusColor = "#C1173C";
    badge = { text: "UNANSWERED", bgColor: "rgba(193, 23, 60, .1)", color: "#C1173C" }
  } else {
    badge = { text: "UNANSWERED", bgColor: "rgba(221, 196, 42, .1)", color: "#DDC42A" }
    statusColor = "#DDC42A";
  }

  return (
    <div className='ratings_table_item_container' >
      <div style={{ backgroundColor: statusColor }} className="ratings_table_item_container_top" />
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: 16 }} >
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }} >
          {stars.map(() => {
            return <img src={StarsFill} className="ratings_table_item_star" />
          })}
          {empty.map(() => {
            return <img src={StarsEmpty} className="ratings_table_item_star" />
          })}
          <span className="ratings_table_item_nick" >by {item.nick}</span>
        </div>
        {badge && <span style={{ backgroundColor: badge.bgColor, color: badge.color }} className="ratings_table_item_badge" >{badge.text}</span>}
      </div>
      <span className="ratings_table_item_title" >{title}</span>
      <span className="ratings_table_item_body" >{body}</span>
      <TranslateButton onCancel={() => { setBody(item.body); setTitle(item.title) }} onClick={(text, title) => { setBody(text); setTitle(title) }} title={item.title} response={response} body={item.body} type={"review"} />
      <div className="ratings_table_item_bottom" >
        <div style={{ display: "flex", flexDirection: "row", }} >
          <div className="ratings_table_item_bottom_card" >
            <img src={country.flag} className="ratings_table_item_bottom_card_flag" />
            {country.name}
          </div>
          <div className="ratings_table_item_bottom_card" >
            {new Date(item.createdDate).toLocaleDateString("en-US", { month: "long", day: "2-digit", year: "numeric" })}
          </div>
        </div>
        {!replyMode && !item.response && <div onClick={() => {
          setReplyMode(true)
          setReplied(item.response)
        }} className="ratings_table_item_bottom_reply" >
          Reply
        </div>}
      </div>
      {
        replyMode && <div className="ratings_table_item_reply_mode_container" >
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
            <span className="ratings_table_item_reply_mode_title" >
              Reply
            </span>
            <span className="ratings_table_item_reply_mode_characters"  >
              {5970 - response.length} characters
            </span>
          </div>
          <textarea value={response} onChange={(e) => { setResponse(e.target.value) }} className="ratings_table_item_reply_mode_textarea" />
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} >
            {response.length > 0 ? <TranslateButton onCancel={() => { setResponse(original) }} onClick={(text) => { setOriginal(response); setResponse(text) }} body={item.body} response={response} type={"response"} /> : <div />}
            <div style={{ display: "flex", marginTop: 12, flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
              <Button onClick={() => setReplyMode(false)} style={{ backgroundColor: "transparent", border: "1px solid white" }} textStyle={{ color: "white" }} text="Cancel" />
              {/* <Button style={{ backgroundColor: "white" }} textStyle={{ color: "black" }} text="Draft Replies" /> */}
              <Button loading={buttonLoading} onClick={async () => {
                if (!response) {
                  Store.addNotification({
                    title: "Warning!",
                    message: "Please write your response.",
                    container: "top-right",
                    insert: "top",
                    type: "warning",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  })
                  return
                }
                setButtonLoading(true);
                const resp = await ratingStore.replyRating(item._id, response)
                // const resp = { review: { lastModifiedDate: new Date(), response: { body: response } } }
                setResponse(resp.review.response.body);
                setReplyMode(false);
                setReplied(resp.review.response);
                setEdit(false);
                setButtonLoading(false);
              }} text="Submit" />
            </div>
          </div>
        </div>
      }
      {
        replied && <div className="ratings_table_item_reply_mode_container" >
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
            <span className="ratings_table_item_reply_mode_title" >
              Reply (Replied on {new Date(replied.lastModifiedDate).toLocaleDateString("en-US", { month: "long", day: "2-digit", year: "numeric" })})
            </span>
            <span className="ratings_table_item_reply_mode_characters"  >
              {5970 - response.length} characters
            </span>
          </div>
          <textarea value={response} disabled={!edit} onChange={(e) => { setResponse(e.target.value) }} className={edit ? "ratings_table_item_reply_mode_textarea" : "ratings_table_item_reply_mode_textarea ratings_table_item_reply_mode_textarea_disabled"} />
          {edit && <TranslateButton onCancel={() => { setResponse(original) }} onClick={(text) => { setOriginal(response); setResponse(text) }} body={replied.body} response={response} type={"response"} />}
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} >
            <div />
            <div style={{ display: "flex", marginTop: 12, flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
              <Button
                onClick={() => {
                  setEdit(!edit);
                  setResponse(replied.body)
                }}
                style={{ backgroundColor: "white" }} textStyle={{ color: "black" }} text={edit ? "Cancel" : "Edit Reply"} />
              {edit && <Button loading={buttonLoading} onClick={async () => {
                if (!response) {
                  Store.addNotification({
                    title: "Warning!",
                    message: "Please write your response.",
                    container: "top-right",
                    insert: "top",
                    type: "warning",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  })
                  return
                }
                setButtonLoading(true);
                const resp = await ratingStore.replyRating(item._id, response)
                // const resp = { review: { lastModifiedDate: new Date(), response: { body: response } } }
                setReplyMode(false);
                setReplied(resp.review.response);
                setResponse(resp.review.response.body);
                setEdit(false);
                setButtonLoading(false);
              }} text="Submit" />}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const Paging = observer(() => {
  const { page, reviews } = ratingStore;
  const { pageCount } = reviews;
  let first, second, third;
  let prevDisabled, nextDisabled;
  if (pageCount === 0) {
    nextDisabled = true;
    prevDisabled = true;
  } else if (pageCount === 1 && page === 1) {
    nextDisabled = true;
  }
  if (page === 0) {
    first = 1; second = 2; third = 3;
    prevDisabled = true;
  } else if (pageCount === page + 1 && pageCount + 1 > 2) {
    first = page - 1; second = page; third = page + 1;
    nextDisabled = true;
  } else {
    first = page; second = page + 1; third = page + 2;
  }
  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
      <span onClick={() => {
        if (prevDisabled) return null
        ratingStore.setPage(page - 1);
      }} className="rating_table_paging_left_arrow" >
        <img style={{ opacity: prevDisabled ? .1 : 1 }} className="rating_table_paging_prev_icon" src={PrevIcon} />
      </span>
      <span onClick={() => {
        ratingStore.setPage(first - 1);
      }} className={first === page + 1 ? "rating_table_paging_item_border rating_table_paging_item" : "rating_table_paging_item"} >
        {first}
      </span>
      {pageCount + 1 > 1 && <span onClick={() => {
        ratingStore.setPage(second - 1);
      }} className={second === page + 1 ? "rating_table_paging_item_border rating_table_paging_item" : "rating_table_paging_item"} >
        {second}
      </span>}
      {pageCount + 1 > 2 && <span onClick={() => {
        ratingStore.setPage(third - 1);
      }} className={third === page + 1 ? "rating_table_paging_item_border rating_table_paging_item" : "rating_table_paging_item"} >
        {third}
      </span>}
      <span onClick={() => {
        if (nextDisabled) return null
        ratingStore.setPage(page + 1);
      }} className="rating_table_paging_right_arrow" >
        <img style={{ opacity: nextDisabled ? .1 : 1 }} className="rating_table_paging_prev_icon" src={NextIcon} />
      </span>
    </div>
  )
})

const SortModal = observer(() => {
  const { sort } = ratingStore;
  return (
    <RatingTopButton title={sort?.title ? sort?.title : "Sort By"} data={() => {
      return SORT_ARRAY.map((item, index) => {
        return (
          <div onClick={async () => {
            ratingStore.setSort(item);
          }} key={"selectedVersion#" + index} className="all_apps_app_container cursor">
            {sort?.title === item?.title ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
            <span className="all_apps_app_text">
              {item.title}
            </span>
          </div>
        )
      })
    }} />
  )
})

function RatingTable() {
  const { reviews } = ratingStore;
  if (!reviews) return null
  return (
    <div>
      <div style={{ display: "flex", marginTop: 30, marginBottom: 20, flexDirection: "row", alignItems: "flex-end", justifyContent: "space-between" }} >
        <span style={{ fontSize: 16, fontWeight: 400, color: "white" }} >
          {reviews.reviewCount} reviews
        </span>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
        </div>
      </div>
      {
        reviews.reviews.map(item => {
          return <RatingCard key={item._id} item={item} />
        })
      }
      <div style={{ display: "flex", marginTop: 30, marginBottom: 100, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} >
        <span>
        </span>
        <Paging />
      </div>
    </div>
  )
}

export default observer(RatingTable);