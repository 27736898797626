import { makeAutoObservable } from "mobx";
import request from "../helpers/request";
import { DATE_FILTER_ARRAY, SORT_ARRAY, STARS_ARRAY, WITH_RESPONSE_ARRAY } from "../helpers/statics";
import staticStore from "./staticStore";
import { Store } from "react-notifications-component";

class RatingStore {

  selectedVersion = { versionString: "All Versions" };
  history = [];
  app = null;
  stars = STARS_ARRAY[0];
  stats = null;
  reviews = null;
  page = 0;
  responseFilter = WITH_RESPONSE_ARRAY[0];
  sort = SORT_ARRAY[0];
  terriority = null;
  dateFilter = DATE_FILTER_ARRAY[0];
  language = localStorage.getItem("language");

  constructor() {
    makeAutoObservable(this);
  }

  getRatings = async (_id) => {
    const reviews = await request(`reviews/fetch/${_id}`, "post", {
      page: this.page,
    })
    this.reviews = reviews;
    const stats = await request(`reviews/stats/${_id}`)
    this.stats = stats.stats;
  }

  setPage = (page) => {
    if (page !== this.page) {
      this.page = page;
      this.getRatingsWithFilter();
    }
  }

  setDateFilter = (dateFilter) => {
    if (this.dateFilter !== dateFilter) {
      this.dateFilter = dateFilter;
      this.getRatingsWithFilter();
    }
  }

  setResponseFilter = (filter) => {
    if (filter !== this.responseFilter) {
      this.responseFilter = filter;
      this.getRatingsWithFilter();
    }
  }

  setTerriority = (ter) => {
    if (this.terriority !== ter) {
      this.terriority = ter;
      this.getRatingsWithFilter();
    }
  }

  replyRating = async (id, response) => {
    try {
      const resp = await request(`reviews/reply/${id}`, "POST", { response });
      return resp;
    } catch (error) {
      console.log('error: ', error);
      Store.addNotification({
        title: "Error!",
        message: "Something went wrong!",
        container: "top-right",
        insert: "top",
        type: "error",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }
  }

  getRatingsWithFilter = async () => {
    let body = {
      filter: {
      },
      page: this.page,
    }
    if (this.stars.value) {
      body.filter["rating"] = this.stars.value;
    }
    if (this.dateFilter) {
      body.date = {};
      body["date"]["start"] = this.dateFilter.value;
      body["date"]["end"] = new Date().getTime();
    }
    if (this.terriority?.iso3) {
      body.filter["territory"] = this.terriority.iso3;
    }
    if (this.sort) {
      body.sort = this.sort.value;
    }
    if (this.responseFilter?.value !== null) {
      body.filter["withResponse"] = this.responseFilter.value;
    }
    const reviews = await request(`reviews/fetch/${this.app._id}`, "post", body)
    this.reviews = reviews;
  }

  setStar = (stars) => {
    if (this.stars.value !== stars.value) {
      this.page = 0;
      this.stars = stars;
      this.getRatingsWithFilter();
    }
  }

  setSort = (sort) => {
    if (this.sort !== sort) {
      this.sort = sort;
      this.getRatingsWithFilter();
    }
  }

  setSelectedVersion = (version) => {
    this.selectedVersion = version
  }

  setHistory = (history) => {
    this.history = history;
  }

  setApp = (app) => {
    this.app = app;
  }

  translateResponse = async (review, reply) => {
    const resp = await request("reviews/translateReply", "POST", {
      review,
      reply,
    })
    return resp;
  }

  translateReview = async (to, text) => {
    const resp = await request("reviews/translateReview", "POST", {
      to,
      text,
    })
    return resp;
  }

  getReviewCountryData = (ter) => {
    const { countries } = staticStore;
    if (!countries) return {}
    let fIndex = countries.findIndex(e => e.iso3 === ter)
    return countries[fIndex];
  }

  setLanguage = (code) => {
    localStorage.setItem("language", code);
    this.language = code;
  }

}

const ratingStore = new RatingStore();
export default ratingStore;