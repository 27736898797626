import React, { useRef, useState } from 'react'
import DropdownModal from '../DropdownModal'
import "./index.css";
import staticStore from '../../stores/staticStore';
import Button from '../Button';
import appStore from '../../stores/appStores';
import { observer } from 'mobx-react-lite';
import modalStore from '../../stores/modalStore';
import mixpanel from '../../helpers/mixpanel';

function AddNewLocale() {

  const localeRef = useRef();

  const { flags } = staticStore;
  const { addableLocales } = appStore;

  const [showLocale, setShowLocale] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState(null);

  return (
    <div className='modal_locale_inner_container' >
      <span className='dropdown-input-title' >New Language</span>
      <div ref={localeRef} onClick={() => {
        setShowLocale(!showLocale)
      }} className={'dropdown-input cursor'} style={{ marginRight: 20 }} >
        <span className={"dropdown-input-text"} >{selectedLocale ? selectedLocale.title : "Select Language"}</span>
        {showLocale && <DropdownModal
          containerStyle={{ width: 450, backgroundColor: "#1B1B2C", zIndex: 999999999 }}
          divRef={localeRef}
          onClose={() => setShowLocale(false)}
          right={0}
          top={55}
          data={() => {
            return addableLocales.sort((a, b) => {
              const _a = flags[a];
              const _b = flags[b]
              if (_a.name[0] < _b.name[0]) {
                return -1;
              }
              if (_a.name[0] > _b.name[0]) {
                return 1;
              }
              return 0
            }).map((item, index) => {
              const flagInfo = flags[item];
              return (
                <div onClick={() => {
                  setShowLocale(false);
                  mixpanel.track("ASO_NEW_LANGUAGE_MODAL_SELECT")
                  setSelectedLocale({
                    code: item,
                    title: flagInfo.name,
                    flag: flagInfo.flag
                  });
                }} key={"flags#" + index} className="all_apps_app_container cursor">
                  <img src={flagInfo.flag} className="all_apps_app_icon" style={{ marginLeft: 20 }} />
                  <span className="all_apps_app_text">
                    {flagInfo.name}
                  </span>
                </div>
              )
            })
          }}
        />}
      </div>
      <Button
        text="Save"
        loading={showLoading}
        loadingColor={"black"}
        onClick={async () => {
          setShowLoading(true)
          await appStore.addNewLang(selectedLocale);
          mixpanel.track("ASO_NEW_LANGUAGE_MODAL_SAVE");
          setShowLoading(false)
          modalStore.setShow(false);
        }}
        style={{ width: "100px", alignSelf: "center", marginTop: "40px", backgroundColor: "white" }}
        textStyle={{ color: "#000000" }}
      />
    </div>
  )
}

export default observer(AddNewLocale);