import React from 'react'
import SmartEditorInput from '../SmartEditorInput'
import "./index.css"
import Selector from '../Selector'
import appStore from '../../stores/appStores';
import { observer } from "mobx-react-lite"
import staticStore from '../../stores/staticStore';
import { MAX_LENGTH } from '../../helpers/statics';
import selectionStore from '../../stores/selectionStore';
import mixpanel from '../../helpers/mixpanel';

function SmartEditorRow({ country, type, multiline }) {

  const { editableVersion } = appStore
  const editable = editableVersion?.editable;
  const item = editableVersion?.metadata[country][type]
  const { flags } = staticStore;
  const { selections } = selectionStore;
  return (
    <div className='se-row-container' >
      <Selector onPress={() => {
        if (!editable) return
        selectionStore.setSelected(country)
        mixpanel.track("ASO_SMART_EDITOR_LANGUAGE_SELECT");
      }} isSelected={selections[country]?.isSelected} />
      <span className='se-row-country' >
        <img src={flags[country].flag} className='se-row-country-icon' />
        {flags[country].name}
      </span>
      <SmartEditorInput
        editable={editable}
        separated={false}
        maxLength={MAX_LENGTH[type]}
        multiline={multiline}
        key={`${country}###${type}####smart-editor-input`}
        onChange={(value) => {
          appStore.editSelectedMetadataWithLocale(country, type, value)
        }} value={item} />
    </div>
  )
}

export default observer(SmartEditorRow);