import { useParams } from "react-router-dom"
import AppDetailHeader from "../../components/AppHeaderDetail"
import Header from "../../components/Header"
import "./index.css"
import React, { useEffect, useState } from 'react'
import request from "../../helpers/request"
import appStore from "../../stores/appStores"
import accountStore from "../../stores/accountStore"
import ratingStore from "../../stores/ratingStore"
import RatingStats from "../../components/RatingStats"
import RatingTable from "../../components/RatingTable"
import staticStore from "../../stores/staticStore"

function ButtonLoading({ color = "white" }) {
  return (
    <div class="lds-ring">
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
    </div>
  )
}

export default function Rating() {

  const { id } = useParams()
  const [isReady, setIsReady] = useState(null);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const refresh = localStorage.getItem("token");
    const { countries } = staticStore
    if (refresh) {
      request("app/get/app/" + id).then(async (resp) => {
        appStore.setSelectedApp(resp.app);
        if (!resp.done) {
          localStorage.removeItem("token");
          window.location = "/login";
        }
        ratingStore.setHistory(resp.history);
        if (!countries) {
          await staticStore.getStatics();
        }
        appStore.setSelectedApp(resp.app);
        await ratingStore.getRatings(id);
        ratingStore.setApp(resp.app);
        accountStore.getAccount();

        if (appStore.apps.length === 0) {
          await appStore.getSecrets();
          await appStore.getApps();
        }
        setIsReady(true)
      })

    } else {
      setIsReady(false)
    }

    return () => {
    }

  }, [id])

  return (
    <div className='rating_container' >
      <Header pathKey={"rating"} />
      {isReady === null ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 120 }} >
        <ButtonLoading />
      </div> : <>
        <AppDetailHeader type="reviews" setTab={setTab} />
        <div style={{ padding: 40 }} >
          <RatingStats />
          <RatingTable />
        </div>
      </>}
    </div>
  )
}
