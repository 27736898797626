import { useEffect, useState } from "react";
import Header from "../../components/Header"
import "./index.css"
import { observer } from 'mobx-react-lite';
import accountStore from "../../stores/accountStore";
import appStore from "../../stores/appStores";
import Account from "./pages/account";
import StoreConnections from "./pages/storeConnections";
import AppManagment from "./pages/appManagment";
import Billing from "./pages/billing";
import PlanUpgrade from "./pages/planupgrade";
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import profile_icon from "../../assets/profile_icon.webp"
import plan_icon from "../../assets/plan_icon.webp"
import connection_icon from "../../assets/connection_icon.webp"
import apps_icon from "../../assets/apps_icon.webp"

const RenderMenuButton = ({ title, isSelected = false, onClick, icon }) => {

  return (
    <div onClick={onClick} className="settings_menu_button_container cursor">
      <img src={icon} className={isSelected ? "settings_menu_button_icon_selected" : "settings_menu_button_icon"} />
      <span className={isSelected ? "settings_menu_button_text_selected" : "settings_menu_button_text"}>
        {title}
      </span>
    </div>
  )
}

const Settings = observer(() => {

  const comps = [
    {
      key: "account",
      icon: profile_icon,
      title: "Account",
      component: <Account />,
    },
    {
      key: "connections",
      title: "Store Connections",
      icon: connection_icon,
      component: <StoreConnections />,
    },
    {
      key: "apps",
      title: "App Management",
      icon: apps_icon,
      component: <AppManagment />,
    },
    {
      key: "billing",
      title: "Billing",
      icon: plan_icon,
      component: <Billing />,
    },
  ];

  const [searchParams] = useSearchParams();
  let _step = searchParams.get("tab")
  let find = comps.findIndex(e => e.key === _step);
  const [isReady, setIsReady] = useState(null);
  const [selectedTab, setSelectedTab] = useState(find >= 0 ? find : 0);
  const navigate = useNavigate();

  useEffect(() => {
    const refresh = localStorage.getItem("token");
    const { account } = accountStore;
    if (account) {
      setIsReady(true)

    } else if (refresh) {
      accountStore.getAccount().then(() => {
        appStore.getSecrets().then(() => {
          setIsReady(true)
        });
      })
    } else {
      setIsReady(false)
    }
    if (_step) {
      let find = comps.findIndex(e => e.key === _step);
      setSelectedTab(find >= 0 ? find : 0);
      setIsReady(true)
    }
    return () => {

    }

  }, [_step]);

  return (
    <div className="settings_container">
      <Header pathKey={"settings"} />
      {!isReady ? <></> : <div style={{ display: "flex", flex: 1 }}>
        <div className="settings_menu_container">
          <span className="account_settings_text">
            Account Settings
          </span>
          {
            comps.map((item, index) => {
              return <RenderMenuButton onClick={() => {
                setSelectedTab(index);
                navigate(`/settings?tab=${comps[index].key}`);
              }} key={"renderMenuButton#" + index} isSelected={selectedTab === index} title={item.title} icon={item.icon} />
            })
          }
        </div>
        <div className="settings_contenst_container">
          {comps[selectedTab].component}
        </div>
      </div>}
    </div>
  )
});

export default Settings