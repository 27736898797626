import Selector from "../Selector";
import "./index.css"

export default function TableHeader({ firstItem, items, selection }) {

  return (
    <tr className="table-header-row" >
      <span className="table-header-item-title" style={{ flex: 2, marginLeft: 40 }} >{firstItem}</span>
      <div className="table-header-right-container" style={{ flex: items.length }} >
        {
          items.map(e => {
            return <span className="table-header-item-title"  >{e}</span>
          })
        }
      </div>
    </tr>
  )
}