import "./index.css";
import arrow from "../../assets/bottom_arrow_white.webp"
import { observer } from "mobx-react-lite"
import appStore from "../../stores/appStores";
import tick from "../../assets/tick.webp";
import { useRef, useState } from "react";

import staticStore from "../../stores/staticStore";
import DropdownModal from "../DropdownModal";
import modalStore from "../../stores/modalStore";
import AddNewLocale from "../AddNewLocale";
import mixpanel from "../../helpers/mixpanel";

const CountryButton = observer(() => {

  const divRef = useRef()

  const { flags } = staticStore;
  const { locale, editableVersion, current } = appStore
  let editable = editableVersion?.editable;
  const editableVersionKeys = Object.keys(editableVersion?.metadata);

  const [show, setShow] = useState(false);

  return (
    <>
      <div ref={divRef} onClick={() => {
        setShow(!show);
        if (!show) {
          mixpanel.track("ASO_LANGUAGE_BUTTON_CLICKED")
        }
      }} className="country_button cursor">
        <img className="circle_flag" src={flags?.[locale]?.flag} />
        <span className="country_name">{flags?.[locale]?.name}</span>
        <img src={arrow} className="country_bottom_arrow" />
        {show ? <DropdownModal
          addButtonDisabled={!editable}
          addButtonAction={() => {
            appStore.findAddableLocales();
            mixpanel.track("ASO_LANGUAGE_BUTTON_ADD_NEW_CLICKED");
            modalStore.setContent("Add New Language", <AddNewLocale />, false);
          }}
          data={() => {
            return editableVersionKeys.sort((a, b) => {
              const _a = flags[a];
              const _b = flags[b]
              if (_a.name[0] < _b.name[0]) {
                return -1;
              }
              if (_a.name[0] > _b.name[0]) {
                return 1;
              }
              return 0
            }).map((item, index) => {
              const isSelected = appStore.locale === item;
              const flagInfo = flags[item];
              return (
                <div onClick={() => {
                  appStore.setLocale(item);
                  mixpanel.track("ASO_LANGUAGE_BUTTON_SELECT", { lang: item })
                  setShow(false);
                }} key={"flags#" + index} className="all_apps_app_container cursor">
                  {isSelected ? <div style={{ marginLeft: 12, marginRight: 12, display: "flex", justifyContent: "center" }}><img style={{ width: 16, height: "auto", objectFit: "contain" }} src={tick} /></div> : <div style={{ width: 24 }} />}
                  <img src={flagInfo.flag} className="all_apps_app_icon" style={{ marginLeft: isSelected ? 0 : 15 }} />
                  <span className="all_apps_app_text">
                    {flagInfo.name}
                  </span>
                </div>
              )
            })
          }}
          addButtonText={"Add New Language"}
          onClose={() => {
            setShow(false)
          }} divRef={divRef} right={-10} top={45} /> : null}
      </div>
    </>
  )
});

export default CountryButton;