import { useState } from "react"
import "./index.css"
import staticStore from "../../stores/staticStore"


const CountryFlag = ({countryCode,style}) => {

  const [showTool,setShowTool] = useState(false)
  const { flags } = staticStore;
  const selectedCountry = flags[countryCode];
  return(
    <div style={{position:"relative",marginLeft:5,marginRight:5,...style}}>
      <div onMouseLeave={ () => { setShowTool(false) }} onMouseEnter={ () => {setShowTool(true)}} className="country_flag_icon_container">
        <img src={selectedCountry.flag} className="country_flag_icon"/>
      </div>
      { showTool ?<div className="country_flag_tooltip">
        <span className="country_flag_tooltip_text">
          {selectedCountry.name}
        </span>
      </div> : null}
    </div>
  )
}

export default CountryFlag