import React from 'react'
import "./index.css"
import Selector from '../Selector'
import SearchIcon from "../../assets/search.webp"
import smartEditorStore from '../../stores/smartEditorStore'
import { observer } from "mobx-react-lite"
import selectionStore from '../../stores/selectionStore'
import modalStore from '../../stores/modalStore'
import BulkEdit from '../BulkEdit'
import PasteFrom from '../PasteFrom'
import { Store } from 'react-notifications-component';
import TranslateFrom from '../TranslateFrom'
import appStore from '../../stores/appStores'
import mixpanel from '../../helpers/mixpanel'
import Search from '../Search'

const EditButton = ({ text, onClick }) => {
  return (
    <div onClick={onClick} className='se-edit-button-container cursor' >
      <span className="se-edit-button-text" >{text}</span>
    </div>
  )
}

function SmartEditorHeader() {
  const { selectedFilter } = smartEditorStore;
  const { editableVersion } = appStore;
  const { allSelected, selections } = selectionStore;
  let d = Object.values(selections)
  let isSelected = d.find(e => e.isSelected)
  return (
    <div className='se-header-container' >
      <Search
        placeholder={"Search Language"}
        onChange={(e) => {
          appStore.searchLocales(e.target.value);
          mixpanel.track("ASO_SMART_EDITOR_LANGUAGE_SEARCH", { query: e.target.value });
        }} />
      <div className='se-header-row' >
        <div style={{ display: "flex", flexDirection: "row" }} >
          <Selector isSelected={allSelected} onPress={() => {
            if (!editableVersion?.editable) return
            mixpanel.track("ASO_SMART_EDITOR_LANGUAGE_SELECT_ALL");
            selectionStore.selectAll();
          }} />
          <span className='se-header-country' >Language</span>
          <span className='se-header-title' >{selectedFilter.displayName}</span>
        </div>
        <div style={{ display: "flex", flexDirection: "row", opacity: isSelected ? 1 : 0.2 }} >
          <EditButton onClick={() => {
            if (!isSelected) {
              Store.addNotification({
                title: "Information",
                message: `Please select at least one country`,
                container: "top-right",
                insert: "top",
                type: "info",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: true
                }
              })
              return;
            }
            mixpanel.track("ASO_SMART_EDITOR_BULK_EDIT_CLICK");
            modalStore.setContent("Bulk Edit", <BulkEdit />, true);
          }} text={"Bulk Edit"} />
          <EditButton onClick={() => {
            if (!isSelected) {
              Store.addNotification({
                title: "Information",
                message: `Please select at least one country`,
                container: "top-right",
                insert: "top",
                type: "info",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: true
                }
              })
              return;
            }
            mixpanel.track("ASO_SMART_EDITOR_COPY_FROM_CLICK");
            modalStore.setContent("Copy From", <PasteFrom />, true);
          }} text={"Copy From"} />
          <EditButton onClick={() => {
            if (!isSelected) {
              Store.addNotification({
                title: "Information",
                message: `Please select at least one country`,
                container: "top-right",
                insert: "top",
                type: "info",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: true
                }
              })
              return;
            }
            mixpanel.track("ASO_SMART_EDITOR_TRANSLATE_FROM_CLICK");
            modalStore.setContent("Translate From", <TranslateFrom />, true, "scroll");
          }} text={"Translate From"} />
        </div>
      </div>
    </div>
  )
}

export default observer(SmartEditorHeader);