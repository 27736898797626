import React, { useState } from 'react'
import KeywordInput from '../KeywordInput';
import "./index.css";
import copy from "../../assets/copy-clickboard.webp";
import copy_done from "../../assets/copy-done.webp";

export default function SmartEditorInput({ value, onChange = () => { }, multiline, maxLength = -1, placeholder, separated, editable }) {
  const [hover, setHover] = useState(false);
  const [copyDone, setCopyDone] = useState(false);

  const renderInput = () => {
    if (separated) {
      let val = value?.split(/[\s,]+/u);
      return (
        <KeywordInput
          maxLength={maxLength}
          value={val}
          isSmart={true}
          editable={editable}
          onChange={onChange} />
      )
    }
    if (multiline) {
      return (
        <textarea
          onChange={(e) => onChange(e.target.value)}
          value={value}
          disabled={!editable}
          placeholder={placeholder}
          maxLength={maxLength}
          className={editable ? 'smart_input_input smart_textarea' : 'smart_input_input smart_textarea smart_input_input_inactive'} />
      )
    } else {
      return (
        <input
          onChange={(e) => onChange(e.target.value)}
          value={value}
          disabled={!editable}
          placeholder={placeholder}
          maxLength={maxLength}
          className={editable ? 'smart_input_input' : 'smart_input_input smart_input_input_inactive'} />
      )
    }
  }

  return (
    <div
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      className={editable ? 'se-input-container' : 'se-input-container se-input-container-inactive'} >
      {renderInput()}
      {(!hover && maxLength > 0) && <span className='se-input-maxlength' >{maxLength - (value ? value?.length : 0)}</span>}
      {hover &&
        <div onClick={() => {
          window.navigator.clipboard.writeText(value);
          setCopyDone(true);
          setTimeout(() => {
            setCopyDone(false);
          }, 1000);
        }} style={{ top: 10 }} className='copy-to-clickboard-container cursor' >
          <img className='copy-to-clickboard-image' src={copyDone ? copy_done : copy} />
        </div>}
    </div>
  )
}
