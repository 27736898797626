import React, { useEffect, useRef, useState } from 'react'
import appStore from '../../stores/appStores';
import "./index.css";
import add_icon from "../../assets/add_icon.webp";

export default function DropdownModal({ right, left, top, divRef, onClose, data, addButtonText, addButtonAction, containerStyle, addButtonDisabled = false }) {
  const _self = useRef()

  const eventAction = (e) => {
    if ((divRef.current?.contains(e.target) && true) || _self.current?.contains(e.target)) {
      return;
    }
    const k = divRef.current?.contains(e.target);
    onClose();
  }


  useEffect(() => {
    document.addEventListener('click', eventAction);
    return () => {
      document.removeEventListener('click', eventAction);
    }
  }, [])

  return (
    <div ref={_self} className="dropdown_container animate__animated animate__fadeIn" style={{ right, left, top, ...containerStyle }}>
      {addButtonText && <div onClick={addButtonDisabled ? () => { } : addButtonAction} className={"dropdown_add_new_app cursor"} >
        <img src={add_icon} className={addButtonDisabled ? "dropdown_add_new_app_icon dropdown-inactive-add" : "dropdown_add_new_app_icon"} />
        <span className={addButtonDisabled ? "dropdown_add_new_app_text dropdown-inactive-add" : "dropdown_add_new_app_text"}>
          {addButtonText}
        </span>
      </div>}
      {data()}
    </div>
  )
}
