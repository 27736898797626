import { Store } from "react-notifications-component";
import templateStore from "../../stores/templateStore";
import Button from "../Button";
import "./index.css"
import modalStore from "../../stores/modalStore";
import { useState } from "react";

const CreateTempModal = () => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="create-temp-modal-container" >
      <div className="create-temp-modal-inner-container" >
        <span className="create-temp-modal-input-title" >
          Name
        </span>
        <input placeholder="Template Name" className="create-temp-modal-input" onChange={(e) => {
          templateStore.setNewTempName(e.target.value);
        }} />
      </div>
      <Button
        color="white"
        style={{ marginLeft: 0 }}
        textStyle={{ color: "black" }}
        loading={loading}
        text="Save"
        onClick={async () => {
          setLoading(true);
          const resp = await templateStore.createTemplate();
          setLoading(false);
          if (resp) {
            Store.addNotification({
              title: "Done!",
              message: "Your template created successfully.",
              container: "top-right",
              insert: "top",
              type: "success",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            })
            modalStore.setShow(false);
          } else {
            Store.addNotification({
              title: "Warning!",
              message: "Please enter a template name.",
              container: "top-right",
              insert: "top",
              type: "warning",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            })
          }
        }}
      />
    </div>
  )
}

export default CreateTempModal