import { useState } from "react";
import "./index.css";
import { observer } from "mobx-react-lite";
import TableHeader from "../TableHeader";
import Search from "../Search";
import Button from "../Button";
import staticStore from "../../stores/staticStore";
import Selector from "../Selector";
import templateStore from "../../stores/templateStore";
import modalStore from "../../stores/modalStore";

const TableHeaderRow = ({ id }) => {
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <div className="template-manage-modal-table-header-row" >
        <Search onChange={(e) => {
          templateStore.searchManagedCountries(e.target.value);
        }} placeholder={"Search Country"} />
        <Button loading={loading} loadingColor="black" text="Save" color="white" textStyle={{ color: "black" }}
          onClick={async () => {
            setLoading(true);
            await templateStore.applyManaged(id);
            modalStore.setShow(false);
            setLoading(false);
          }} />
      </div>
      <TableHeader
        firstItem={"Countries"}
        items={[
          "", ""
        ]} />
    </div>
  )
}

const TableRowItem = ({ data }) => {
  return (
    <div className="template-manage-modal-row" >
      <Selector onPress={() => {
        templateStore.selectManagedCountry(data.country);
      }} isSelected={data.selected} />
      <img className="pricing-edit-modal_item_bottom_card_flag" src={data.flag} />
      <span className="table-row-item-title" style={{ flex: 2, marginLeft: 12 }} >
        {data.country}</span>
    </div>
  )
}

const TemplateManageModal = ({ id, item }) => {
  const { managedCountries, filteredCountries } = templateStore;
  let array = filteredCountries ? filteredCountries : managedCountries;
  return (
    <div className='template-manage-modal-container' style={{ overflow: "scroll" }} >
      <div className="template-manage-modal-inner-container" >
        <TableHeaderRow id={id} item={item} />
        {
          array.map((e) => <TableRowItem key={`${e.country}#####${e.selected}`} data={e} />)
        }
      </div>
    </div>
  )
}

export default observer(TemplateManageModal);