import { observer } from "mobx-react-lite"
import accountStore from "../../../stores/accountStore";
import { useEffect, useState } from "react";
import blue_dot from "../../../assets/blue_dot.webp";
import line from "../../../assets/line.webp";
import red_dot from "../../../assets/red_dot.webp";
import staticStore from "../../../stores/staticStore";
import starterIcon from "../../../assets/starter.webp"
import advancedIcon from "../../../assets/advanced.png";
import Button from "../../../components/Button";
import mixpanel from "../../../helpers/mixpanel";

const CardDot = ({ text, icon }) => {
  return (
    <div className="dot_container">
      <img className="dot_style" src={icon} />
      <span className="dot_text">
        {text}
      </span>
    </div>
  )
}

const ChooseButton = ({ backgroundColor, onClick }) => {
  return (
    <div onClick={onClick} style={{ backgroundColor }} className="plan_choose_button cursor">
      <span className="plan_choose_text">
        Choose
      </span>
    </div>
  )
}

const BlueCard = ({ id, amount }) => {
  return (
    <div className="blue_card">
      <div className="plan_title_container">
        <span className="blue_text_gradient">
          Starter
        </span>
      </div>
      <span className="plan_card_desc">
        Suitable for developers with a small number of apps
      </span>
      <span style={{ fontWeight: 500, fontSize: 80, color: "white", marginTop: 50 }}>
        <span style={{ color: "#3896E4", fontSize: 24, fontWeight: 400, verticalAlign: "text-top" }}>$</span>{Math.floor(amount / 100)}.<span style={{ fontSize: 48 }}>{amount % 100}</span>
      </span>
      <div className="dash_line" />
      <CardDot icon={blue_dot} text={<span>Total <span style={{ fontWeight: 600 }}>1 app</span> limit</span>} />
      <CardDot icon={blue_dot} text={"Access All Features"} />
      <ChooseButton onClick={async () => {
        await accountStore.getMonthlySubscriptionLink(id);
        mixpanel.track("ASO_SETTINGS_BILLING_STARTER_CLICK");
      }} backgroundColor={"#426BFD"} />
    </div>
  )
}

const BlueCardV2 = () => {
  return (
    <div className="blue_card_v2">
      <div className="plan_title_container">
        <span className="blue_text_gradient">
          Need More?
        </span>
        <span className="plan_card_desc">
          Suitable for developers and teams with many apps
        </span>
      </div>
      <span className="blue_card_v2_custom_pricing" >
        Customized
        <br />
        <span style={{ fontWeight: 400 }} >
          Pricing
        </span>
      </span>
      <div className="plan_bottom_container" >
        <span className="blue_card_v2_desc" >
          After an online consultation, we will offer you a customized package that best suits your needs
        </span>
        <a onClick={() => {
          mixpanel.track("ASO_SETTINGS_BILLING_ADVANCED_CLICK");
        }} target="_top" href="mailto:hello@aso.studio?subject=About custom pricing!" className="blue_card_v2_button cursor">
          <span className="blue_card_v2_button_text">
            Get in Touch
          </span>
        </a>
      </div>
    </div>
  )
}

const RedCard = ({ amount, id }) => {
  return (
    <div style={{ alignItems: "center", flex: 2 }} className="plan_container_content">
      <div className="red_card">
        <div className="plan_title_container">
          <span className="red_text_gradient">
            Advanced
          </span>
        </div>
        <span className="plan_card_desc">
          Suitable for developers and teams with many apps
        </span>
        <span style={{ fontWeight: 500, fontSize: 80, color: "white", marginTop: 50 }}>
          <span style={{ color: "#DD61A0", fontSize: 24, fontWeight: 400, verticalAlign: "text-top" }}>$</span>{Math.floor(amount / 100)}.<span style={{ fontSize: 48 }}>{amount % 100}</span>
        </span>
        <div className="dash_line" />
        <CardDot icon={red_dot} text={<span>Total <span style={{ fontWeight: 600 }}>10 apps</span> limit</span>} />
        <CardDot icon={red_dot} text={"Access All Features"} />
        <ChooseButton onClick={async () => {
          await accountStore.getMonthlySubscriptionLink(id)
        }} backgroundColor={"#DD61A0"} />
      </div>
    </div>
  )
}

const ActiveSubscriptionCard = ({ name, appLimit, url }) => {
  const icon = name === "Starter" ? starterIcon : advancedIcon;

  return (
    <div className="active_subs_card">
      <img src={icon} className="active_subs_card_icon" />
      <div className="active_subs_card_title_container">
        <span className="active_subs_card_title">{name}</span>
        <span className="active_subs_card_limit">App Limit: {appLimit}</span>
      </div>
      <div style={{ display: "flex", justifyContent: "center", flex: 1, alignSelf: "stretch", marginLeft: 50, marginRight: 22 }}>
        <Button onClick={() => {
          mixpanel.track("ASO_SETTINGS_MANAGE_PLAN_CLICK");
          openInNewTab(url)
        }} text="Manage" textStyle={{ color: "black" }} style={{ backgroundColor: "white", alignSelf: "center" }} />
      </div>

    </div>
  )
}

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const Billing = observer(() => {

  const { account } = accountStore;
  const { plans, defaultPlan } = staticStore
  const [url, setUrl] = useState(null)
  const [isMonthly, setIsMonthly] = useState(false);
  useEffect(() => {
    if (!plans) {
      staticStore.getStatics();
    }
    accountStore.getManageSubscriptionLink().then((_url) => {
      setUrl(_url)
    })
    mixpanel.track("ASO_SETTINGS_BILLING_TAB_VIEW");
  }, [])


  if (!plans) return null;

  const currentAdvance = plans.Advanced[isMonthly ? "month" : "year"];
  const currentStarter = plans[defaultPlan][isMonthly ? "month" : "year"];

  let monthlyPrice = plans[defaultPlan]["month"].amount * 12
  let yearlyPrice = plans[defaultPlan]["year"].amount

  let discount = ((monthlyPrice - yearlyPrice) / monthlyPrice) * 100;

  if (account.subscription.active) {
    return (
      <div className="setting_page_container">
        <div className="settings_page_title_container" style={{ flexDirection: "column" }}>
          <span className="setting_page_title">
            Billing
          </span>
          <span className="setting_custom_desc">
            Here you can see your Invoices and manage your plan.
          </span>
        </div>
        <div style={{ display: "flex", marginLeft: 30, marginTop: 60, flexDirection: "column" }}>
          <span className="setting_page_subtitle">Your Plan</span>
          <ActiveSubscriptionCard url={url} name={account.subscription.package} {...account.subscription} />
          <span style={{ marginTop: 50 }} className="setting_page_subtitle">Invoices</span>
          <span className="setting_custom_desc">
            In the window that will open, you can view all your invoices and change your payment method if you want.
          </span>
          <Button onClick={() => {
            mixpanel.track("ASO_SETTINGS_VIEW_INVOICES_CLICK");
            openInNewTab(url);
          }} style={{ backgroundColor: "transparent", borderWidth: 1, borderColor: "white", borderStyle: "solid", alignSelf: "flex-start", marginLeft: 0, marginTop: 30 }} text="View Invoices" />
        </div>
        {/*         
        <div onClick={ async () => {
          await accountStore.getMonthlySubscriptionLink();
        }} style={{color:"white"}}>
          STRIPE MONTHLY
        </div>
        <div style={{color:"white"}}>
          <a target="_blank" href={url}>
            Manage Subscription
          </a>
          
        </div> */}
      </div>
    )
  }

  return (
    <div className="setting_page_container">
      <div className="settings_page_title_container" style={{ flexDirection: "column" }}>
        <span className="setting_page_title">
          Billing
        </span>
        <span className="setting_custom_desc">
          Here you can see your Invoices and manage your plan.
        </span>
      </div>
      <div className="plan_container">
        <div className="plan_container_content">
          <span className="plan_span">
            Pick Your Plan
          </span>
          <div onClick={() => {
            setIsMonthly(true)
            mixpanel.track("ASO_SETTINGS_BILLING_MONTHLY_SWITCH_CLICK");
          }} className="plan_packages_container cursor">
            <div className="plan_pack">
              <div className={isMonthly ? "selected_dot" : "un_selected_dot"} />
              <span className="plan_pack_text">
                Monthly Billing
              </span>
            </div>
          </div>

          <div onClick={() => {
            mixpanel.track("ASO_SETTINGS_BILLING_YEARLY_SWITCH_CLICK");
            setIsMonthly(false)
          }} className="plan_packages_container cursor">
            <div className={isMonthly ? "un_selected_dot" : "selected_dot"} />
            <span className="plan_pack_text">
              Yearly Biling
            </span>
            <div className="discount_container discount_text">
              {`${discount.toFixed()}% Discount`}
            </div>
          </div>
          <div style={{ marginRight: 100, marginTop: 50, display: "flex", flexDirection: "column" }} className="all_packages_text_container" >
            <span className="all_packages_text" >
              All Packages Have The Same Features
            </span>
            <p className="all_packages_paragraph" >
              So you can choose the package that suits you best depending on the number of applications.
            </p>
            <span style={{ fontSize: 20, fontWeight: 300 }} className="gradient_text_smart" >
              Smart & Easy
            </span>
            <span className="gradient_text_smart" >
              Store Optimization
            </span>
            <img src={line} style={{ width: 219, height: 41, marginTop: 12 }} />
          </div>
        </div>

        <div style={{ flexDirection: "row", display: "flex", marginBottom: 150, alignSelf: "stretch" }} >
          <BlueCard {...currentStarter} />
          {/* <RedCard {...currentAdvance} /> */}
          <BlueCardV2 {...currentAdvance} />
        </div>
      </div>
      {/* <div onClick={ async () => {
        await accountStore.getMonthlySubscriptionLink();
      }} style={{color:"white"}}>
        STRIPE MONTHLY
      </div>
      <div style={{color:"white"}}>
        <a target="_blank" href={url}>
          Manage Subscription
        </a>
        
      </div> */}
    </div>
  )
});

export default Billing;
