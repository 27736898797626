import React, { useCallback, useEffect, useRef, useState } from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import request from "../../helpers/request";
import accountStore from "../../stores/accountStore";
import gradient from "../../assets/login_gradient.webp";
import logo from "../../assets/logo.webp";
import "./index.css";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import {
  Link,
  Navigate
} from "react-router-dom";
import appStore from "../../stores/appStores";
import { validateEmail } from "../../helpers/statics";
import { Store } from "react-notifications-component";
import mixpanel from "../../helpers/mixpanel";


const Login = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [navigate, setNavigate] = useState(false)
  const [captcha, setCaptcha] = useState(null)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('login');
    setCaptcha(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const submit = async () => {
    mixpanel.track("ASO_LOGIN_SUBMIT");
    let message;
    let title;
    const isValid = validateEmail(email);
    if (!email) {
      title = "Email"
      message = "Please enter your email address."
    } else if (!password) {
      title = "Password"
      message = "Please check your password."
    } else if (password.length < 4) {
      title = "Password"
      message = "Password must be at least 8 characters."
    } else if (!isValid) {
      title = "Email"
      message = "The email address you provided is not valid."
    }
    if (message) {
      mixpanel.track("ASO_LOGIN_SUBMIT_WARNING", { message, title });
      Store.addNotification({
        title: title,
        message: message,
        container: "top-right",
        insert: "top",
        type: "warning",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
      return
    }
    const resp = await request("auth/login", "post", { email, password, captcha })
    handleReCaptchaVerify();
    if (resp.done) {
      mixpanel.track("ASO_LOGIN_SUBMIT_SUCCES");
      accountStore.setAccount(resp.account);
      mixpanel.identify(resp.account._id);
      mixpanel.register(resp.account);
      localStorage.setItem("token", resp.token);

      const { redirect } = await appStore.getSecrets();
      if (redirect === "app") {
        const app = await appStore.getApps();
        if (!app) {
          setNavigate("/settings?tab=apps")
        }
        setNavigate("/detail/" + app._id);
      } else {
        setNavigate("/register/connection")
      }
    } else {
      mixpanel.track("ASO_LOGIN_SUBMIT_ERROR");
      Store.addNotification({
        title: "Invalid Credentials",
        message: "Please check the credentials you provided.",
        container: "top-right",
        insert: "top",
        type: "warning",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }
  }
  return (

    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex: 1, height: "100vh", width: "100%" }}>
      <img src={gradient} style={{ width: "100%", height: "100%", position: "absolute", top: 0, right: 0, zIndex: 1 }} />
      <img src={logo} style={{ width: "300px", height: "auto", objectFit: "contain", zIndex: 2, marginBottom: 20, marginTop: 150 }} />
      <div id="login-captcha" className="login_container">
        <span className="login_text">
        </span>
        <div style={{ height: 30 }} />
        <Input type="email" title={"E-Mail"} value={email} onChange={setEmail} />
        <Input type="password" title={"Password"} onSubmit={submit} value={password} onChange={setPassword} />

        <Button
          style={{ marginLeft: 0, marginBottom: 30, width: 150, backgroundColor: "white", alignSelf: "center", marginTop: 18 }}
          textStyle={{ color: "black" }}
          onClick={submit} text="Login" />
      </div>
      <div style={{ zIndex: 3, marginTop: 12 }} >
        <span className="login-no-account" >Don’t Have An Account?  </span>
        <Link onClick={() => {
          mixpanel.track("ASO_LOGIN_BOTTOM_REGISTER_CLICK");
        }} to={"/register"} className="login-no-account" style={{ color: "white" }}>  Register For Free</Link>
      </div>
      <div className="login-bottom" >
        <span className="login-terms-text" ><Link >Terms and Conditions </Link> • <Link to={"https://aso.studio/privacy"} > Privacy Policy </Link></span>
      </div>
      {
        navigate && <Navigate to={navigate} />
      }
    </div >
  )
}

export default Login;