import React, { useState, useEffect, useRef } from 'react';
import * as ReactDOM from "react-dom/client";
import "./App.css"
import 'animate.css/animate.min.css';
import Modal from "./components/Modal";

import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useNavigate,
  Outlet,
  useParams
} from "react-router-dom";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import AppDetail from "./pages/AppDetail";
import Login from './pages/Login';

import { observer } from "mobx-react-lite"
import appStore from './stores/appStores';

import 'react-notifications-component/dist/theme.css'
import { ReactNotifications } from 'react-notifications-component'
import AddConnection from './pages/AddConnection';
import Register from './pages/Register';
import request from './helpers/request';
import Settings from './pages/Settings';
import PaymentSucceeded from './pages/PaymentSucceeded';
import accountStore from './stores/accountStore';

import TrialEnd from "../src/pages/TrialEnd";
import { initGA } from './helpers/events';
import mixpanel from './helpers/mixpanel';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import Rating from './pages/Rating';
import { initTwitter } from './helpers/pixel';
import Pricing from './pages/Pricing';
import { IntlProvider } from 'react-intl';

// CHECK: 
if (false) {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
  console.warn = () => { }
  console.trace = () => { }
  console.info = () => { }
  console.group = () => { }
}

mixpanel.init();
initTwitter();
initGA("G-9JYYKX8QXY");

const GetAppAndRedirect = ({ }) => {

  const [url, setUrl] = useState("");

  useEffect(() => {

    request("account").then(resp => {
      resp && mixpanel.identify(resp.account._id);
      resp && mixpanel.register(resp.account);
      appStore.getSecrets().then(() => {
        appStore.getApps().then((app) => {
          let _url = `/detail/${app._id}`;
          setUrl(_url);
        })
      })
    })

    return () => {

    }
  }, [])
  if (url) {

    return (
      <Navigate to={url} />
    )
  }

  return (
    <div />
  )
  //state param ile hide ediliniyor.
}

const RedirectToHome = observer(() => {
  const { apps } = appStore;

  if (!localStorage.getItem("token")) {
    return <Navigate to={"/login"} />
  }

  if (apps.length === 0) {
    return <GetAppAndRedirect />
  }

  return (
    <Navigate to={"/detail/" + apps[0]._id} />
  )
});

const Root = observer(({ }) => {

  const { account } = accountStore;

  const nav = useNavigate();

  useEffect(() => {
    account && mixpanel.identify(account._id);
    account && mixpanel.register(account);
  }, [])

  const path = window.location.pathname;

  if (account) {
    const date1 = new Date(account?.subscription?.expireAt);
    const date2 = new Date();
    const diffTime = date1 - date2
    const showTrial = !account.subscription.active && diffTime < 0;
    if (path !== "/trial/end") {
      if (showTrial) {
        nav("/trial/end")
      }
    } else {
      if (!showTrial) {
        nav("/")
      }
    }
  }

  return (
    <div className='test' style={{}}>
      <main>
        <Outlet />
      </main>
    </div>
  )
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "detail/:id",
        element: <AppDetail />,
      },
      {
        path: "reviews/:id",
        element: <Rating />,
      },
      {
        path: "pricing/:id",
        element: <Pricing />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "register/connection",
        element: <AddConnection />,
      },
      {
        path: "login",
        element: <Login />
      },
      {
        path: "settings",
        element: <Settings />
      },
      {
        path: "",
        element: <RedirectToHome />
      },
      {
        path: "payment_succeeded",
        element: <PaymentSucceeded />
      },
      {
        path: "trial/end",
        element: <TrialEnd />
      },
      {
        path: ":text",
        element: <RedirectToHome />,
      }
    ]
  },
]);

const CheckIsValidPurchase = observer(({ nav }) => {

  const { account } = accountStore;


  if (!account) {
    return null;
  }

  if (!account?.active && !account?.isTrial) {
    return <Navigate to={"/trial/end"} />
  }

  return null;
});


function App() {
  mixpanel.track("ASO_INIT");
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LfUpg0pAAAAAL1bFregzLG6biwueCshJVEz-go8"
      language="en"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        nonce: undefined // optional, default undefined
      }}
    >
      <IntlProvider locale='en' >
        <div className='App'>
          <ReactNotifications />
          <Modal />
          <RouterProvider router={router} />
          {/* <TawkMessengerReact
          propertyId="65532317958be55aeaaf65a2"
        widgetId="1hf6c25i1" /> */}
        </div>
      </IntlProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
