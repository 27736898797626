import { useEffect } from "react"
import { useNavigate } from "react-router-dom";

function ButtonLoading({ color }) {
  return (
    <div class="lds-ring">
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
    </div>
  )
}

const PaymentSucceeded = () => {

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/settings");
    },5000)
  })

  return(
    <div style={{display:"flex",flex:1,justifyContent:"center",alignItems:"center",alignSelf:"stretch", width:"100%", height:"100vh"}}>
      <ButtonLoading />
    </div>
  )
}

export default PaymentSucceeded;