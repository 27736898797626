import { observer } from "mobx-react-lite"

import "./index.css";
import selectionStore from "../../stores/selectionStore";
import CountryFlag from "../CountryFlag";
import CustomInput from "../CustomInput";
import smartEditorStore from "../../stores/smartEditorStore";
import { useState } from "react";
import { MAX_LENGTH } from "../../helpers/statics"
import Button from "../Button";
import appStore from "../../stores/appStores";
import modalStore from "../../stores/modalStore";
import { Store } from 'react-notifications-component';
import mixpanel from "../../helpers/mixpanel";

const BulkEdit = observer(() => {
  const { selections } = selectionStore;
  const { selectedFilter } = smartEditorStore;

  const [value, setValue] = useState("");

  const _selections = Object.keys(selections).filter((s) => selections[s].isSelected);
  return (
    <div className="bulk_edit_container">
      <span className="bulk_edit_text">
        {_selections.length} countries have been selected
      </span>
      <div className="bulk_edit_flag_container">
        {
          _selections.map((countryCode, index) => {
            return (
              <CountryFlag countryCode={countryCode} key={index + "#" + countryCode} />
            )
          })
        }
      </div>
      <div className="bulk_edit_content_container">
        <CustomInput
          maxLength={MAX_LENGTH[selectedFilter.field]}
          editable={true}
          title={selectedFilter.displayName}
          copy={false}
          multiline={selectedFilter.multiline}
          value={value}
          onChange={setValue}
          placeholder={selectedFilter.displayName} />
        <Button
          onClick={async () => {
            await appStore.bulkEdit(_selections, selectedFilter.field, value);
            mixpanel.track("ASO_SMART_EDITOR_BULK_EDIT_SAVE");
            modalStore.setShow(false);
            Store.addNotification({
              title: "Edited!",
              message: "The changes were successfully eddited on selected languages.",
              container: "top-right",
              insert: "top",
              type: "success",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            })
          }}
          text="Save"
          style={{ width: "100px", alignSelf: "center", marginBottom: "20px", backgroundColor: "white" }}
          textStyle={{ color: "#000000" }}
        />
      </div>
    </div>
  )
})

export default BulkEdit;