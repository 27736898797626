import "./index.css";

function ButtonLoading({ color }) {
  return (
    <div class="lds-ring">
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
    </div>
  )
}


const Button = ({ color = "#426BFD", icon, loading, text = "TEXT", style = {}, textStyle = {}, onClick, disabled, className = "", loadingColor = "white" }) => {
  return (
    <div onClick={(disabled || loading) ? () => { } : onClick} className={`custom_button cursor ${className}`} style={{ backgroundColor: color, ...style }}>
      {loading ? <ButtonLoading color={loadingColor} /> :
        <>
          {icon && <img src={icon} className="button_icon" />}
          <span className="text" style={textStyle}>
            {text}
          </span>
        </>
      }
    </div>
  )
}

export default Button;