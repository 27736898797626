import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import "./index.css";
import request from '../../helpers/request';
import appStore from '../../stores/appStores';
import step_zero from "../../assets/step-zero.webp";
import step_one from "../../assets/step-one.webp";
import step_two from "../../assets/step-two.webp";
import step_add from "../../assets/step-add.webp";
import steps_lock from "../../assets/steps-lock.webp";
import why_admin from "../../assets/why_admin.webp";
import question_blue from "../../assets/question_blue.webp";
import confetti_left from "../../assets/confetti-left.webp";
import confetti_right from "../../assets/confetti-right.webp";
import step_left_arrow from "../../assets/step-left-arrow.webp";
import CustomInput from "../../components/CustomInput";
import Button from "../../components/Button";
import CustomDropzone from '../../components/CustomDropzone';
import Selector from '../../components/Selector';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import accountStore from '../../stores/accountStore';
import { Store } from 'react-notifications-component';
import mixpanel from '../../helpers/mixpanel';
import { sendTwitterEvent } from '../../helpers/pixel';

function AddConnection() {
  const [searchParams] = useSearchParams();
  let _step = searchParams.get("action")

  const [isReady, setIsReady] = useState(null);
  const [step, setStep] = useState(_step ? parseInt(_step) : 0);
  const [issuer, setIssuer] = useState("");
  const [key, setKey] = useState("");
  const [file, setFile] = useState(null);
  const [showApps, setShowApps] = useState(false);
  const [showAdminTip, setshowAdminTip] = useState(false);
  const [selected, setSelected] = useState([]);
  const [secretLoading, setSecretLoading] = useState(false);
  const [appsLoading, setAppsLoading] = useState(false);

  useEffect(() => {
    appStore.clear();
    const refresh = localStorage.getItem("token");
    mixpanel.track("ASO_CONNECT_DEVELOPER_VIEW");
    if (refresh) {
      if (!accountStore.account) {
        accountStore.getAccount().then(() => {
          appStore.getSecrets().then(() => {
            setIsReady(true)
          });
        })

      } else {
        setIsReady(false)
      }
    } else {
      setIsReady(true)
    }


  }, [])

  const navigate = useNavigate();

  if (isReady === null) {
    return (
      <div className='index'>
        LOADING
      </div>
    )
  }

  const renderDot = (index) => {
    return (
      <span className={step === index ? 'ac-progress-dot' : step < index ? 'ac-progress-dot-inactive' : 'ac-progress-dot-filled'} >

      </span>
    )
  }

  const renderLine = (status) => {
    return (
      <span className={status === "invisible" ? 'ac-progress-line-invisible' : 'ac-progress-line-inactive'} >

      </span>
    )
  }

  const renderProgressStep = (title, index) => {
    let isBackable = step !== 3 && step > index;
    return (
      <div onClick={() => setStep(isBackable ? index : step)} className={isBackable ? 'ac-progress-step cursor' : 'ac-progress-step'} >
        <span className='ac-progress-title' >{title}</span>
        <div className='ac-progress-dot-container' >
          {renderLine(index === 0 ? "invisible" : step >= index ? "active" : "visible")}
          {renderDot(index)}
          {renderLine(index === 3 ? "invisible" : step >= index + 1 ? "active" : "visible")}
        </div>
      </div>
    )
  }

  const renderBar = () => {
    return (
      <div className='ac-progress-dot-container' >
        {renderProgressStep("Issuer ID", 0)}
        {renderProgressStep("Key ID", 1)}
        {renderProgressStep("Key File", 2)}
        {renderProgressStep("Select Apps", 3)}
      </div>
    )
  }

  const renderLock = () => {
    return (
      <img src={steps_lock} className='ac-steps-lock' />
    )
  }

  const renderStepZero = () => {
    return (
      <div>
        <div className='ac-step-image-container' >
          {renderLock()}
          <img src={step_zero} className='ac-step-zero-image' />
          <div className='ac-step-image-bottom' >
            <span className='ac-step-image-bottom-text' >Users and Access</span>
            <img src={step_left_arrow} className='ac-step-left-arrow' />
            <span className='ac-step-image-bottom-text' >Keys</span>
            <img src={step_left_arrow} className='ac-step-left-arrow' />
            <span className='ac-step-image-bottom-text' >App Store Connect API</span>
            <img src={step_left_arrow} className='ac-step-left-arrow' />
            <span className='ac-step-image-bottom-text' >Copy Issuer ID</span>
          </div>
        </div>
        <div className='ac-step-bottom' >
          <CustomInput extraStyle={{ width: "80%" }} value={issuer} onChange={setIssuer} copy={false} editable title={"Issuer ID"} placeholder={"Paste Issuer ID Here"} />
          <Button onClick={() => setStep(step + 1)} disabled={!issuer} text='Next' color='#426BFD' style={{ marginLeft: 0, opacity: issuer ? 1 : .2 }} />
        </div>
      </div>
    )
  }

  const renderStepOne = () => {
    return (
      <div>
        <div className='ac-step-image-container' >
          {renderLock()}
          <img src={step_one} className='ac-step-one-image' />
          <div className='ac-step-image-bottom' >
            <img src={step_add} className='ac-step-add-icon' />
            <span className='ac-step-image-bottom-text' >Generate API Keys</span>
            <img src={step_left_arrow} className='ac-step-left-arrow' />
            <span className='ac-step-image-bottom-text ac-step-image-bottom-text-with-access' >App Manager</span>
            <img src={step_left_arrow} className='ac-step-left-arrow' />
            <span className='ac-step-image-bottom-text' >Generate</span>
            <img src={step_left_arrow} className='ac-step-left-arrow' />
            <span className='ac-step-image-bottom-text ac-step-image-bottom-text-with-hover' >Copy Key ID</span>
          </div>
        </div>
        <div className='ac-step-bottom' >
          <CustomInput extraStyle={{ width: "80%" }} value={key} onChange={setKey} copy={false} editable title={"Key ID"} placeholder={"Paste Key ID Here"} />
          <Button onClick={() => setStep(step + 1)} disabled={!key} text='Next' color='#426BFD' style={{ marginLeft: 0, opacity: key ? 1 : .2 }} />
        </div>
      </div>
    )
  }

  const renderStepTwo = () => {
    return (
      <div>
        <div className='ac-step-image-container' >
          {renderLock()}
          <img src={step_two} className='ac-step-two-image' />
          <div className='ac-step-image-bottom' >
            <span className='ac-step-image-bottom-text' >Tap The Download Button On The Key You Created</span>
          </div>
        </div>
        <div className='ac-step-bottom' >
          <CustomDropzone setValue={setFile} value={file} />
          <Button loading={secretLoading} onClick={async () => {
            setSecretLoading(true);
            await appStore.createSecret("", "ios", issuer, key, file.value);
            const { allApps } = appStore;
            let ids = allApps.filter(e => e.active);
            setSelected(ids.map(e => e._id));
            setStep(step + 1);
            setSecretLoading(false);
          }} disabled={!file} text='Next' color='#426BFD' style={{ marginTop: 20, marginLeft: 0, opacity: file ? 1 : .2 }} />
        </div>
      </div>
    )
  }

  const renderStepThree = () => {
    const { allApps } = appStore;
    console.log('allApps: ', allApps);
    return (
      <div className='ac-select-apps-table' >
        <div className='ac-select-apps-top' >
          <span className='ac-select-apps-top-title' >Select Apps</span>
          <span className='ac-select-apps-top-subtitle' >{selected.length > 0 ? `${selected.length} apps selected. You can change apps later.` : "Select the apps you want to manage. You can change apps later."}</span>
        </div>
        <div className='ac-select-apps-table-container' >
          <div className='ac-select-apps-table-header' >
            <Selector isSelected={allApps.length === selected.length} onPress={() => {
              if (allApps.length === selected.length) {
                setSelected([]);
              } else {
                const ids = allApps.map((item) => { return item._id })
                setSelected(ids)
              }
            }} />
            <span className='ac-select-apps-table-header-title' style={{ flex: 1, marginLeft: 60 }} >App Icon</span>
            <span className='ac-select-apps-table-header-title' style={{ flex: 10 }} >App Name</span>
          </div>
          {
            allApps.map((app) => {
              return (
                <div className='ac-select-apps-table-row' >
                  <Selector onPress={() => {
                    if (selected.includes(app._id)) {
                      let fIndex = selected.findIndex((i) => i === app._id);
                      let apps = [...selected]
                      apps.splice(fIndex, 1)
                      setSelected(apps);
                    } else {
                      let apps = [...selected];
                      apps.push(app._id)
                      setSelected(apps);
                    }
                  }} isSelected={selected.includes(app._id)} />
                  <span className='ac-select-apps-table-row-app-icon-container' style={{ flex: 1, marginLeft: 60 }} ><img className='ac-select-apps-table-row-app-icon' src={app.icon} /></span>
                  <span className='ac-select-apps-table-row-title' style={{ flex: 10 }} >{app.name}</span>
                </div>
              )
            })
          }
        </div>
        <div className='ac-step-bottom' >
          <Button onClick={async () => {
            setAppsLoading(true);
            const { selectedSecret } = appStore;
            await appStore.selectApps(selectedSecret._id, selected);
            const { account } = accountStore;
            sendTwitterEvent(account.email, "tw-ohlkv-ohmbi");
            request("app/get/app/" + selected[0]).then(async (resp) => {
              appStore.setCurrent(resp.current);
              appStore.setEditableVersion(resp.current);
              appStore.setHistory(resp.history);
              appStore.setSelectedApp(resp.app);
              appStore.setLocale(resp.app.primaryLocale);
              if (appStore.apps.length === 0) {
                await appStore.getSecrets();
                await appStore.getApps();
              }
              mixpanel.track("ASO_CONNECT_DEVELOPER_APPS_SELECT_SAVE")
              setAppsLoading(false);
              navigate(`/detail/${selected[0]}`);
            })
          }} text='Finish' color='#426BFD' loading={appsLoading} disabled={selected.length <= 0} style={{ marginTop: 20, marginLeft: 0, opacity: selected.length > 0 ? 1 : .2 }} />
        </div>
      </div>
    )
  }

  const renderContent = () => {
    switch (step) {
      case 0:
        return renderStepZero();
        break;
      case 1:
        return renderStepOne();
        break;
      case 2:
        return renderStepTwo();
        break;
      case 3:
        return renderStepThree();
        break;
      default:
        break;
    }
  }

  const issuerTip = <div className='ac-step-image-container' >
    {renderLock()}
    <span className='ac-step-title' >Where Is Issuer ID?</span>
    <img src={step_zero} className='ac-step-zero-image' />
    <div className='ac-step-image-bottom' >
      <span className='ac-step-image-bottom-text' >Users and Access</span>
      <img src={step_left_arrow} className='ac-step-left-arrow' />
      <span className='ac-step-image-bottom-text' >Keys</span>
      <img src={step_left_arrow} className='ac-step-left-arrow' />
      <span className='ac-step-image-bottom-text' >App Store Connect API</span>
      <img src={step_left_arrow} className='ac-step-left-arrow' />
      <span className='ac-step-image-bottom-text' >Copy Issuer ID</span>
    </div>
  </div>

  const keyTip = <div className='ac-step-image-container' >
    <span className='ac-step-title' >Where Is Key ID?</span>
    {renderLock()}
    <img src={step_one} className='ac-step-one-image' />
    <div className='ac-step-image-bottom' >
      <img src={step_add} className='ac-step-add-icon' />
      <span className='ac-step-image-bottom-text' >Generate API Keys</span>
      <img src={step_left_arrow} className='ac-step-left-arrow' />
      <span className='ac-step-image-bottom-text ac-step-image-bottom-text-with-access' >Admin</span>
      <img src={step_left_arrow} className='ac-step-left-arrow' />
      <span className='ac-step-image-bottom-text' >Generate</span>
      <img src={step_left_arrow} className='ac-step-left-arrow' />
      <span className='ac-step-image-bottom-text ac-step-image-bottom-text-with-hover' >Copy Key ID</span>
    </div>
  </div>

  const fileTip = <div className='ac-step-image-container' >
    {renderLock()}
    <span className='ac-step-title' >Where Is Key File?</span>
    <img src={step_two} className='ac-step-two-image' />
    <div className='ac-step-image-bottom' >
      <span className='ac-step-image-bottom-text' >Tap The Download Button On The Key You Created</span>
    </div>
  </div>

  const adminTip = <div className='ac-why-admin-tip-container' >
    <span className='ac-why-admin-tip-text' >Some features, such as responding to customer reviews, are not available without the Admin role. To fully utilize aso.studio, ensure that the certificate you create is assigned the Admin role. For more detailed information, visit the following link and refer to the section titled 'My Apps': <Link className='ac-all-link-connect' to='https://developer.apple.com/support/roles/' target='_blank' >https://developer.apple.com/support/roles/</Link></span>
  </div>

  const renderAll = () => {
    return (
      <div className='ac-all-container' >
        <CustomInput maxLength={36} info={issuerTip} extraStyle={{ width: "100%" }} value={issuer} onChange={setIssuer} copy={false} editable title={"Issuer ID"} placeholder={"Paste Issuer ID Here"} />
        <CustomInput maxLength={10} info={keyTip} extraStyle={{ width: "100%" }} value={key} onChange={setKey} copy={false} editable title={"Key ID"} placeholder={"Paste Key ID Here"} />
        <span className='custom_input_title' style={{ alignSelf: "flex-start", marginBottom: 8 }} >Key File</span>
        <CustomDropzone info={fileTip} setValue={setFile} value={file} />
        <Button loading={secretLoading} onClick={async () => {
          if (issuer.length !== 36) {
            mixpanel.track("ASO_CONNECT_DEVELOPER_ERROR_ISSUER")
            Store.addNotification({
              title: "Issuer ID!",
              message: "Please check your Issuer ID.",
              container: "top-right",
              insert: "top",
              type: "warning",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            })
            return
          }
          if (key.length !== 10) {
            mixpanel.track("ASO_CONNECT_DEVELOPER_ERROR_KEY")
            Store.addNotification({
              title: "Key ID!",
              message: "Please check your Key ID.",
              container: "top-right",
              insert: "top",
              type: "warning",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            })
            return
          }
          setSecretLoading(true);
          await appStore.createSecret("", "ios", issuer, key, file.value);
          const { account } = accountStore;
          sendTwitterEvent(account.email, "tw-ohlkv-ohm4v");
          const { allApps } = appStore;
          let ids = allApps.filter(e => e.active);
          setSelected(ids.map(e => e._id));
          setShowApps(true);
          mixpanel.track("ASO_CONNECT_DEVELOPER_SELECT_APPS_VIEW", { appCount: allApps.length });
          setSecretLoading(false);
        }} disabled={false} text='Next' color='#426BFD' style={{ marginTop: 20, marginLeft: 0, opacity: (!file || issuer === "" || key === "") ? .2 : 1 }} />
      </div>
    )
  }

  return (
    <div className='ac-container' >
      <Header pathKey="register" />
      <div className='ac-inner-container' >
        {/* {renderBar()} */}
        {/* {renderContent()} */}
        <span className='ac-all-title' >Connect Apple Developer Account</span>
        <span className='ac-all-subtitle' >In order to access your apps on Aso.Studio, you need to create API Key on <a className='ac-all-link-connect' target="_blank" href={"https://appstoreconnect.apple.com/access/api"} >App Store Connect.</a>  To learn more please <a className='need_help_ac' target="_blank" href={"https://blog.aso.studio/how-to-create-app-store-connect-api-key/"} >check this out.</a> </span>
        {!showApps && <div className='ac-why-admin-container' >
          <div className='ac-why-admin-image-container' >
            <img className='ac-why-admin-confetti' src={confetti_left} />
            <img className='ac-why-admin-badge' src={why_admin} />
            <img className='ac-why-admin-confetti' src={confetti_right} />
          </div>
          <span className='ac-why-admin-title' >
            Please assign the<span style={{ fontWeight: 600 }} > 'Admin' </span> role as the access level.
          </span>
          <span
            onMouseLeave={() => {
              setshowAdminTip(false);
            }}
            onMouseEnter={() => {
              setshowAdminTip(true);
            }} className='ac-why-admin-question' >
            Why Admin <img src={question_blue} />
            {showAdminTip && adminTip}
          </span>
        </div>}
        {showApps ? renderStepThree() : renderAll()}
      </div>
    </div>
  )
}

export default observer(AddConnection)