import { makeAutoObservable } from "mobx"
import request from "../helpers/request";
import { Store } from 'react-notifications-component';
import staticStore from "./staticStore";
class AppStore {

  secrets = [];
  apps = [];
  locale = "en-US"

  selectedSecret = null;

  selectedApp = null;

  allApps = [];

  duplicates = {
    name: [],
    subtitle: [],
    keywords: []
  }

  current = null;
  history = null;

  isEdited = null;
  editableVersion = null;

  searchedLocales = null;
  keywordUpdateAt = new Date().getTime();

  constructor() {
    makeAutoObservable(this)
  }

  checkDuplicates = () => {
    if (!this.editableVersion?.metadata[this.locale]) {
      this.duplicates = Object.assign({}, {
        name: [],
        subtitle: [],
        keywords: []
      });
      return;
    }
    const names = this.editableVersion?.metadata[this.locale].name;
    const names_splits = names ? names.toLowerCase().split(" ") : [];

    const keywords = this.editableVersion?.metadata[this.locale].keywords;
    const keywords_splits = keywords ? keywords.toLowerCase().split(",") : [];

    const subtitles = this.editableVersion?.metadata[this.locale].subtitle;
    const subtitle_splits = subtitles ? subtitles.toLowerCase().split(" ") : [];


    const name_and_subtitle = names_splits.filter((item) => subtitle_splits.includes(item));

    const name_and_keywords = names_splits.filter((item) => keywords_splits.includes(item));

    const subtitle_and_keywords = subtitle_splits.filter((item) => keywords_splits.includes(item));

    this.duplicates = Object.assign({}, {
      name: [...name_and_keywords, ...name_and_subtitle],
      subtitle: [...subtitle_and_keywords, ...name_and_subtitle],
      keywords: [...subtitle_and_keywords, ...name_and_keywords]
    });



  }

  clear = () => {
    this.apps = [];
    this.selectedApp = null;
    this.current = null;
    this.history = null;
    this.isEdited = null;
    this.editableVersion = null;
  }

  setSecrets(secrets) {
    this.secrets = secrets;
    this.selectedSecret = secrets[0];
  }

  async setSelectedSecret(selectedSecret) {
    this.selectedSecret = selectedSecret;
    return await this.getApps();
  }

  async getSecrets() {
    const { secrets } = await request("account/get/secrets");
    if (secrets.length === 0) {
      return { redirect: "secret" };
    }

    this.setSecrets(secrets);
    return { redirect: "app" };
  }


  refreshApps = async (secretId) => {
    const { apps } = await request(`account/fetch/apps/${secretId}`);
    this.allApps = apps;
  }

  async pull() {
    const detail = await request("app/pull/app/" + this.selectedApp._id);
    const { app, current, history, done } = detail;
    if (done) {
      this.isEdited = null
      this.setSelectedApp(app);
      this.setHistory(history);
      this.setCurrent(current)
      this.setEditableVersion(current);
      Store.addNotification({
        title: "Synced!",
        message: "The changes were successfully sync to App Store Connect.",
        container: "top-right",
        insert: "top",
        type: "success",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }
  }

  async push() {
    const detail = await request("app/push/app/" + this.selectedApp._id);
    if (detail.done) {
      Store.addNotification({
        title: "Pushed!",
        message: "The changes were successfully push to App Store Connect.",
        container: "top-right",
        insert: "top",
        type: "success",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }
  }

  async save() {
    const detail = await request("app/edit/app/metadata", "post", {
      metadata: this.editableVersion?.metadata,
      versionId: this.editableVersion?._id
    })
    if (detail.done) {
      this.isEdited = null;
      Store.addNotification({
        title: "Saved!",
        message: "The changes were successfully saved.",
        container: "top-right",
        insert: "top",
        type: "success",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }
  }

  async getApps() {
    const appUrl = `account/get/apps/${this.selectedSecret._id}`;
    const { apps } = await request(appUrl);
    this.setApps(apps);
    return apps[0];
  }

  setApps(apps) {
    this.apps = apps;
  }

  setSelectedApp(selectedApp) {
    this.selectedApp = selectedApp
  }

  setCurrent(current) {
    this.current = current
  }

  setEditableVersion(editableVersion) {
    if (!editableVersion) {
      window.location.replace("/settings")
      return
    }
    this.editableVersion = editableVersion;
    this.checkDuplicates();
  }

  setHistory(history) {
    this.history = history
  }

  setLocale(locale) {
    this.locale = locale;
    this.checkDuplicates();
  }

  async addNewLang(locale) {
    const body = {
      appId: this.selectedApp._id,
      locale: locale.code
    }
    const { done, current } = await request("app/create/app/localization", "post", body)
    if (done) {
      this.setCurrent(current)
      this.setEditableVersion(current);
      Store.addNotification({
        title: "Added!",
        message: `${locale.title} language added succesffuly.`,
        container: "top-right",
        insert: "top",
        type: "success",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }
  }

  async createNewVersion(versionString) {
    const body = {
      appId: this.selectedApp._id,
      versionString
    }

    const { done, app, current, history } = await request("app/create/app/version", "post", body)

    if (done) {
      this.setSelectedApp(app);
      this.setHistory(history);
      this.setCurrent(current)
      this.setEditableVersion(current);
      Store.addNotification({
        title: "Created!",
        message: `${versionString} version created succesffuly.`,
        container: "top-right",
        insert: "top",
        type: "success",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }
  }

  setIsEdited(key, value, locale = this.locale) {
    if (!this.isEdited) {
      this.isEdited = {
        [locale]: {
          [key]: value
        }
      }
    } else {
      if (this.isEdited[locale]) {
        this.isEdited[locale][key] = value
      } else {
        this.isEdited[locale] = {
          [key]: value
        }
      }
    }

    if (key === "name" || key === "subtitle" || key === "keywords") {
      this.checkDuplicates();
    }

    this.isEdited = Object.assign({}, this.isEdited);
  }

  editSelectedMetadataWithKey(key, value) {
    if (!this.editableVersion) return null
    this.editableVersion.metadata[this.locale][key] = value
    this.setIsEdited(key, value)
  }

  bulkEdit(countries, key, value) {
    for (let i = 0; i < countries.length; i++) {
      const locale = countries[i];
      this.editableVersion.metadata[locale][key] = value
    }
    this.setIsEdited(key, value)

  }

  pasteAllFrom = (value) => {
    if (this.isEdited) {
      this.isEdited[this.locale] = value;
    } else {
      this.isEdited = {
        [this.locale]: value
      }
    }
    this.keywordUpdateAt = new Date().getTime();
    if (!this.editableVersion) return null
    this.editableVersion.metadata[this.locale] = { ...this.editableVersion.metadata[this.locale], ...value };

  }

  pasteFrom(countries, key, value) {
    for (let i = 0; i < countries.length; i++) {
      const locale = countries[i];
      this.editableVersion.metadata[locale][key] = value
    }
    this.keywordUpdateAt = new Date().getTime();
    this.setIsEdited(key, value)

  }

  editSelectedMetadataWithLocale(locale, key, value) {
    this.editableVersion.metadata[locale][key] = value
    this.setIsEdited(key, value, locale)
  }

  findAddableLocales = () => {
    const { flags } = staticStore;
    const { current } = this;
    const l = Object.keys(flags).filter((f) => { if (!current.metadata[f]) return f })
    this.addableLocales = l;
  }


  getAllApps = (secretId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await request(`account/get/all/apps/${secretId}`);
        if (resp.done) {
          this.allApps = resp.apps;
        }
        resolve(true);
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  createSecret = async (name, type = "ios", issuerId, keyId, privateKey, serviceAccount) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await request("account/create/secret", "POST", { type, issuerId, keyId, privateKey });
        if (resp.done) {
          this.allApps = resp.apps;
          this.selectedSecret = resp.secret;
        }
        resolve(true);
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  removeApp = (appId) => {
    return new Promise(async (resolve, reject) => {
      const resp = await request(`account/delete/app/${appId}`, "GET");
      await this.getAllApps(this.selectedSecret._id);
      resolve(resp);
    })
  }

  removeSecret = (secretId) => {
    return new Promise(async (resolve, reject) => {
      const resp = await request(`account/delete/secret/${secretId}`, "GET");
      await this.getSecrets();
      resolve(resp);
    });
  }

  selectApps = (secretId, selectedAppIds) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await request("account/select/apps", "POST", { secretId, selectedAppIds });
        resolve(true);
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  searchLocales = (text) => {
    if (!text) {
      this.searchedLocales = null;
      return
    }
    let locales = Object.keys(this.editableVersion?.metadata);
    const { flags } = staticStore;
    this.searchedLocales = locales.filter(e => flags[e].name.toLowerCase().includes(text.toLowerCase()));
  }


}

const appStore = new AppStore();
export default appStore;