import { makeAutoObservable } from "mobx"

class SelectionStore {

  selections = {};
  allSelected = false;

  constructor() {
    makeAutoObservable(this)
  }

  init = (locales) => {
    this.allSelected = false;
    this.selections = locales;
  }

  setSelected = (country) => {
    this.selections[country].isSelected = !this.selections[country].isSelected;
  }

  selectAll = () => {
    const keys = Object.keys(this.selections);
    this.allSelected = !this.allSelected;
    const newObject = keys.reduce((result, key) => {
      result[key] = { isSelected: this.allSelected }
      return result;
    }, {});
    this.selections = newObject;
  }

  clear = () => {
    this.selections = {};
  }

}

const selectionStore = new SelectionStore();
export default selectionStore;