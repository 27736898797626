import React, { useRef, useState } from 'react'
import "./index.css";
import DropdownModal from '../DropdownModal'
import appStore from '../../stores/appStores'
import request from '../../helpers/request'
import tick from "../../assets/tick.webp";
import staticStore from '../../stores/staticStore'
import { observer } from "mobx-react-lite"
import selectionStore from '../../stores/selectionStore';
import CountryFlag from '../CountryFlag';
import Button from '../Button';
import smartEditorStore from '../../stores/smartEditorStore';
import modalStore from "../../stores/modalStore";
import { Store } from 'react-notifications-component';
import mixpanel from '../../helpers/mixpanel';

const PasteFrom = observer(({ isAll }) => {
  const { selections } = selectionStore;
  const { selectedFilter } = smartEditorStore;
  const { history } = appStore;
  const { flags } = staticStore;

  // BUG: ŞU AN EDİTLENEN VERSİYONU SEÇERSE DEĞİŞİKLİKLER DB DEN Mİ GELECEK LOKALDEN Mİ?

  const versionRef = useRef();
  const localeRef = useRef();
  const [showVersions, setShowVersions] = useState(false);
  const [versionData, setVersionData] = useState(null);
  const [showLocale, setShowLocale] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedLocale, setSelectedLocale] = useState(null);
  const [locales, setLocales] = useState(null);
  const _selections = Object.keys(selections).filter((s) => selections[s].isSelected);

  return (
    <div className='modal_inner_container' >
      {isAll ?
        <span className='bulk_edit_text' >
          This will fill all fields from the locale you select
        </span>
        : <>
          <span className="bulk_edit_text">
            {_selections.length} countries have been selected
          </span>
          <div className="bulk_edit_flag_container">
            {
              _selections.map((countryCode, index) => {
                return (
                  <CountryFlag countryCode={countryCode} key={index + "#" + countryCode} />
                )
              })
            }
          </div>
        </>}
      <div className='dropdown-input-container' >
        <span className='dropdown-input-title' >Copy From</span>
        <div className='mt-flex-row' >
          <div ref={versionRef} onClick={() => setShowVersions(!showVersions)} className='dropdown-input cursor' >
            <span className='dropdown-input-text' >{selectedVersion ? selectedVersion.versionString : "Select Version"}</span>
            {showVersions && <DropdownModal
              containerStyle={{ width: 400, backgroundColor: "#1B1B2C", zIndex: 999999999 }}
              divRef={versionRef}
              onClose={() => setShowVersions(false)}
              right={0}
              top={55}
              data={() => {
                return history.map((item, index) => {
                  let text, color;
                  if (item.appStoreState === "READY_FOR_SALE") {
                    color = "#4ABF7E"
                    text = "Released";
                  } else if (item.appStoreState === "PREPARE_FOR_SUBMISSION") {
                    color = "#FFD43A"
                    text = "Prepare For Submission";
                  }
                  return (
                    <div onClick={async () => {
                      setSelectedVersion(item);
                      const { version } = await request("app/get/app/version", "post", { versionId: item._id });
                      setVersionData(version);
                      const locales = Object.keys(version.metadata);
                      mixpanel.track("ASO_COPY_FROM_VERSION_SELECT", { from: isAll ? "metadata" : "smart_editor" });
                      setLocales(locales);
                      setSelectedLocale(null);
                    }} key={"selectedVersion#" + index} className="dropdown-input-item cursor">
                      {selectedVersion?.versionString === item.versionString ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
                      <span className="all_apps_app_text">
                        {item.versionString}
                      </span>
                      <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", flexDirection: "row", alignItems: "center", marginRight: 15 }}>
                        <div className="dot" style={{ backgroundColor: color }} />
                        <span style={{ color, fontSize: 12 }}>
                          {text}
                        </span>
                      </div>
                    </div>
                  )
                })
              }} />}
          </div>
          <div ref={localeRef} onClick={() => {
            if (!locales) return
            setShowLocale(!showLocale)
          }} className={!locales ? 'dropdown-input dropdown-input-inactive' : 'dropdown-input cursor'} >
            <span className={locales ? "dropdown-input-text" : 'dropdown-input-text dropdown-input-text-inactive'} >{selectedLocale ? selectedLocale.title : "Select Language"}</span>
            {showLocale && <DropdownModal
              containerStyle={{ width: 400, backgroundColor: "#1B1B2C", zIndex: 999999999 }}
              divRef={localeRef}
              onClose={() => setShowLocale(false)}
              right={0}
              top={55}
              data={() => {
                return locales.sort((a, b) => {
                  const _a = flags[a];
                  const _b = flags[b]
                  if (_a.name[0] < _b.name[0]) {
                    return -1;
                  }
                  if (_a.name[0] > _b.name[0]) {
                    return 1;
                  }
                  return 0
                }).map((item, index) => {
                  const flagInfo = flags[item];
                  return (
                    <div onClick={() => {
                      setShowLocale(false);
                      setSelectedLocale({
                        code: item,
                        title: flagInfo.name,
                        flag: flagInfo.flag
                      });
                      mixpanel.track("ASO_COPY_FROM_LANGUAGE_SELECT", { from: isAll ? "metadata" : "smart_editor" });
                    }} key={"flags#" + index} className="all_apps_app_container cursor">
                      <img src={flagInfo.flag} className="all_apps_app_icon" style={{ marginLeft: 20 }} />
                      <span className="all_apps_app_text">
                        {flagInfo.name}
                      </span>
                    </div>
                  )
                })
              }}
            />}
          </div>
        </div>
      </div>
      <Button
        text="Save"
        onClick={async () => {
          if (isAll) {
            let obj = {};
            for (let i = 0; i < staticStore.smartEditor.length; i++) {
              const field = staticStore.smartEditor[i].field;
              obj[field] = versionData.metadata[selectedLocale.code][field];
            }
            await appStore.pasteAllFrom(obj);
            modalStore.setShow(false);
            Store.addNotification({
              title: "Edited!",
              message: "The changes were successfully eddited on selected languages.",
              container: "top-right",
              insert: "top",
              type: "success",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            })
            mixpanel.track("ASO_COPY_FROM_SAVE", { from: isAll ? "metadata" : "smart_editor" });
          } else {
            await appStore.pasteFrom(_selections, selectedFilter.field, versionData.metadata[selectedLocale.code][selectedFilter.field]);
            modalStore.setShow(false);
            mixpanel.track("ASO_COPY_FROM_SAVE", { from: isAll ? "metadata" : "smart_editor" });
            Store.addNotification({
              title: "Edited!",
              message: "The changes were successfully eddited on selected languages.",
              container: "top-right",
              insert: "top",
              type: "success",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            })
          }
        }}
        style={{ width: "100px", alignSelf: "center", marginTop: "20px", backgroundColor: "white" }}
        textStyle={{ color: "#000000" }}
      />
    </div>
  )
})

export default PasteFrom