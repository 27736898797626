import DropdownModal from "../DropdownModal";
import "./index.css"
import React, { useRef, useState } from 'react'
import arrow from "../../assets/bottom_arrow_white.webp";

export default function RatingTopButton({ title, data, addButtonAction = () => { }, addButtonText = null }) {

  const divRef = useRef()
  const [show, setShow] = useState(false);

  return (
    <>
      <div
        ref={divRef}
        onClick={() => {
          setShow(!show);
        }}
        className="rt_top_button_container" >
        <span style={{ color: "white", fontWeight: 500, marginRight: 16 }}>
          {title}
        </span>
        <img src={arrow} className="arrow" />
        {
          show ? <DropdownModal
            addButtonAction={addButtonAction}
            addButtonText={addButtonText}
            data={data}
            onClose={() => {
              setShow(false);
            }} divRef={divRef} right={0} top={50} /> : null
        }
      </div>
    </>
  )
}
