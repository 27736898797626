
import { useEffect, useRef, useState } from "react";
import Button from "../../../components/Button";
import CustomInput from "../../../components/CustomInput";
import { observer } from 'mobx-react-lite';
import accountStore from "../../../stores/accountStore";
import appStore from "../../../stores/appStores";
import apple_icon from "../../../assets/apple.webp";
import add_icon from "../../../assets/add_icon.webp"
import { Link } from "react-router-dom"
import right_arrow from "../../../assets/rigt_arrow_white.webp"
import ThreeDot from "../../../components/Threedots";
import remove_icon from '../../../assets/remove.webp';
import modalStore from "../../../stores/modalStore";
import question_mark from "../../../assets/question_cirlce.webp";
import mixpanel from "../../../helpers/mixpanel";

const SecretItem = ({ name, appCount, _id }) => {
  const threedot = useRef()
  return (
    <div className="settings_account_item cursor">
      <div className="store_icon_container">
        <img src={apple_icon} className="store_icon" />
      </div>
      <div className="settings_account_container">
        <span className="settings_account_title">
          {name}
        </span>
        <span className="settings_account_desc">
          {appCount} Applications Connected
        </span>
      </div>
      <ThreeDot ref={threedot}>
        <div onClick={async () => {
          threedot.current.closeModal();
          modalStore.setContentSure(
            "Delete",
            question_mark,
            "All your data will be deleted from Aso.Studio. Are you sure?",
            "Yes, Remove Connection",
            "Cancel",
            async () => {
              await appStore.removeSecret(_id)
            }
          )

        }} className='add_app_remove_icon_container cursor'>
          <img src={remove_icon} className='add_app_remove_icon' />
          <span className='add_app_remove_text'>
            Remove Store Connection
          </span>
        </div>
      </ThreeDot>
    </div>
  )
}

const AddNewSecret = () => {
  return (
    <Link to={"/register/connection"} className="cursor settings_add_new_account_button">
      <img className="add_icon_new_account" src={add_icon} />
      <span className="setting_page_subtitle" style={{ marginLeft: 12 }}>
        Add New Connetion
      </span>
    </Link>
  )
}

const StoreConnections = observer(() => {

  const { secrets } = appStore;

  useEffect(() => {
    mixpanel.track("ASO_SETTINGS_SECRETS_TAB_VIEW");

  }, [])


  return (
    <div className="setting_page_container">
      <div className="settings_page_title_container" style={{ flexDirection: "column" }}>
        <span className="setting_page_title">
          Store Connections
        </span>
        <span className="setting_custom_desc">
          Here you can see your Store accounts that you have authorized to Aso.Studio and manage their permissions.
        </span>
      </div>
      <div style={{ display: "flex", marginLeft: 30, marginTop: 60, flexDirection: "column" }}>
        <span className="setting_page_subtitle">
          Connected Accounts
        </span>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {
            secrets.map((item, index) => {
              return <SecretItem key={"secretItem#" + index} {...item} />
            })
          }
          <AddNewSecret />
        </div>
      </div>
    </div>
  )
});

export default StoreConnections;