import "./index.css";
import arrow from "../../assets/bottom_arrow_white.webp"
import { observer } from "mobx-react-lite"
import tick from "../../assets/tick.webp";
import { useRef, useState } from "react";

import staticStore from "../../stores/staticStore";
import DropdownModal from "../DropdownModal";
import mixpanel from "../../helpers/mixpanel";
import ratingStore from "../../stores/ratingStore";

const RatingLanguageButton = observer(({ data, title }) => {

  const divRef = useRef()

  const [show, setShow] = useState(false);

  return (
    <>
      <div ref={divRef} onClick={() => {
        setShow(!show);
        if (!show) {
          mixpanel.track("ASO_LANGUAGE_BUTTON_CLICKED")
        }
      }} className="rt_country_button cursor">
        <span className="rt_country_name">{title}</span>
        <img src={arrow} className="rt_country_bottom_arrow" style={{ objectFit: "contain" }} />
        {show ? <DropdownModal
          data={data}
          onClose={() => {
            setShow(false)
          }} divRef={divRef} right={-10} top={45} /> : null}
      </div>
    </>
  )
});

export default RatingLanguageButton;