import { observer } from 'mobx-react-lite';
import appStore from '../../../stores/appStores';
import three_dots from "../../../assets/three_dots.webp";
import add_icon from "../../../assets/add_icon.webp"
import { useEffect, useRef, useState } from 'react';
import modalStore from '../../../stores/modalStore';
import question_mark from "../../../assets/question_cirlce.webp"
import ThreeDot from '../../../components/Threedots';
import remove_icon from '../../../assets/remove.webp';
import accountStore from '../../../stores/accountStore';
import { Store } from 'react-notifications-component';
import mixpanel from '../../../helpers/mixpanel';
import Button from '../../../components/Button';
import pull from '../../../assets/pull.webp';

const AppButton = ({ name, icon, pasive, _id }) => {

  const threedot = useRef();

  return (
    <div className="settings_account_item" style={{ backgroundColor: pasive ? "rgba(255,255,255,0.06)" : "rgba(255,255,255,0.1)" }}>
      <img src={icon} className="app_store_icon" style={{ opacity: pasive ? 0.4 : 1 }} />
      <div className="settings_account_container" style={{ opacity: pasive ? 0.4 : 1 }}>
        <span className="settings_account_title">
          {name}
        </span>
        <span className="settings_account_desc">
          {pasive ? "Passive" : "Connected"}
        </span>
      </div>
      {
        pasive ? <img onClick={() => {
          mixpanel.track("ASO_SETTINGS_APPS_ADD_APP_CLICK");
          modalStore.setContentSure("Adding New Application", question_mark, `${name} application adding to Aso.Studio.\nAre you sure?`, "Add App", "Cancel", async () => {
            await accountStore.getAccount();
            const { account } = accountStore;
            if (account.appCount >= account.subscription.appLimit) {
              Store.addNotification({
                title: "Warning",
                message: `Your app limit is full. Please upgrade your subscription.`,
                container: "top-right",
                insert: "top",
                type: "warning",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: true
                }
              })
              return;
            }
            await appStore.selectApps(appStore.selectedSecret._id, [_id])
            await appStore.getAllApps(appStore.selectedSecret._id);
            mixpanel.track("ASO_SETTINGS_APPS_ADD_APP_SAVE");
          })
        }} className='add_icon_settings cursor' src={add_icon} /> :
          <ThreeDot ref={threedot}>
            <div onClick={async () => {
              mixpanel.track("ASO_SETTINGS_APPS_REMOVE_APP_CLICK");
              threedot.current.closeModal();
              modalStore.setContentSure(
                "Delete",
                question_mark,
                "All the data of your app will be removed from Aso.Studio. Are you sure?",
                "Yes, Delete App",
                "Cancel",
                async () => {
                  await appStore.removeApp(_id)
                  mixpanel.track("ASO_SETTINGS_APPS_REMOVE_APP_SAVE");
                }
              )
            }} className='add_app_remove_icon_container cursor'>
              <img src={remove_icon} className='add_app_remove_icon' />
              <span className='add_app_remove_text'>
                Remove App
              </span>
            </div>
          </ThreeDot>
      }
    </div>
  )
}

const AppManagment = observer(() => {

  const { allApps, selectedSecret } = appStore


  const [isReady, setIsReady] = useState(false)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedSecret) {
      appStore.getAllApps(selectedSecret._id).then(() => {
        setIsReady(true)
      })
    } else {
      setIsReady(true)
    }
    mixpanel.track("ASO_SETTINGS_APPS_TAB_VIEW");
  }, [selectedSecret?._id]);

  if (!isReady) {
    return (
      <div>
        loading
      </div>
    )
  }

  const activeApps = allApps.filter((item) => item.active);
  const deactiveApps = allApps.filter((item) => !item.active);

  return (
    <div className="setting_page_container">
      <div className="settings_page_title_container" style={{ flexDirection: "column" }}>
        <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "space-between" }} >
          <div style={{ flexDirection: "column", display: "flex", justifyContent: "space-between" }} >
            <span className="setting_page_title">
              App Managment
            </span>
            <span className="setting_custom_desc">
              Here you can choose which applications of your connected account you want to control with Aso.Studio
            </span>
            {/* marginLeft: "0px" olarak verilirse prod patlıyor */}
            <Button
              icon={pull}
              loading={loading} loadingColor="white" onClick={async () => {
                setLoading(true);
                await appStore.refreshApps(selectedSecret._id);
                setLoading(false);
              }} text="Pull Apps" style={{ backgroundColor: "rgba(255,255,255,0.1)", width: 130, marginLeft: 0, marginTop: "16px", marginRight: "40px", paddingLeft: "12px", paddingRight: "12px" }} textStyle={{ color: "white", textAlign: "center" }} color="white" />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", marginLeft: 30, marginTop: 60, flexDirection: "column" }}>
        <span className="setting_page_subtitle">
          Active Apps
        </span>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {activeApps.map((item, index) => {
            return <AppButton key={"appButton#" + index}  {...item} />
          })}
        </div>
      </div>
      <div style={{ display: "flex", marginLeft: 30, marginTop: 60, flexDirection: "column" }}>
        <span className="setting_page_subtitle">
          Passive Apps
        </span>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {deactiveApps.map((item, index) => {
            return <AppButton key={"appButton#" + index} pasive {...item} />
          })}
        </div>
      </div>
    </div>
  )
});

export default AppManagment;
