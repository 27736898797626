import { makeAutoObservable } from "mobx"


class ModalStore {

  show = false;
  children = null;
  title = "";

  //ASK SURE
  icon = null;
  text = null;
  isSure = false;
  positiveText = "";
  negativeText = "";
  isBig = false;
  cb = () => { };
  scroll = "unset";

  constructor() {
    makeAutoObservable(this)
  }

  setShow(show) {
    this.show = show;
    this.children = null;
    this.isSure = false;
  }

  setContent(title, children, isBig = true, scroll = "unset") {
    this.title = title;
    this.children = children
    this.isBig = isBig;
    this.show = true;
    this.scroll = scroll;
  }

  setContentSure(title, icon, text, positiveText, negativeText, cb) {
    this.title = title;
    this.icon = icon
    this.text = text;
    this.cb = cb;
    this.isSure = true
    this.positiveText = positiveText;
    this.negativeText = negativeText;
    this.isBig = false;
    this.show = true;

  }

}

const modalStore = new ModalStore();
export default modalStore;