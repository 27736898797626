import { observer } from "mobx-react-lite"
import "./index.css"
import React from 'react'
import ratingStore from "../../stores/ratingStore"
import NegativeIcon from "../../assets/NegativeIcon.webp"
import PositiveIcon from "../../assets/PositiveIcon.webp"

const Card = ({ title, number, subText, percentage, reverse }) => {
  let border = title === "Unanswered Reviews" ? { border: "none" } : {}
  let isNegative = reverse ? subText > 0 : subText < 0;
  return (
    <div className="rating_stats_card" style={border} >
      <span className="rating_stats_card_title" >
        {title}
      </span>
      <span className="rating_stats_card_number" >
        {number ? number : 0}{percentage ? "%" : ""}
      </span>
    </div>
  )
}

function RatingStats() {
  const { reviews } = ratingStore;
  const { stats } = reviews;
  if (!stats) return null
  return (
    <div className='rating_stats_container' >
      <Card number={stats.total} title={"Review Count"} />
      <Card number={stats.answeredCount} title={"Answered Count"} />
      <Card percentage number={stats.answeredPercent} title={"Response Rate"} />
    </div>
  )
}

export default observer(RatingStats);
