import { observer } from "mobx-react-lite"
import Search from "../Search"
import Selector from "../Selector"
import TableHeader from "../TableHeader"
import "./index.css"
import React, { useEffect, useRef, useState } from 'react'
import pricingStore from "../../stores/pricingStore"
import staticStore from "../../stores/staticStore"
import { FormattedNumber } from "react-intl"
import DropdownModal from "../DropdownModal"
import tick from "../../assets/tick.webp";
import Button from "../Button"
import RatingTopButton from "../RatingTopButton"
import templateStore from "../../stores/templateStore"
import arrow from "../../assets/bottom_arrow_white.webp";
import modalStore from "../../stores/modalStore"
import DropLine from "../../assets/DropLine.webp";
import question_mark from "../../assets/question_cirlce.webp";
import { Store } from "react-notifications-component"

const PricePickerModal = ({ title, data, onClick = () => { } }) => {

  const divRef = useRef()
  const [show, setShow] = useState(false);

  return (
    <>
      <div
        ref={divRef}
        onClick={async () => {
          await onClick();
          setShow(!show);
        }}
        className="rt_top_button_container" >
        <span style={{ color: "white", fontWeight: 500, marginRight: 16 }}>
          {title}
        </span>
        <img src={arrow} className="arrow" />
        {
          show ? <DropdownModal
            data={data}
            onClose={() => {
              setShow(false);
            }} divRef={divRef} left={0} right={0} top={50} /> : null
        }
      </div>
    </>
  )
}

function ButtonLoading({ color = "white" }) {
  return (
    <div class="lds-ring">
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
    </div>
  )
}

const PricingPointList = observer(({ item, setBasePrice, data }) => {
  const [pricingPoints, setPricingPoints] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const points = await pricingStore.getPricingPoints(data.appId, data.groupId, data.subsId, ["USA"]);
      setPricingPoints(points);
    }
    fetchData();
    return () => {
      console.log("dğştü")
    }
  }, [])

  if (!pricingPoints) {
    return (
      <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} >
        <ButtonLoading />
      </div>
    )
  }
  return (
    pricingPoints.map(e => {
      return <div className="all_apps_app_container" onClick={() => {
        setBasePrice(e);
        pricingStore.setTemplatePrices(null);
        // pricingStore.setSubsPoint(item, e);
      }} >
        {/* {e.pricing.customerPrice == item.pricing.customerPrice ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />} */}
        <span className="all_apps_app_text" >
          <FormattedNumber value={e.pricing.customerPrice}
            style="currency" currency={e.currency} />
        </span>
      </div>
    })
  )
})

const preserveTip = <div className='price-template-modal-tip-container' >
  <span className='price-template-modal-tip-text' >
    You can preserve the current price for existing subscribers who are paying the price of your subscription currently displayed on the App Store while increasing the price for new subscribers. If you choose to apply a price increase without preserving prices, existing subscribers may need to agree to the price increase.
  </span>
</div>

const TableHeaderRow = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const [basePrice, setBasePrice] = useState(data.basePrice);
  const [preserve, setPreserve] = useState(true);
  const [date, setDate] = useState(-1);
  const [template, setTemplate] = useState(data.template);
  let now = new Date();
  let tomorrow = new Date().setDate(now.getDate() + 1);
  const dateArray = [{
    label: "Today",
    key: "today",
    date: now
  }, {
    label: "Tomorrow",
    key: "tomorrow",
    date: tomorrow
  }]
  const { templates } = templateStore;
  const { templatePrices } = pricingStore;
  return (
    <div>
      <div className="pricing-template-modal-table-header-row" >
        <div style={{ display: "flex", flexDirection: "row", marginLeft: 40 }} >
          <PricePickerModal title={template?.name ? template?.name : "Select Template"} data={() => {
            return templates.map((item, index) => {
              return (
                <div onClick={async () => {
                  setTemplate(item);
                }} key={"selectedVersion#" + index} className="all_apps_app_container cursor">
                  {template?._id === item?._id ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
                  <span className="all_apps_app_text">
                    {item.name}
                  </span>
                </div>
              )
            })
          }} />
          <PricePickerModal onClick={async () => {
          }} title={basePrice?.pricing.customerPrice ? <FormattedNumber value={basePrice?.pricing.customerPrice}
            style="currency" currency={basePrice?.currency} /> : "Select Base Price"} data={() => {
              return <PricingPointList data={data} item={basePrice} setBasePrice={setBasePrice} key={new Date().getTime()} />
            }} />
          <PricePickerModal onClick={async () => {
          }} title={date >= 0 ? dateArray[date].label : "Select Date"} data={() => {
            return dateArray.map((item, index) => {
              return (
                <div onClick={async () => {
                  setDate(index);
                }} key={"selectedVersion#" + index} className="all_apps_app_container cursor">
                  {date === item.index ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
                  <span className="all_apps_app_text">
                    {item.label}
                  </span>
                </div>
              )
            })
          }} />
        </div>
        <div className="price-template-modal-header-right" >
          <div className="price-template-modal-preserve-container" >
            <span onClick={() => {
              setPreserve(!preserve);
            }} style={{ backgroundColor: !preserve ? "#426bfd" : "#101022" }} className="price-template-modal-preserve-tick-container" >
              {!preserve && <img src={tick} />}
            </span>
            <span className="price-template-modal-preserve-text" >
              Apply price increase to existing subscribers
              <img
                onMouseEnter={() => {
                  setHover(true);
                }}
                onMouseLeave={() => {
                  setHover(false);
                }}
                src={question_mark} />
              {
                hover && preserveTip
              }
            </span>
          </div>
          <Button loading={loading} text={templatePrices ? "Save" : "Calculate"} onClick={async () => {
            if (templatePrices) {
              if (date === -1) {
                Store.addNotification({
                  title: "Warning!",
                  message: "Please select date.",
                  container: "top-right",
                  insert: "top",
                  type: "warning",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 5000,
                    onScreen: true
                  }
                })
                return
              }
              setLoading(true);
              await pricingStore.saveTemplatePrices(data.appId, data.subsId, template._id, basePrice, preserve, dateArray[date].date);
              await pricingStore.getSubscriptions(data.appId);
              setLoading(false);
              modalStore.setShow(false);
            } else {
              setLoading(true);
              await pricingStore.getTemplatePrices(data.appId, data.subsId, template._id, basePrice);
              setLoading(false);
            }
          }} />
        </div>
      </div>
      <TableHeader
        firstItem={"Countries"}
        items={[
          "Current Price",
          "Price Dollar Equivalent"
        ]} />
    </div>
  )
}

const TableRow = observer(({ item, data }) => {
  const { countries } = staticStore;
  const divRef = useRef()
  const [show, setShow] = useState(false);
  let flag = countries.findIndex(c => c.iso3 === item.territory);
  return (
    <div className="table-row-container">
      <img className="pricing-edit-modal_item_bottom_card_flag" src={countries[flag].flag} />
      <span className="table-row-item-title" style={{ flex: 2, marginLeft: 12 }} >
        {item.country}</span>
      <div className="table-header-right-container" style={{ flex: 2, marginLeft: -30 }} >
        <span ref={divRef} onClick={async () => {
        }} className="table-row-item-title"  >
          <span className="price-template-modal-table-row-item-price" >
            <FormattedNumber value={item.pricing.customerPrice}
              style="currency" currency={item.currency} />
          </span>
        </span>
        <span className="table-row-item-title"  >
          <FormattedNumber value={item.baseEquivalent}
            style="currency" currency={item.baseCurrency} />
        </span>
      </div>
    </div >
  )
})

function PricingTemplateModal({ data, item }) {
  const { templatePrices } = pricingStore;
  return (
    <div className='pricing-edit-modal-container' style={{ overflow: "scroll" }} >
      <div className="pricing-edit-modal-inner-container" >
        <TableHeaderRow data={data} item={item} />
        {
          templatePrices?.map(e => {
            return <TableRow data={data} item={e} />
          })
        }
      </div>
    </div>
  )
}

export default observer(PricingTemplateModal);