import { useEffect, useState } from "react";
import Header from "../../components/Header";
import "./index.css"
import { useParams } from "react-router-dom";
import AppDetailHeader from "../../components/AppHeaderDetail";
import appStore from "../../stores/appStores";
import ratingStore from "../../stores/ratingStore";
import accountStore from "../../stores/accountStore";
import request from "../../helpers/request";
import Subs from "./Subs";
import Templates from "./Templates";
import InApps from "./InApps";
import pricingStore from "../../stores/pricingStore";
import templateStore from "../../stores/templateStore";
import inAppStore from "../../stores/inAppStore";

function ButtonLoading({ color = "white" }) {
  return (
    <div class="lds-ring">
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
    </div>
  )
}

export default function Pricing() {

  const { id } = useParams()
  const [isReady, setIsReady] = useState(null);
  const [tab, setTab] = useState(1);

  useEffect(() => {
    const refresh = localStorage.getItem("token");
    if (refresh) {
      request("app/get/app/" + id).then(async (resp) => {
        await pricingStore.getSubscriptions(id);
        await inAppStore.getInApps(id);
        await templateStore.getTemplates();
        appStore.setSelectedApp(resp.app);
        if (!resp.done) {
          localStorage.removeItem("token");
          window.location = "/login";
        }
        appStore.setSelectedApp(resp.app);
        await ratingStore.getRatings(id);
        ratingStore.setApp(resp.app);
        accountStore.getAccount();

        if (appStore.apps.length === 0) {
          await appStore.getSecrets();
          await appStore.getApps();
        }
        setIsReady(true)
      })

    } else {
      setIsReady(false)
    }
    return () => {
    }

  }, [id])

  const renderTab = () => {
    switch (tab) {
      case 0:
        return <InApps />
      case 1:
        return <Subs />
      case 2:
        return <Templates />

      default:
        break;
    }
  }

  return (
    <div className='pricing_container' >
      <Header pathKey={"pricing"} />
      {isReady === null ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 120 }} >
        <ButtonLoading />
      </div> : <>
        <AppDetailHeader type="pricing" tab={tab} setTab={(e) => setTab(e)} />
        {renderTab()}
      </>}
    </div>
  )
}