import { useCallback, useEffect, useState } from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import request from "../../helpers/request";
import accountStore from "../../stores/accountStore";
import gradient from "../../assets/login_gradient.webp";
import logo from "../../assets/logo.webp";
import "./index.css";

import {
  Link,
  Navigate
} from "react-router-dom";
import appStore from "../../stores/appStores";
import { Store } from "react-notifications-component";
import { validateEmail } from "../../helpers/statics";
import mixpanel from "../../helpers/mixpanel";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { sendTwitterEvent } from "../../helpers/pixel";


const Register = () => {
  const [navigate, setNavigate] = useState(false)
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [captcha, setCaptcha] = useState(null)
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('login');
    setCaptcha(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex: 1, height: "100vh", width: "100%" }}>
      <img src={gradient} style={{ width: "100%", height: "100%", position: "absolute", top: 0, right: 0, zIndex: 1 }} />
      <img src={logo} style={{ width: "300px", height: "auto", objectFit: "contain", zIndex: 2, marginBottom: 20, marginTop: 100 }} />
      <div className="login_container">
        <span className="login_text">
          Create Account
        </span>
        <div style={{ height: 30 }} />
        <Input type="text" title={"Full Name"} value={name} onChange={setName} />
        <Input type="text" title={"E-Mail"} value={email} onChange={setEmail} />
        <Input type="password" title={"Password"} value={password} onChange={setPassword} />
        <span className="register-terms-text" >By signing up, you agree to Aso.Studio’s <Link to={"https://aso.studio/privacy"} className="register-terms-link" >Privacy Policy</Link>, <Link className="register-terms-link" >Terms and Conditions</Link> and ours promotional emails.</span>
        <Button style={{ marginLeft: 0, marginBottom: 30, width: 150, backgroundColor: "white", alignSelf: "center", marginTop: 18 }} textStyle={{ color: "black" }} onClick={async () => {
          let message;
          let title;
          mixpanel.track("ASO_REGISTER_SUBMIT");
          const isValid = validateEmail(email);
          if (!name) {
            title = "Full Name"
            message = "Please enter your full name."
          } else if (!email) {
            title = "Email"
            message = "Please enter your email address."
          } else if (!password) {
            title = "Password"
            message = "Please check your password."
          } else if (password.length < 4) {
            title = "Password"
            message = "Password must be at least 8 characters."
          } else if (!isValid) {
            title = "Email"
            message = "The email address you provided is not valid."
          }
          if (message) {
            mixpanel.track("ASO_REGISTER_SUBMIT_WARNING", { title, message });
            Store.addNotification({
              title: title,
              message: message,
              container: "top-right",
              insert: "top",
              type: "warning",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            })
            return
          }
          const resp = await request("auth/register", "post", { name, email, password, captcha })
          handleReCaptchaVerify();
          if (resp.done) {
            mixpanel.track("ASO_REGISTER_SUBMIT_SUCCES");
            sendTwitterEvent(email, "tw-ohlkv-ohm4m")
            accountStore.setAccount(resp.account);
            mixpanel.identify(resp.account._id);
            mixpanel.register(resp.account);
            localStorage.setItem("token", resp.token);
            setNavigate("/register/connection")
          } else {
            mixpanel.track("ASO_REGISTER_SUBMIT_ERROR");
            Store.addNotification({
              title: "Invalid Credentials",
              message: "Please check the credentials you provided.",
              container: "top-right",
              insert: "top",
              type: "warning",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            })
          }
        }
        } text="Register" />
      </div>
      <div style={{ zIndex: 3, marginTop: 12 }} >
        <span className="login-no-account" >Already have an account?  </span>
        <Link onClick={() => {
          mixpanel.track("ASO_REGISTER_BOTTOM_LOGIN_CLICK");
        }} to={"/login"} className="login-no-account" style={{ color: "white" }}>  Login</Link>
      </div>
      {
        navigate && <Navigate to={navigate} />
      }
    </div>
  )
}

export default Register;