import { makeAutoObservable } from "mobx"

class SmartEditorStore {

  selectedFilter = {
    displayName: "Name",
    field: "name",
  };

  constructor() {
    makeAutoObservable(this)
  }

  setSelectedFilter(selectedFilter) {
    this.selectedFilter = selectedFilter;
  }
}

const smartEditorStore = new SmartEditorStore();
export default smartEditorStore;