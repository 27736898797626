import { useState } from "react";
import CustomInput from "../CustomInput";
import "./index.css";
import Button from "../Button";
import appStore from "../../stores/appStores";
import modalStore from "../../stores/modalStore";
import mixpanel from "../../helpers/mixpanel";

const CreateNewVersion = () => {
  const [isLoading, setLoading] = useState(false)
  const [version, setVersion] = useState("")
  return (
    <div className="create_new_version">
      <CustomInput
        editable={true}
        value={version}
        onChange={(val) => setVersion(val)}
        title={"Version"}
        copy={false}
        placeholder={"Type version"}
      />
      <Button loadingColor="black" loading={isLoading} onClick={async () => {
        setLoading(true);
        await appStore.createNewVersion(version);
        mixpanel.track("ASO_NEW_VERSION_SAVED");
        modalStore.setShow(false);
      }} text="Create" style={{ backgroundColor: "white", marginLeft: 0, width: 100 }} textStyle={{ color: "black" }} />
    </div>
  )
}


export default CreateNewVersion