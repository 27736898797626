import { observer } from "mobx-react-lite"

import "./index.css";
import selectionStore from "../../stores/selectionStore";
import CountryFlag from "../CountryFlag";
import CustomInput from "../CustomInput";
import smartEditorStore from "../../stores/smartEditorStore";
import { useRef, useState } from "react";
import { MAX_LENGTH } from "../../helpers/statics"
import Button from "../Button";
import appStore from "../../stores/appStores";
import request from '../../helpers/request'
import tick from "../../assets/tick.webp";
import staticStore from "../../stores/staticStore";
import DropdownModal from "../DropdownModal";
import modalStore from "../../stores/modalStore";
import mixpanel from "../../helpers/mixpanel";

const TranslateFrom = observer(() => {
  const { selections } = selectionStore;
  const { selectedFilter } = smartEditorStore;
  const { history } = appStore;
  const { flags } = staticStore;

  // BUG: ŞU AN EDİTLENEN VERSİYONU SEÇERSE DEĞİŞİKLİKLER DB DEN Mİ GELECEK LOKALDEN Mİ?

  const versionRef = useRef();
  const localeRef = useRef();
  const [showVersions, setShowVersions] = useState(false);
  const [versionData, setVersionData] = useState(null);
  const [showLocale, setShowLocale] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedLocale, setSelectedLocale] = useState(null);
  const [locales, setLocales] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showTranslates, setShowTranslates] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const _selections = Object.keys(selections).filter((s) => selections[s].isSelected);
  return (
    <div className="modal_inner_container_tf_2" >
      <div className="modal_inner_container_tf"  >
        <span className="bulk_edit_text">
          {_selections.length} countries have been selected
        </span>
        <div className="bulk_edit_flag_container">
          {
            _selections.map((countryCode, index) => {
              return (
                <CountryFlag countryCode={countryCode} key={index + "#" + countryCode} />
              )
            })
          }
        </div>
        <div className='dropdown-input-container' style={{ marginTop: 16 }} >
          <span className='dropdown-input-title' >Translate From</span>
          <div className='mt-flex-row' style={{ alignItems: "end" }} >
            <div ref={versionRef} onClick={() => setShowVersions(!showVersions)} className='dropdown-input cursor' >
              <span className='dropdown-input-text' >{selectedVersion ? selectedVersion.versionString : "Select Version"}</span>
              {showVersions && <DropdownModal
                containerStyle={{ width: 400, backgroundColor: "#1B1B2C", zIndex: 999999999 }}
                divRef={versionRef}
                onClose={() => setShowVersions(false)}
                right={0}
                top={55}
                data={() => {
                  return history.map((item, index) => {
                    let text, color;
                    if (item.appStoreState === "READY_FOR_SALE") {
                      color = "#4ABF7E"
                      text = "Released";
                    } else if (item.appStoreState === "PREPARE_FOR_SUBMISSION") {
                      color = "#FFD43A"
                      text = "Prepare For Submission";
                    }
                    return (
                      <div onClick={async () => {
                        setCanSave(false);
                        setSelectedVersion(item);
                        const { version } = await request("app/get/app/version", "post", { versionId: item._id });
                        setVersionData(version);
                        const locales = Object.keys(version.metadata);
                        setLocales(locales);
                        setSelectedLocale(null);
                      }} key={"selectedVersion#" + index} className="dropdown-input-item cursor">
                        {selectedVersion?.versionString === item.versionString ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
                        <span className="all_apps_app_text">
                          {item.versionString}
                        </span>
                        <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", flexDirection: "row", alignItems: "center", marginRight: 15 }}>
                          <div className="dot" style={{ backgroundColor: color }} />
                          <span style={{ color, fontSize: 12 }}>
                            {text}
                          </span>
                        </div>
                      </div>
                    )
                  })
                }} />}
            </div>
            <div ref={localeRef} onClick={() => {
              if (!locales) return
              setShowLocale(!showLocale)
            }} className={!locales ? 'dropdown-input dropdown-input-inactive' : 'dropdown-input cursor'} >
              <span className={locales ? "dropdown-input-text" : 'dropdown-input-text dropdown-input-text-inactive'} >{selectedLocale ? selectedLocale.title : "Select Language"}</span>
              {showLocale && <DropdownModal
                containerStyle={{ width: 400, backgroundColor: "#1B1B2C", zIndex: 999999999 }}
                divRef={localeRef}
                onClose={() => setShowLocale(false)}
                right={0}
                top={55}
                data={() => {
                  return locales.sort((a, b) => {
                    const _a = flags[a];
                    const _b = flags[b]
                    if (_a.name[0] < _b.name[0]) {
                      return -1;
                    }
                    if (_a.name[0] > _b.name[0]) {
                      return 1;
                    }
                    return 0
                  }).map((item, index) => {
                    const flagInfo = flags[item];
                    return (
                      <div onClick={() => {
                        setCanSave(false);
                        setShowLocale(false);
                        setSelectedLocale({
                          code: item,
                          title: flagInfo.name,
                          flag: flagInfo.flag
                        });
                      }} key={"flags#" + index} className="all_apps_app_container cursor">
                        <img src={flagInfo.flag} className="all_apps_app_icon" style={{ marginLeft: 20 }} />
                        <span className="all_apps_app_text">
                          {flagInfo.name}
                        </span>
                      </div>
                    )
                  })
                }}
              />}
            </div>
            <Button
              loading={buttonLoading}
              loadingColor={"white"}
              onClick={async () => {
                try {
                  setButtonLoading(true);
                  let to = _selections.filter(e => e !== selectedLocale.code)
                  const resp = await request("app/translate", "POST", { from: selectedLocale.code, to, field: selectedFilter.field, appId: versionData.app, versionId: versionData._id })
                  setShowTranslates(resp.suggestions);
                  setButtonLoading(false);
                  setCanSave(true);
                } catch (error) {
                  setButtonLoading(false);
                  console.log('error: ', error);
                }
              }}
              text={"Translate"}
              textStyle={{ color: "white" }}
              style={{ backgroundColor: "transparent", borderWidth: 1, borderColor: "white", borderStyle: "solid", height: 30 }} />
          </div>
          <span className="selected-locale-data" >{versionData?.metadata?.[selectedLocale?.code]?.[selectedFilter.field]}</span>
          <div style={{ marginTop: 20 }} >
            {
              showTranslates && <>
                {showTranslates.map((e) => {
                  const flagInfo = flags[e.locale];
                  return (
                    <CustomInput
                      flag={flagInfo.flag}
                      multiline={selectedFilter.multiline}
                      title={`${selectedFilter.displayName} for ${flagInfo.name}`}
                      value={e.result}
                      maxLength={MAX_LENGTH[selectedFilter.field]} />
                  )
                })}
              </>
            }
          </div>
        </div>
      </div>
      {showTranslates.length > 0 && <div className="tf_modal_bottom" >
        <Button onClick={() => {
          setSaveLoading(true);
          for (let i = 0; i < showTranslates.length; i++) {
            const element = showTranslates[i];
            appStore.editSelectedMetadataWithLocale(element.locale, element.field, element.result)
          }
          mixpanel.track("ASO_SMART_EDITOR_TRANSLATE_FROM_SAVE");
          setSaveLoading(false);
          modalStore.setShow(false);
        }} loading={saveLoading} text="Save" color="white" style={{ backgroundColor: "white", height: 30, width: "100px" }} textStyle={{ color: "#1B1B2C" }} />
      </div>}
    </div>
  )
})

export default TranslateFrom;