import { observer } from "mobx-react-lite"
import Search from "../Search"
import Selector from "../Selector"
import TableHeader from "../TableHeader"
import "./index.css"
import React, { useEffect, useRef, useState } from 'react'
import pricingStore from "../../stores/pricingStore"
import staticStore from "../../stores/staticStore"
import { FormattedNumber } from "react-intl"
import DropdownModal from "../DropdownModal"
import tick from "../../assets/tick.webp";
import DropLine from "../../assets/DropLine.webp";
import Button from "../Button"
import modalStore from "../../stores/modalStore"
import templateStore from "../../stores/templateStore"
import arrow from "../../assets/bottom_arrow_white.webp";
import question_mark from "../../assets/question_cirlce.webp";

const PricePickerModal = ({ title, data, onClick = () => { } }) => {

  const divRef = useRef()
  const [show, setShow] = useState(false);

  return (
    <>
      <div
        ref={divRef}
        onClick={async () => {
          await onClick();
          setShow(!show);
        }}
        className="rt_top_button_container" >
        <span style={{ color: "white", fontWeight: 500, marginRight: 16 }}>
          {title}
        </span>
        <img src={arrow} className="arrow" />
        {
          show ? <DropdownModal
            data={data}
            onClose={() => {
              setShow(false);
            }} divRef={divRef} left={0} right={0} top={50} /> : null
        }
      </div>
    </>
  )
}

function ButtonLoading({ color = "white" }) {
  return (
    <div class="lds-ring">
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
      <div style={{ borderColor: `${color} transparent transparent transparent` }} ></div>
    </div>
  )
}

const PricingPointList = observer(({ item, data }) => {
  const [pricingPoints, setPricingPoints] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const points = await pricingStore.getPricingPoints(data.appId, data.groupId, data.subsId, [item.territory]);
      setPricingPoints(points);
    }
    fetchData();
    return () => {
      console.log("dğştü")
    }
  }, [])

  if (!pricingPoints) {
    return (
      <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} >
        <ButtonLoading />
      </div>
    )
  }
  return (
    pricingPoints.map(e => {
      return <div className="all_apps_app_container" onClick={() => {
        pricingStore.setSubsPoint(item, e);
      }} >
        {e.pricing.customerPrice == item.pricing.customerPrice ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
        <span className="all_apps_app_text" >
          <FormattedNumber value={e.pricing.customerPrice}
            style="currency" currency={e.currency} />
        </span>
      </div>
    })
  )
})

const preserveTip = <div className='price-template-modal-tip-container' >
  <span className='price-template-modal-tip-text' >
    You can preserve the current price for existing subscribers who are paying the price of your subscription currently displayed on the App Store while increasing the price for new subscribers. If you choose to apply a price increase without preserving prices, existing subscribers may need to agree to the price increase.
  </span>
</div>


const TableHeaderRow = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const [basePrice, setBasePrice] = useState(data.basePrice);
  const [preserve, setPreserve] = useState(true);
  const [date, setDate] = useState(-1);
  const [template, setTemplate] = useState(data.template);
  let now = new Date();
  let tomorrow = new Date().setDate(now.getDate() + 1);
  const dateArray = [{
    label: "Today",
    key: "today",
    date: now
  }, {
    label: "Tomorrow",
    key: "tomorrow",
    date: tomorrow
  }]
  const { templates } = templateStore;
  const { templatePrices } = pricingStore;
  return (
    <div>
      <div className="pricing-edit-modal-table-header-row" >
        <Search onChange={(e) => {
          pricingStore.searchSubsDetails(e.target.value);
        }} placeholder={"Search Country"} />
        <div className="price-template-modal-preserve-container" >
          <PricePickerModal onClick={async () => {
          }} title={date >= 0 ? dateArray[date].label : "Select Date"} data={() => {
            return dateArray.map((item, index) => {
              return (
                <div onClick={async () => {
                  setDate(index);
                }} key={"selectedVersion#" + index} className="all_apps_app_container cursor">
                  {date === item.index ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
                  <span className="all_apps_app_text">
                    {item.label}
                  </span>
                </div>
              )
            })
          }} />
          <span onClick={() => {
            setPreserve(!preserve);
          }} style={{ backgroundColor: !preserve ? "#426bfd" : "#101022" }} className="price-template-modal-preserve-tick-container" >
            {!preserve && <img src={tick} />}
          </span>
          <span className="price-template-modal-preserve-text" >
            Apply price increase to existing subscribers
            <img
              onMouseEnter={() => {
                setHover(true);
              }}
              onMouseLeave={() => {
                setHover(false);
              }}
              src={question_mark} />
            {
              hover && preserveTip
            }
          </span>
        </div>
        <Button loading={loading} loadingColor="black" text="Save" color="white" textStyle={{ color: "black" }}
          onClick={async () => {
            setLoading(true);
            await pricingStore.saveDetails(data.appId, data.subsId, dateArray[date].date, preserve);
            await pricingStore.getSubscriptions(data.appId);
            setLoading(false);
            modalStore.setShow(false);
          }} />
      </div>
      <TableHeader
        firstItem={"Countries"}
        items={[
          "Current Price",
        ]} />
    </div>
  )
}

const TableRow = observer(({ item, data }) => {
  const { countries } = staticStore;
  const divRef = useRef()
  const [show, setShow] = useState(false);
  let flag = countries.findIndex(c => c.iso3 === item.territory);
  return (
    <div className="table-row-container">
      <img className="pricing-edit-modal_item_bottom_card_flag" src={countries[flag].flag} />
      <span className="table-row-item-title" style={{ flex: 2, marginLeft: 10 }} >
        {item.country}</span>
      <div className="table-header-right-container" style={{ flex: 1, marginLeft: -24 }} >
        <span ref={divRef} onClick={async () => {
          setShow(!show);
        }} className="table-row-item-title"  >
          {
            show ? <DropdownModal
              containerStyle={{ width: 240, zIndex: 999999999 }}
              data={() => {
                return <PricingPointList data={data} item={item} key={new Date().getTime()} />
              }}
              onClose={() => {
                setShow(false)
              }} divRef={divRef} right={150} top={50} >
            </DropdownModal> : null
          }
          <span className="price-edit-modal-table-row-item-price" >
            <FormattedNumber value={item.pricing.customerPrice}
              style="currency" currency={item.currency} />
            <img src={DropLine} className="price-edit-modal-table-row-item-dropline" />
          </span>
        </span>
      </div>
    </div >
  )
})

function PricingEditModal({ data }) {
  const { subscriptionDetails, filteredDetails } = pricingStore;
  let array = filteredDetails ? filteredDetails : subscriptionDetails;
  if (!subscriptionDetails) return null;
  return (
    <div className='pricing-edit-modal-container' style={{ overflow: "scroll" }} >
      <div className="pricing-edit-modal-inner-container" >
        <TableHeaderRow data={data} />
        {
          array?.prices?.map(e => {
            return <TableRow key={`${new Date().getTime()}####${array.length}###${e.country}`} data={data} item={e} />
          })
        }
      </div>
    </div>
  )
}

export default observer(PricingEditModal);