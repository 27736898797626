import "./index.css";
import Search from "../../../components/Search"
import TableHeader from "../../../components/TableHeader";
import Selector from "../../../components/Selector";
import RightArrow from "../../../assets/right-arrow.webp";
import ComingSoon from "../../../assets/coming-soon.webp";
import LLine from "../../../assets/l-line.webp";
import { useState } from "react";
import modalStore from "../../../stores/modalStore";
import PricingEditModal from "../../../components/PricingEditModal";
import inAppStore from "../../../stores/inAppStore";
import { observer } from "mobx-react-lite";
import InAppEditModal from "../../../components/InAppEditModal";
import InAppTemplateModal from "../../../components/InAppTemplateModal";
import templateStore from "../../../stores/templateStore";
import Button from "../../../components/Button";
import { useParams } from "react-router-dom";

const TableHeaderRow = () => {
  return (
    <div>
      <Search onChange={(e) => {
        inAppStore.searchReference(e.target.value);
      }} placeholder={"Search Reference"} />
      <TableHeader
        firstItem={"Reference Name"}
        items={[
          "Product ID",
          "Template"
        ]} />
    </div>
  )
}

const TableRow = ({ item }) => {
  const { templates } = templateStore;
  const temp = templates.find(t => t._id === item.template)
  return (
    <div>
      <div className="inapp-table-row-container" >
        <span
          onClick={() => {
            inAppStore.getInAppDetails(item.app, item.id);
            modalStore.setContent(item.name, <InAppEditModal data={{ appId: item.app, inAppId: item.id, basePrice: item.baseTerritory }} isAll={true} />, true, "scroll");
          }}
          className="inapp-table-row-item-title" style={{ flex: 2, marginLeft: 40, color: "#426BFD", cursor: "pointer" }} >{item.name}</span>
        <div className="table-header-right-container" style={{ flex: 2 }} >
          <span className="inapp-table-row-item-title"  >{item.productId}</span>
          <div className="pricing-subs-table-sub-row-item-right" >
            <span className="pricing-subs-table-sub-row-item-template" onClick={async () => {
              await inAppStore.setTemplatePrices(null);
              modalStore.setContent(item.name, <InAppTemplateModal data={{ appId: item.app, inAppId: item.id, template: temp, basePrice: item.basePrice }} isAll={true} />, true, "scroll");
            }} >
              {temp ? temp.name : "No Template"}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const EmptyScreen = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  return (
    <div className='subs-page-empty-container' >
      {/* <img src={SubsEmpty} /> */}
      <p>
        This screen you will be able to view subscription groups and subscription packages, edit the price and apply templates to subscriptions.
      </p>
      <p>
        View your data now by pressing Pull.
      </p>
      <Button
        loading={loading}
        onClick={async () => {
          setLoading(true);
          await inAppStore.pull(id);
          setLoading(false);
        }}
        color="white"
        style={{ marginLeft: 0 }}
        textStyle={{ color: "black", padding: "0px 50px" }}
        text="Pull"
      />
    </div>
  )
}

function InApps() {

  // return (
  //   <div className="inapp-page-container" >
  //     <img src={ComingSoon} className="coming-soon-image" />
  //     <span className="coming-soon" >
  //       Coming Soon...
  //     </span>
  //   </div>
  // )

  const { inApps, filterInapps } = inAppStore;
  let array = filterInapps ? filterInapps : inApps;
  if (!inApps || inApps.length <= 0) {
    return <EmptyScreen />
  }
  return (
    <div>
      <TableHeaderRow />
      {
        array.map(e => {
          return <TableRow item={e} />
        })
      }
    </div>
  )

}

export default observer(InApps);
