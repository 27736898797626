import "./index.css"

const Input = ({ title, value, onChange = () => { }, onSubmit = null, placeholder, type = "text" }) => {
  return (
    <div className='default_input_container' >
      <span className='default_input_title' >{title}</span>
      <input
        type={type}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && onSubmit) {
            onSubmit()
          }
        }}
        placeholder={placeholder}
        className='default_input_input'
      />
    </div>
  )
}

export default Input;