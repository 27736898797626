import { makeAutoObservable } from "mobx";
import request from "../helpers/request";

class PricingStore {

  inApps = [];
  subscriptions = [];
  subscriptionDetails = [];
  filteredDetails = null;
  templatePrices = null;
  filteredSubs = null;

  constructor() {
    makeAutoObservable(this);
  }

  getInApps = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await request("pricing/fetch/inApps");
        this.inApps = resp.data;
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  getSubscriptions = (appId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await request(`pricing/fetch/subs/${appId}`);
        console.log('resp: ', resp);
        this.subscriptions = resp.subscriptions;
        resolve();
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  setSubsPoint = (item, price) => {
    let prices = this.subscriptionDetails.prices;
    let index = prices.findIndex(e => e.territory == item.territory)
    prices[index] = { ...prices[index], ...price };
    let details = this.subscriptionDetails;
    details.prices = prices;
    this.subscriptionDetails = details;
    console.log('this.subscriptionDetails: ', this.subscriptionDetails);
  }

  getSubscriptionDetails = (appId, groupId, subsId) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.searchSubsDetails(null);
        this.subscriptionDetails = null;
        const resp = await request("pricing/fetch/subs/detail", "POST", {
          groupId,
          appId,
          subsId,
        });
        this.subscriptionDetails = resp.subscription;
        resolve();
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  pull = (appId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await request(`pricing/pull/subs/${appId}`);
        console.log('resp: ', resp);
        this.subscriptions = resp.subscriptions;
        resolve();
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  getPricingPoints = (appId, groupId, subsId, territory) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.pricingPoints = null;
        const resp = await request("pricing/fetch/subs/pricingPoints", "POST", {
          groupId,
          appId,
          subsId,
          territory,
        });
        this.pricingPoints = resp.pricingPoints;
        resolve(resp.pricingPoints);
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  getTemplatePrices = (appId, subscriptionId, templateId, basePrice) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.templatePrices = null;
        const resp = await request(`pricing/fetch/subs/templates/pricingPoints/${appId}`, "POST", {
          subscriptionId,
          templateId,
          basePrice
        });
        this.setTemplatePrices(resp.points);
        resolve();
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  setTemplatePrices = (data) => {
    this.templatePrices = data;
  }

  saveDetails = (appId, subscriptionId, startDate, preserve) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('this.subscriptionDetails: ', this.subscriptionDetails);
        const resp = await request(`pricing/apply/subs/pricingPoints/${appId}`, "POST", {
          subscriptionId,
          points: this.subscriptionDetails.prices,
          preserve,
          startDate,
        });
        resolve();
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  saveTemplatePrices = (appId, subscriptionId, templateId, basePrice, preserve, date) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await request(`pricing/apply/subs/pricingPoints/${appId}`, "POST", {
          subscriptionId,
          templateId,
          basePrice,
          points: this.templatePrices,
          preserve,
          startDate: date,
        });
        this.setTemplatePrices(null);
        console.log('resp: ', resp);
        resolve();
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  searchSubsDetails = (text) => {
    if (!text) {
      this.filteredDetails = null;
      return
    }
    let prices = [...this.subscriptionDetails.prices]
    prices = prices.filter(item => item.country.toLowerCase().includes(text.toLowerCase()));
    let details = Object.assign({}, this.subscriptionDetails);
    details.prices = prices;
    this.filteredDetails = details;
  }

  searchReference = (text) => {
    if (!text) {
      this.filteredSubs = null;
      return
    }
    let subs = this.subscriptions;
    let a = subs.filter(item => {
      if (item.name.toLowerCase().includes(text.toLowerCase())) {
        return item;
      } else {
        if (item.subscriptions.some(subItem => subItem.name.toLowerCase().includes(text.toLowerCase()))) {
          return item;
        }
      }
    });
    this.filteredSubs = a;
  }

}

const pricingStore = new PricingStore();
export default pricingStore;