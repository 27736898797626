import "./index.css"

import arrow from '../../assets/bottom_arrow_white.webp';
import copy from '../../assets/copy.webp';
import pull from '../../assets/pull.webp';
import { createRef, useEffect, useRef, useState } from "react";
import VersionButton from "../VersionButton";
import Button from "../Button";
import CountryButton from "../CountryButton";
import { observer } from "mobx-react-lite"
import appStore from "../../stores/appStores";
import add_icon from "../../assets/add_icon.webp";
import tick from "../../assets/tick.webp";
import reply from "../../assets/reply.webp";
import question_mark from "../../assets/question_cirlce.webp";

import { useParams, Link, useNavigate } from 'react-router-dom';
import SmartEditorFilterButton from "../SmartEditorFilterButton";
import DropdownModal from "../DropdownModal";
import modalStore from "../../stores/modalStore";
import PasteFrom from "../PasteFrom";
import mixpanel from "../../helpers/mixpanel";
import RatingTopButton from "../RatingTopButton";
import ratingStore from "../../stores/ratingStore";
import { DATE_FILTER_ARRAY, SORT_ARRAY, STARS_ARRAY, WITH_RESPONSE_ARRAY } from "../../helpers/statics";
import RatingLanguageButton from "../RatingLanguageButton";
import staticStore from "../../stores/staticStore";
import templateStore from "../../stores/templateStore";
import pricingStore from "../../stores/pricingStore";
import CreateTempModal from "../CreateTempModal";
import inAppStore from "../../stores/inAppStore";

const Metadata = ({ color = "white" }) => {
  return (
    <svg width="20px" height="24px" viewBox="0 0 20 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Main-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Metadata-2" transform="translate(-40, -215)">
          <g id="Group-5" transform="translate(40, 213)">
            <g id="Group-12" transform="translate(0, 2.6)">
              <rect id="Rectangle" stroke={color} strokeWidth="1.4" x="0.7" y="4.1" width="18.6" height="14.6" rx="1.2"></rect>
              <g id="Group" fill={color}>
                <rect id="Rectangle" x="0" y="0" width="10" height="1.4" rx="0.7"></rect>
                <rect id="Rectangle-Copy" x="0" y="21.4" width="10" height="1.4" rx="0.7"></rect>
                <polygon id="Rectangle-Copy" transform="translate(5, 11.5) rotate(90) translate(-5, -11.5)" points="-6 10.8 16 10.8 16 12.2 -6 12.2"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

const SmartEditor = ({ color = "white" }) => {
  return (
    <svg width="39px" height="39px" viewBox="0 0 39 39" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Main-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Metadata-2" transform="translate(-201, -208)">
          <g id="Group" transform="translate(220.1276, 227.6919) rotate(45) translate(-220.1276, -227.6919)translate(205.2561, 215.6919)">
            <rect id="Rectangle" stroke={color} strokeWidth="1.4" x="13.5715076" y="0.7" width="3.6" height="22.6" rx="1.8"></rect>
            <path d="M12.8715076,11 L17.8715076,11 L17.8715076,21.5 C17.8715076,22.8807119 16.7522195,24 15.3715076,24 C13.9907957,24 12.8715076,22.8807119 12.8715076,21.5 L12.8715076,11 L12.8715076,11 Z" id="Rectangle" fill={color}></path>
            <path d="M22.9142711,15.6246796 L21.426964,16.3893446 C21.0823435,16.5665233 20.6593417,16.430785 20.482163,16.0861646 C20.3786448,15.884817 20.3786448,15.6458912 20.482163,15.4445436 L21.246828,13.9572365 C21.3943678,13.6702651 21.3943678,13.3297349 21.246828,13.0427635 L20.482163,11.5554564 C20.3049843,11.2108359 20.4407226,10.7878341 20.7853431,10.6106554 C20.9866906,10.5071372 21.2256164,10.5071372 21.426964,10.6106554 L22.9142711,11.3753204 C23.2012426,11.5228602 23.5417727,11.5228602 23.8287441,11.3753204 L25.3160513,10.6106554 C25.6606717,10.4334767 26.0836735,10.569215 26.2608522,10.9138354 C26.3643705,11.115183 26.3643705,11.3541088 26.2608522,11.5554564 L25.4961872,13.0427635 C25.3486474,13.3297349 25.3486474,13.6702651 25.4961872,13.9572365 L26.2608522,15.4445436 C26.438031,15.7891641 26.3022926,16.2121659 25.9576722,16.3893446 C25.7563247,16.4928628 25.5173988,16.4928628 25.3160513,16.3893446 L23.8287441,15.6246796 C23.5417727,15.4771398 23.2012426,15.4771398 22.9142711,15.6246796 Z" id="Star" fill={color} transform="translate(23.3715, 13.5) rotate(10) translate(-23.3715, -13.5)"></path>
            <path d="M7.91427113,9.93755165 L7.13407075,10.3386738 C6.91476683,10.4514239 6.64558387,10.365045 6.53283377,10.1457411 C6.46695854,10.0176108 6.46695854,9.86556711 6.53283377,9.73743687 L6.93395597,8.95723649 C7.08149577,8.67026506 7.08149577,8.32973494 6.93395597,8.04276351 L6.53283377,7.26256313 C6.42008367,7.04325922 6.50646261,6.77407625 6.72576653,6.66132615 C6.85389677,6.59545092 7.00594051,6.59545092 7.13407075,6.66132615 L7.91427113,7.06244835 C8.20124256,7.20998815 8.54177268,7.20998815 8.8287441,7.06244835 L9.60894449,6.66132615 C9.8282484,6.54857606 10.0974314,6.63495499 10.2101815,6.85425891 C10.2760567,6.98238915 10.2760567,7.13443289 10.2101815,7.26256313 L9.80905927,8.04276351 C9.66151947,8.32973494 9.66151947,8.67026506 9.80905927,8.95723649 L10.2101815,9.73743687 C10.3229316,9.95674078 10.2365526,10.2259237 10.0172487,10.3386738 C9.88911846,10.4045491 9.73707473,10.4045491 9.60894449,10.3386738 L8.8287441,9.93755165 C8.54177268,9.79001185 8.20124256,9.79001185 7.91427113,9.93755165 Z" id="Star" fill={color}></path>
            <path d="M5.91427113,17.6246796 L4.42696397,18.3893446 C4.08234353,18.5665233 3.65934173,18.430785 3.482163,18.0861646 C3.37864477,17.884817 3.37864477,17.6458912 3.482163,17.4445436 L4.24682799,15.9572365 C4.39436779,15.6702651 4.39436779,15.3297349 4.24682799,15.0427635 L3.482163,13.5554564 C3.30498428,13.2108359 3.44072261,12.7878341 3.78534305,12.6106554 C3.98669057,12.5071372 4.22561645,12.5071372 4.42696397,12.6106554 L5.91427113,13.3753204 C6.20124256,13.5228602 6.54177268,13.5228602 6.8287441,13.3753204 L8.31605127,12.6106554 C8.66067171,12.4334767 9.08367351,12.569215 9.26085223,12.9138354 C9.36437046,13.115183 9.36437046,13.3541088 9.26085223,13.5554564 L8.49618724,15.0427635 C8.34864744,15.3297349 8.34864744,15.6702651 8.49618724,15.9572365 L9.26085223,17.4445436 C9.43803096,17.7891641 9.30229263,18.2121659 8.95767219,18.3893446 C8.75632466,18.4928628 8.51739879,18.4928628 8.31605127,18.3893446 L6.8287441,17.6246796 C6.54177268,17.4771398 6.20124256,17.4771398 5.91427113,17.6246796 Z" id="Star" fill={color} transform="translate(6.3715, 15.5) rotate(-10) translate(-6.3715, -15.5)"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

const TabButton = ({ isSelected, icon, text, onClick }) => {
  const className = isSelected ? "tab_button tab_button_border cursor" : "tab_button cursor";
  const classNameText = isSelected ? "tab_button_text_selected" : "tab_button_text";
  return (
    <div onClick={() => {
      onClick();
    }} className={className}>
      {icon}
      <span className={classNameText}>
        {text}
      </span>
    </div>
  )
}

const PullButton = () => {

  const [isLoading, setLoading] = useState(false);

  return (
    <Button loading={isLoading}
      icon={pull}
      text="Pull" onClick={async () => {
        mixpanel.track("ASO_PULL_BUTTON_CLICKED")
        modalStore.setContentSure(
          "Pull",
          question_mark,
          "All data from App Store Connect will pull and overwritten. Are you sure?",
          "Yes, Overwrite Data",
          "Cancel",
          async () => {
            setLoading(true);
            mixpanel.track("ASO_PULL_BUTTON_SAVED")
            await appStore.pull();
            setLoading(false);
          }
        )

      }} style={{ backgroundColor: "rgba(255,255,255,0.1)", marginRight: "40px", paddingLeft: "12px", paddingRight: "12px" }} />
  )

}

const PushButton = observer(() => {

  const { isEdited } = appStore
  const [isLoading, setLoading] = useState(false);

  if (isEdited) {
    return <Button onClick={async () => {
      mixpanel.track("ASO_SAVE_BUTTON_CLICKED")
      modalStore.setContentSure(
        "Save",
        question_mark,
        "Your changes will be saved. Are you sure?",
        "Yes",
        "Cancel",
        async () => {
          await appStore.save()
          mixpanel.track("ASO_SAVE_BUTTON_SAVED")
        }
      )
    }} text="Save" style={{ backgroundColor: "white" }} textStyle={{ color: "black" }} />
  }

  return <Button loading={isLoading} text="Push" onClick={async () => {
    mixpanel.track("ASO_PUSH_BUTTON_CLICKED")
    modalStore.setContentSure(
      "Push",
      question_mark,
      "The metadata in your App Store Connect will be overwritten. Are you sure?",
      "Yes, Overwrite Data",
      "Cancel",
      async () => {
        setLoading(true);
        await appStore.push();
        mixpanel.track("ASO_PUSH_BUTTON_SAVED")
        setLoading(false);
      }
    )
  }} />
});

const TopRight = observer(({ type, tab, id }) => {
  const { countries } = staticStore;
  const [loading, setLoading] = useState(false);
  const { templates, rawTemplate } = templateStore;
  const { selectedVersion, history, stars, sort, terriority, responseFilter, dateFilter } = ratingStore;
  switch (type) {
    case "detail":
      return (
        <div className="other_buttons">
          <VersionButton />
          <PushButton />
          <PullButton />
        </div>
      )
    case "reviews":
      return (
        <div className="other_buttons" style={{ marginRight: 20 }} >
          <RatingTopButton
            data={() => {
              return countries.map((item, index) => {
                const isSelected = terriority?.name === item.name;
                return (
                  <div onClick={() => {
                    ratingStore.setTerriority(item);
                  }} key={"flags#" + index} className="all_apps_app_container cursor">
                    {isSelected ? <div style={{ marginLeft: 12, marginRight: 12, display: "flex", justifyContent: "center" }}><img style={{ width: 16, height: "auto", objectFit: "contain" }} src={tick} /></div> : <div style={{ width: 24 }} />}
                    <img src={item.flag} className="all_apps_app_icon" style={{ marginLeft: isSelected ? 0 : 15, borderRadius: 100, objectFit: "cover" }} />
                    <span className="all_apps_app_text">
                      {item.name}
                    </span>
                  </div>
                )
              })
            }}
            title={terriority ? terriority.name : countries[0]?.name} />
          <RatingTopButton title={sort?.title ? sort?.title : "Sort By"} data={() => {
            return SORT_ARRAY.map((item, index) => {
              return (
                <div onClick={async () => {
                  ratingStore.setSort(item);
                }} key={"selectedVersion#" + index} className="all_apps_app_container cursor">
                  {sort?.title === item?.title ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
                  <span className="all_apps_app_text">
                    {item.title}
                  </span>
                </div>
              )
            })
          }} />
          {/* <RatingTopButton data={() => {
            return (
              STARS_ARRAY.map((item, index) => {
                return (
                  <div onClick={async () => {
                    ratingStore.setStar(item)
                  }} key={"selectedVersion#" + index} className="all_apps_app_container cursor">
                    {stars.value === item.value ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
                    <span className="all_apps_app_text">
                      {item.title}
                    </span>
                  </div>
                )
              })
            )
          }} title={stars.title} /> */}
        </div>
      )
    case "pricing":
      return (
        <div className="other_buttons">
          {
            tab === 2 &&
            <>
              <RatingTopButton
                addButtonText={"Create Template"}
                addButtonAction={() => {
                  modalStore.setContent("Create Template", <CreateTempModal />, false, false);
                }}
                title={rawTemplate?.name ? rawTemplate?.name : "Template"} data={() => {
                  return templates.map((item, index) => {
                    return (
                      <div onClick={async () => {
                        templateStore.checkSave(() => {
                          templateStore.setTemplate(item);
                        })
                      }} key={"selectedVersion#" + index} className="all_apps_app_container cursor">
                        {rawTemplate?._id === item?._id ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
                        <span className="all_apps_app_text">
                          {item.name}
                        </span>
                      </div>
                    )
                  })
                }} />
              <Button onClick={() => {
                templateStore.saveEditTemplate();
              }} text="Save" style={{ marginLeft: -6, marginRight: 40 }} textStyle={{ color: "black" }} color="white" />
            </>
          }
          {
            tab === 1 &&
            <>
              <Button loading={loading} loadingColor="black" onClick={async () => {
                setLoading(true);
                await pricingStore.pull(id);
                setLoading(false);
              }} text="Pull" style={{ marginLeft: -6, marginRight: 40 }} textStyle={{ color: "black" }} color="white" />
            </>
          }
          {
            tab === 0 &&
            <>
              <Button loading={loading} loadingColor="black" onClick={async () => {
                setLoading(true);
                await inAppStore.pull(id);
                setLoading(false);
              }} text="Pull" style={{ marginLeft: -6, marginRight: 40 }} textStyle={{ color: "black" }} color="white" />
            </>
          }
        </div>
      )
  }
})

const BottomBar = observer(({ type, setTab, tab, id }) => {
  const [isSmart, setIsSmart] = useState(false);
  const { selectedVersion, history, stars, sort, terriority, responseFilter, dateFilter } = ratingStore;
  switch (type) {
    case "detail":
      return (
        <div className="app_detail_container_bottom">
          <TabButton onClick={() => {
            mixpanel.track("ASO_METADATA_TAB_CLICKED");
            setIsSmart(false)
            setTab(0);
          }} text={"Metadata"} icon={<Metadata color={!isSmart ? "white" : "#84848D"} />} isSelected={!isSmart} />
          <TabButton onClick={() => {
            mixpanel.track("ASO_SMART_EDITOR_TAB_CLICKED");
            setIsSmart(true)
            setTab(1);
          }} icon={<SmartEditor color={isSmart ? "white" : "#84848D"} />} text={"Smart Editor"} isSelected={isSmart} />
          <div className="app_detail_container_bottom_right">
            {!isSmart ?
              <>
                <div onClick={() => {
                  modalStore.setContent("Copy From", <PasteFrom isAll={true} />, true);
                  mixpanel.track("ASO_COPY_FROM_METADATA_CLICKED");
                }} className="paste_from_metadata cursor">
                  <img className="paste_icon_metadata" src={copy} />
                  <span className="paste_from_text_metadata">
                    Copy From
                  </span>
                </div>
                <CountryButton />
              </> :
              <SmartEditorFilterButton />
            }
          </div>
        </div>
      )
    case "reviews":
      return (
        <div className="app_detail_container_bottom">
          <TabButton onClick={() => {
            mixpanel.track("ASO_METADATA_TAB_CLICKED");
            setIsSmart(false)
            setTab(0);
          }} text={"Reply Reviews"} icon={<img src={reply} style={{ width: 20, height: 20 }} />} isSelected={true} />
          <div className="app_detail_container_bottom_right">
            <RatingLanguageButton title={dateFilter.title} data={() => {
              return (
                DATE_FILTER_ARRAY.map((item, index) => {
                  return (
                    <div onClick={async () => {
                      ratingStore.setDateFilter(item)
                    }} key={"selectedVersion#" + index} className="all_apps_app_container cursor">
                      {dateFilter.value === item.value ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
                      <span className="all_apps_app_text">
                        {item.title}
                      </span>
                    </div>
                  )
                })
              )
            }} />
            <RatingLanguageButton title={responseFilter.title} data={() => {
              return (
                WITH_RESPONSE_ARRAY.map((item, index) => {
                  return (
                    <div onClick={async () => {
                      ratingStore.setResponseFilter(item)
                    }} key={"selectedVersion#" + index} className="all_apps_app_container cursor">
                      {responseFilter.value === item.value ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
                      <span className="all_apps_app_text">
                        {item.title}
                      </span>
                    </div>
                  )
                })
              )
            }} />
            <RatingLanguageButton title={stars.title} data={() => {
              return (
                STARS_ARRAY.map((item, index) => {
                  return (
                    <div onClick={async () => {
                      ratingStore.setStar(item)
                    }} key={"selectedVersion#" + index} className="all_apps_app_container cursor">
                      {stars.value === item.value ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
                      <span className="all_apps_app_text">
                        {item.title}
                      </span>
                    </div>
                  )
                })
              )
            }} />
          </div>
        </div>
      )
    case "pricing":
      return (
        <div className="app_detail_container_bottom">
          <TabButton onClick={() => {
            // mixpanel.track("ASO_METADATA_TAB_CLICKED");
            setTab(0);
          }} text={"In Apps"} isSelected={tab === 0} />
          <TabButton onClick={() => {
            // mixpanel.track("ASO_METADATA_TAB_CLICKED");
            templateStore.checkSave(() => {
              setTab(1);
            })
          }} text={"Subscription"} isSelected={tab === 1} />
          <TabButton onClick={async () => {
            // mixpanel.track("ASO_METADATA_TAB_CLICKED");
            setTab(2);
          }} text={"Templates"} isSelected={tab === 2} />
          <div className="app_detail_container_bottom_right">
          </div>
        </div>
      )
  }
})

const AppDetailHeader = observer(({ setTab, tab, type }) => {

  const arrowRef = useRef()
  const divRef = useRef()
  const { id } = useParams();

  const navigate = useNavigate();


  const [show, setShow] = useState(false);

  const { selectedApp, apps } = appStore

  return (
    <div className='app_detail_header_container'>
      <div className="app_detail_header_container_top">
        <div ref={divRef} onClick={() => {
          setShow(!show);
          if (!show) {
            mixpanel.track("ASO_APP_HEADER_APP_DROPDOWN_CLICK");
          }
        }} className="app_name_container">
          <img src={selectedApp.icon} className="app_icon" />
          <span className="app_name">
            {selectedApp.name}
          </span>
          <img ref={arrowRef} src={arrow} style={{ width: 20, height: 20, objectFit: "contain", marginTop: 4, marginLeft: 8 }} />
          {
            show ? <DropdownModal
              data={() => {
                return apps.map((item, index) => {
                  return (
                    <Link onClick={() => {
                      setShow(false);
                      mixpanel.track("ASO_APP_HEADER_APP_DROPDOWN_SELECT");
                    }} to={`/${type}/` + item._id} key={"selectedApp#" + index} className="all_apps_app_container">
                      {item._id === id ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
                      <img src={item.icon} className="all_apps_app_icon" />
                      <span className="all_apps_app_text">
                        {item.name}
                      </span>
                    </Link>
                  )
                })
              }}
              addButtonText={"Add New App"}
              addButtonAction={() => {
                mixpanel.track("ASO_APP_HEADER_APP_DROPDOWN_ADD_APP_CLICK");
                navigate("/settings?tab=apps")
              }}
              onClose={() => {
                setShow(false)
              }} divRef={divRef} right={-150} top={50} /> : null
          }
        </div>
        <TopRight id={id} tab={tab} type={type} />
      </div>
      <BottomBar id={id} type={type} tab={tab} setTab={setTab} />
    </div>
  );
});

export default AppDetailHeader;
