import React, { useEffect } from 'react'
import SmartEditorRow from '../../../components/SmartEditorRow'
import { observer } from "mobx-react-lite"
import appStore from '../../../stores/appStores'
import "./index.css"
import SmartEditorHeader from '../../../components/SmartEditorHeader'
import smartEditorStore from '../../../stores/smartEditorStore'
import staticStore from '../../../stores/staticStore'
import selectionStore from '../../../stores/selectionStore'
import modalStore from '../../../stores/modalStore'
import mixpanel from '../../../helpers/mixpanel'
// import SmartEditorHeader from '../../../stores/smartEditorStore';

function SmartEditor() {
  const { editableVersion, searchedLocales } = appStore;
  const { selectedFilter } = smartEditorStore;
  const { flags } = staticStore;

  useEffect(() => {
    mixpanel.track("ASO_APP_DETAIL_SMART_EDITOR_VIEW");
    const keys = Object.keys(editableVersion?.metadata);

    const newObject = keys.reduce((result, key) => {
      result[key] = { isSelected: false }
      return result;
    }, {});
    selectionStore.init(newObject);

    return () => {
      selectionStore.clear();
    }
  }, [editableVersion])

  let locales = searchedLocales ? searchedLocales.slice() : Object.keys(editableVersion?.metadata)

  return (
    <div className='smart-editor-container' >
      <SmartEditorHeader />
      <div className='smart-editor-rows-container' >
        {
          locales.sort((a, b) => {
            const _a = flags[a];
            const _b = flags[b]
            if (_a.name[0] < _b.name[0]) {
              return -1;
            }
            if (_a.name[0] > _b.name[0]) {
              return 1;
            }
            return 0
          })?.map((item, index) => {
            return <SmartEditorRow key={"SmartEditorRow#" + index} multiline={selectedFilter.multiline} type={selectedFilter.field} country={item} />
          })
        }
      </div>
    </div>
  )
}

export default observer(SmartEditor);