import { makeAutoObservable } from "mobx";
import request from "../helpers/request";
import { Store } from "react-notifications-component";
import staticStore from "./staticStore";
import modalStore from "./modalStore";
import question_mark from "../assets/question.webp";

class TemplateStore {

  template = null;
  edited = false;
  editedTemplate = [];
  rawTemplate = {};
  templates = [];
  managedCountries = [];
  filteredCountries = null;
  newTempName = null;

  constructor() {
    makeAutoObservable(this);
  }

  checkSave = (cb) => {
    if (this.edited) {
      modalStore.setContentSure(
        "Unsaved Changes",
        question_mark,
        "You have unsaved changes. Are you sure you want to discard them?",
        "Discard Changes",
        "Cancel",
        () => {
          cb();
          this.edited = false;
        })
    } else {
      this.edited = false;
      cb()
    }
  }

  setTemplate = (template) => {
    console.log('template: ', template);
    this.rawTemplate = template;
    let _template = [];
    for (let index = 0; index < template.boards.length; index++) {
      const element = template.boards[index];
      let cards = element.countries.map(e => {
        return { ...e, id: `${e.territory}##${e.country}` }
      })
      _template.push({
        ...element,
        id: element._id,
        cards,
      })
    }
    this.editedTemplate = _template;
    this.template = _template;
  }

  setEditedTemplate = (template) => {
    this.edited = true;
    this.editedTemplate = template;
  }

  createTemplate = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.newTempName) {
          return resolve(false);
        }
        const resp = await request("pricing/create/template", "POST", {
          name: this.newTempName,
        })
        await this.getTemplates();
        resolve(true);
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  organizeTemplateToDB = () => {
    let template = [];
    let raw = this.rawTemplate;
    for (let index = 0; index < this.editedTemplate.length; index++) {
      const element = this.editedTemplate[index];
      if (element.id === "add_column") {
        continue;
      }
      let countries = element.cards.map(e => {
        return { country: e.country, flag: e.flag, territory: e.territory }
      })
      template.push({
        _id: element._id,
        discount: element.discount,
        name: element.name,
        isDefault: element.isDefault,
        countries,
      });
    }
    raw.boards = template;
    return raw;
  }

  setNewTempName = (name) => {
    this.newTempName = name;
  }

  saveEditTemplate = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const temp = this.organizeTemplateToDB();
        const resp = await request(`pricing/edit/template`, "POST", {
          template: temp,
        })
        const saved = await request(`pricing/fetch/templates`)
        this.templates = saved.templates;
        if (resp.done) {
          this.edited = false;
          Store.addNotification({
            title: "Saved!",
            message: "The changes were successfully saved.",
            container: "top-right",
            insert: "top",
            type: "success",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          })
        }
        resolve(true);
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  getTemplates = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await request(`pricing/fetch/templates`)
        this.templates = resp.templates;
        if (resp.templates[0]) {
          this.setTemplate(resp.templates[0]);
        }
        resolve(true);
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  renameColumn = (name, id) => {
    this.edited = true;
    let findIndex = this.editedTemplate.findIndex(e => e.id === id);
    let editTemp = this.editedTemplate;
    editTemp[findIndex].name = name;
    this.editedTemplate = editTemp;
  }

  editDiscount = (discount, id) => {
    this.edited = true;
    let findIndex = this.editedTemplate.findIndex(e => e.id === id);
    let editTemp = this.editedTemplate;
    editTemp[findIndex].discount = discount;
    this.editedTemplate = editTemp;
  }

  initManagedCountries = (id) => {
    let findIndex = this.editedTemplate.findIndex(e => e.id === id);
    let editTemp = this.editedTemplate;
    let countries = editTemp[findIndex].cards;
    const { appStoreCountries } = staticStore;
    const appStore = appStoreCountries.map(item => ({
      ...item,
      selected: countries.some(subItem => subItem.country === item.country)
    }));
    this.managedCountries = appStore;
  }

  selectManagedCountry = (country) => {
    let temp = this.managedCountries;
    temp.forEach(item => {
      if (item.country === country) {
        item.selected = !item.selected; // Toggle the 'selected' property
      }
    });
    this.managedCountries = temp;
  }

  searchManagedCountries = (text) => {
    if (!text) {
      this.filteredCountries = null;
      return
    }
    let temp = this.managedCountries.filter(item => item.country.toLowerCase().includes(text.toLowerCase()));
    this.filteredCountries = temp;
  }

  applyManaged = (id) => {
    this.edited = true;
    let _template = [];
    let selected = this.managedCountries.filter(item => item.selected);
    let unselected = this.managedCountries.filter(item => !item.selected);
    let toDefault = [];
    for (let index = 0; index < this.editedTemplate.length; index++) {
      const element = this.editedTemplate[index];
      let cards = [];
      if (element.id === id) {
        cards = selected.map(e => {
          return {
            country: e.country,
            flag: e.flag,
            territory: e.territory,
            id: `${e.territory}##${e.country}`
          }
        })
        toDefault = unselected.filter(e => {
          let inc = element.cards.some(c => e.country === c.country)
          if (inc) {
            return {
              country: e.country,
              flag: e.flag,
              territory: e.territory,
              id: `${e.territory}##${e.country}`
            }
          }
        })
      } else {
        cards = unselected.filter(e => {
          let inc = element.cards.some(c => e.country === c.country)
          if (inc) {
            return {
              country: e.country,
              flag: e.flag,
              territory: e.territory,
              id: `${e.territory}##${e.country}`
            }
          }
        })
      }
      _template.push({
        ...element,
        id: element._id,
        cards,
      })
    }
    let findDef = this.editedTemplate.findIndex(e => e.isDefault);
    _template[findDef].cards = _template[findDef].cards.concat(toDefault);
    this.editedTemplate = _template;
    this.template = _template;
  }

  addNewColumn = (name, discount) => {
    let id = new Date().getTime()
    this.template.push({
      id,
      name,
      discount,
      cards: [],
    })
    this.editedTemplate.push({
      id,
      name,
      discount,
      cards: [],
    })
  }

  deleteColumn = (id) => {
    let findIndex = this.editedTemplate.findIndex(e => e.id === id);
    let editTemp = [...this.editedTemplate];
    let cards = editTemp[findIndex].cards;
    editTemp.splice(findIndex, 1);
    let findDef = this.editedTemplate.findIndex(e => e.isDefault);
    let temp = editTemp[findDef].cards.concat(cards);
    editTemp[findDef].cards = temp;
    this.editedTemplate = editTemp;
    this.template = editTemp;
  }

}

const templateStore = new TemplateStore();
export default templateStore;