import { makeAutoObservable } from "mobx";
import request from "../helpers/request";

class InAppStore {

  inApps = [];
  inAppDetails = null;
  filteredDetails = null;
  filterInapps = null;
  templatePrices = null;

  constructor() {
    makeAutoObservable(this);
  }

  getInApps = (appId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await request(`pricing/fetch/inapps/${appId}`);
        this.inApps = resp.inapps;
        resolve();
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  getInAppDetails = (appId, inAppId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await request(`pricing/fetch/inapps/detail`, "POST", {
          inAppId,
          appId,
        });
        this.inAppDetails = resp.inapp;
        resolve();
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  setTemplatePrices = (data) => {
    this.templatePrices = data;
  }

  getTemplatePrices = (appId, inAppId, templateId, basePrice) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.templatePrices = null;
        const resp = await request(`pricing/fetch/inapps/templates/pricingPoints/${appId}`, "POST", {
          inAppId,
          templateId,
          basePrice
        });
        this.setTemplatePrices(resp.points);
        resolve();
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  getPricingPoints = (appId, inAppId, territory) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.pricingPoints = null;
        const resp = await request("pricing/fetch/inapps/pricingPoints", "POST", {
          appId,
          inAppId,
          territory,
        });
        this.pricingPoints = resp.pricingPoints;
        resolve(resp.pricingPoints);
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  pull = (appId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await request(`pricing/pull/inapps/${appId}`);
        this.inApps = resp.inapps;
        resolve();
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  setSubsPoint = (item, price) => {
    let prices = this.inAppDetails.prices;
    let index = prices.findIndex(e => e.territory == item.territory)
    prices[index] = { ...prices[index], ...price };
    let details = this.inAppDetails;
    details.prices = prices;
    this.inAppDetails = details;
  }

  saveTemplatePrices = (appId, inAppId, templateId, basePrice) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await request(`pricing/apply/inapps/pricingPoints/${appId}`, "POST", {
          inAppId,
          templateId,
          basePrice,
          points: this.templatePrices,
        });
        this.setTemplatePrices(null);
        resolve();
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  saveDetails = (appId, inAppId, basePrice) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await request(`pricing/apply/inapps/pricingPoints/${appId}`, "POST", {
          inAppId,
          points: this.inAppDetails.prices,
          basePrice,
        });
        resolve();
      } catch (error) {
        console.log('error: ', error);
        reject(error);
      }
    })
  }

  searchInappDetails = (text) => {
    if (!text) {
      this.filteredDetails = null;
      return
    }
    let prices = [...this.inAppDetails.prices]
    prices = prices.filter(item => item.country.toLowerCase().includes(text.toLowerCase()));
    let details = Object.assign({}, this.inAppDetails);
    details.prices = prices;
    this.filteredDetails = details;
  }

  searchReference = (text) => {
    if (!text) {
      this.filterInapps = null;
      return
    }
    console.log('text: ', text);
    let subs = this.inApps;
    let a = subs.filter(item => {
      if (item.name.toLowerCase().includes(text.toLowerCase())) {
        return item;
      }
    });
    console.log('a: ', a);
    this.filterInapps = a;
  }

}

const inAppStore = new InAppStore();
export default inAppStore;