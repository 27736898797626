import { makeAutoObservable } from "mobx"
import request from "../helpers/request";
import world_icon from "../assets/worldicons.webp"

class StaticStore {

  flags = null
  smartEditor = null
  plans = null;
  countries = null;
  language = null;
  rawCountries = null;
  google = null;
  appStoreCountries = null;
  defaultPlan = null;


  constructor() {
    makeAutoObservable(this)
    this.getStatics();
    this.language = localStorage.getItem("language");
  }

  async getStatics() {
    await request("auth/statics").then((resp) => {
      this.defaultPlan = resp.defaultPlan;
      this.setAppStoreCountries(resp.appStoreCountries);
      this.setSmartEditor(resp.smartEditor);
      this.setFlags(resp.flags)
      this.setPlans(resp.plans)
      this.setCountries(resp.countries)
      this.setGoogle(resp.google);
    })
  };

  setPlans(plans) {
    this.plans = plans;
  }

  setAppStoreCountries = (countries) => {
    this.appStoreCountries = countries;
  }

  setSmartEditor(smartEditor) {
    this.smartEditor = smartEditor;
  }

  setFlags(flags) {
    this.flags = flags;
  }

  setCountries(countries) {
    this.countries = countries;
    this.countries.unshift({ name: "All Countries", flag: world_icon });
    this.rawCountries = countries;
  }

  setGoogle = (google) => {
    this.google = google;
  }
}

const staticStore = new StaticStore();
export default staticStore;