import '@asseinfo/react-kanban/dist/styles.css'
import "./index.css"
import React, { useState } from 'react'
import TemplateCard from '../../../components/TemplateCard'
import Board from '@asseinfo/react-kanban'
import AddColumnIcon from "../../../assets/add-column.webp";
import templateStore from '../../../stores/templateStore'
import { observer } from 'mobx-react-lite'
import DeleteIcon from "../../../assets/delete.webp"
import EditTempIcon from "../../../assets/edit-template.webp"
import modalStore from '../../../stores/modalStore'
import TemplateManageModal from '../../../components/TemplateManageModal'
import question_mark from "../../../assets/question_cirlce.webp";
import drag_lines from "../../../assets/drag-lines.webp";
import EmptyTemplateImage from "../../../assets/template_empty.webp";
import EmptyAddIcon from "../../../assets/add-white.webp";
import CreateTempModal from '../../../components/CreateTempModal'

const AddBoard = () => {
  const [clicked, setClicked] = useState(false);
  const [name, setName] = useState("");
  if (clicked) {
    return (
      <div className='template-card-header-top' >
        <div
          className='template-card-header' >
          <div style={{ flex: 1, display: "flex" }} />
          <input
            value={name}
            placeholder='Board Name'
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                templateStore.addNewColumn(name, 10);
              }
            }
            }
            autoFocus
            onChange={(e) => {
              setName(e.target.value)
            }}
            className='template-card-header-name-input'
          />
          <div style={{ flex: 1, display: "flex" }} />
        </div>
      </div>
    )
  }
  return (
    <div onClick={() => {
      setClicked(true);
    }} className="template-add-board" >
      <img src={AddColumnIcon} className="template-add-board-icon" />
      Add New Board
    </div>
  )
}

const DiscountRow = ({ discount, id, isDefault }) => {
  const [_dis, setDiscount] = useState(discount);
  return (
    <div className='template-card-discount-container' >
      <span className='template-card-discount-title' >
        Discount Rate
      </span>
      <span className='template-card-discount-rate' >
        {
          isDefault ? <span style={{ height: 24 }} >
            {discount}
          </span> : <input
            value={_dis}
            type="number"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                templateStore.editDiscount(e.target.value, id);
              }
            }
            }
            onChange={(e) => {
              templateStore.editDiscount(e.target.value, id);
              setDiscount(e.target.value)
            }}
            className='template-card-header-discount-input'
          />
        }

        %
      </span>
    </div>
  )
}

const CountryRow = ({ id, isDefault, name, length }) => {
  return (
    <div className='template-card-header-countries-row' >
      <span className='template-card-header-countries' >
        Countries {`(${length})`}
      </span>
      {!isDefault && <span
        onClick={() => {
          templateStore.initManagedCountries(id);
          modalStore.setContent(`Manage "${name}" Board`, <TemplateManageModal id={id} />, true, "scroll")
        }}
        className='template-card-header-manage' >
        Manage
      </span>}
    </div>
  )
}

const ColumnHeader = ({ name, discount, id, isDefault, cards }) => {
  const [editMode, setEditMode] = useState(false);
  const [hover, setHover] = useState(false);
  const [title, setName] = useState(name);
  const { editedTemplate } = templateStore;
  let find = editedTemplate.find(e => e.id === id);
  if (id === "add_column") {
    return <AddBoard />
  }
  return (
    <div className='template-card-header-top' >
      <div
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        className='template-card-header' >
        <div style={{ flex: 1, display: "flex" }} />
        {editMode ?
          <input
            value={title}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setEditMode(false);
                templateStore.renameColumn(title, id);
              }
            }
            }
            autoFocus
            onChange={(e) => {
              templateStore.renameColumn(e.target.value, id);
              setName(e.target.value)
            }}
            className='template-card-header-name-input'
          />
          :
          <span style={{ padding: 10 }} >
            {title}
          </span>
        }
        <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }} >
          {hover && !isDefault &&
            <>
              <span
                onClick={() => {
                  setEditMode(true);
                }}
                className='template-card-header-icon-container' >
                <img className='template-card-header-icon' src={EditTempIcon} />
              </span>
              <span
                onClick={() => {
                  modalStore.setContentSure("Delete Column",
                    question_mark,
                    "The board will be deleted and the countries on the board will be transferred to the default board.",
                    "Delete",
                    "Cancel",
                    () => {
                      templateStore.deleteColumn(id);
                      console.log('id: ', id);
                    })
                }}
                className='template-card-header-icon-container' >
                <img className='template-card-header-icon' src={DeleteIcon} />
              </span>
            </>
          }
        </div>
      </div>
      <DiscountRow isDefault={isDefault} discount={discount} id={id} />
      <CountryRow length={cards.length} name={name} isDefault={isDefault} id={id} />
      {
        find?.cards?.length <= 0 &&
        <div className='template-card-header-empty' >
          <div className='template-card-header-empty-text' >
            You can drag & drop or select <br /> from the list to add countries
          </div>
          <span onClick={() => {
            templateStore.initManagedCountries(id);
            modalStore.setContent(`Manage "${name}" Board`, <TemplateManageModal id={id} />, true, "scroll")
          }} className='template-card-header-empty-manage' >
            Manage
          </span>
        </div>
      }
    </div>
  )
}

const EmptyScreen = () => {
  return (
    <div className='template-page-empty-container' >
      <img src={EmptyTemplateImage} />
      <p>
        There are boards within the templates. You will be able to set a discount percentage on the boards and move any country you want to that board.
      </p>
      <div onClick={() => {
        modalStore.setContent("Create Template", <CreateTempModal />, false, false);
      }} style={{ display: "flex", marginTop: 20, flexDirection: "column", alignItems: "center", justifyContent: "center" }} >
        <img src={EmptyAddIcon} className='template-page-empty-button' />
        <span className='template-page-empty-button-text' >
          Create Your <br /> First Template
        </span>
      </div>
    </div>
  )
}

const BoardComponent = observer(() => {
  const { template, rawTemplate } = templateStore;

  return (
    <div className='template-page-container' >
      <span className='template-page-name' >
        {rawTemplate.name}
      </span>
      <Board
        dragging
        onCardDragEnd={(e) => {
          templateStore.setEditedTemplate(e.columns);
        }}
        allowAddColumn
        key={rawTemplate._id + template.length + new Date().getTime()}
        initialBoard={{
          columns: [...template, {
            id: "add_column",
            cards: [],
          }]
        }}
        renderColumnHeader={({ name, discount, id, isDefault, cards }) => <ColumnHeader cards={cards} isDefault={isDefault} id={id} name={name} discount={discount} />}
        renderCard={({ country, flag, discount }, { removeCard, dragging }) => (
          <div key={country} className='react-kanban-card'>
            <span style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
              <img className="template_item_bottom_card_flag" src={flag} />
              <span>
                {country}
              </span>
            </span>
            <img className='template-kanban-card-drag' src={drag_lines} />
          </div>
        )}
      >
      </Board>
    </div>
  )
})

function Templates() {
  const { templates } = templateStore;
  if (templates.length <= 0) {
    return <EmptyScreen />
  }
  return <BoardComponent />
}

export default observer(Templates);