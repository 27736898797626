
export const MAX_LENGTH = {
  "name": 30,
  "subtitle": 30,
  "keywords": 100,
  "description": 4000,
  "promotionalText": 170,
  "whatsNew": 4000,
  "marketingUrl": -1,
  "supportUrl": -1,
  "privacyPolicyUrl": -1,
  "privacyChoicesUrl": -1,
  "privacyPolicyText": -1,
}

export const validateEmail = (email) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export const STARS_ARRAY = [
  {
    title: "All Ratings",
    value: null,
    key: "0_stars",
  },
  {
    title: "5 Stars",
    value: 5,
    key: "5_stars",
  },
  {
    title: "4 Stars",
    value: 4,
    key: "4_stars",
  },
  {
    title: "3 Stars",
    value: 3,
    key: "3_stars",
  },
  {
    title: "2 Stars",
    value: 2,
    key: "2_stars",
  },
  {
    title: "1 Stars",
    value: 1,
    key: "1_stars",
  },
]

export const SORT_ARRAY = [
  {
    title: "Most Recent",
    value: { key: "createdDate", value: -1 },
  },
  {
    title: "Oldest",
    value: { key: "createdDate", value: 1 },
  },
  {
    title: "Highest Rating",
    value: { key: "rating", value: -1 },
  },
  {
    title: "Lowest Rating",
    value: { key: "rating", value: 1 },
  },
]

export const WITH_RESPONSE_ARRAY = [
  {
    title: "All Reviews",
    value: null
  },
  {
    title: "Answered Reviews",
    value: true
  },
  {
    title: "Unanswered Reviews",
    value: false
  },
]

export const SUB_STATES = {
  "MISSING_METADATA": {
    label: "Missing Metadata",
  },
  "APPROVED": {
    label: "Approved",
  },
  "WAITING_FOR_REVIEW": {
    label: "Waiting for Review",
  },
  "PREPARE_FOR_SUBMISSION": {
    label: "Prepare for Submission",
  }
}

export const SUB_PERIODS = {
  "ONE_MONTH": {
    label: "1 month"
  },
  "ONE_YEAR": {
    label: "1 year"
  },
  "THREE_MONTHS": {
    label: "3 months"
  },
  "SIX_MONTHS": {
    label: "6 months"
  },
  "ONE_WEEK": {
    label: "1 week"
  },
}

let now = new Date();
let today = new Date().setDate(now.getDate() - 1);
let lastSeven = new Date().setDate(now.getDate() - 7);
let lastThirty = new Date().setDate(now.getDate() - 30);
let lastNinety = new Date().setDate(now.getDate() - 90);
let lastYear = new Date().setDate(now.getDate() - 365);
let allTime = new Date(-8640000000000000);

export const DATE_FILTER_ARRAY = [
  {
    title: "All Time",
    value: allTime,
  },
  {
    title: "Today",
    value: today,
  },
  {
    title: "Last 7 Days",
    value: lastSeven,
  },
  {
    title: "Last 30 Days",
    value: lastThirty,
  },
  {
    title: "Last 90 Days",
    value: lastNinety,
  },
  {
    title: "Last One Year",
    value: lastYear,
  },
]