import "./index.css";

import arrow from "../../assets/bottom_arrow_white.webp";
import add_icon from "../../assets/add_icon.webp";
import tick from "../../assets/tick.webp";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite"
import appStore from "../../stores/appStores";
import request from "../../helpers/request";
import DropdownModal from "../DropdownModal";
import modalStore from "../../stores/modalStore";
import CreateNewVersion from "../CreateNewVersion";
import mixpanel from "../../helpers/mixpanel";

// const AllVersions = observer(({ left, top, divRef, onClose, isOpen }) => {

//   const _self = useRef()

//   const { editableVersion, history } = appStore

//   const eventAction = (e) => {
//     if ((divRef.current?.contains(e.target) && true) || _self.current?.contains(e.target)) {
//       return;
//     }
//     const k = divRef.current?.contains(e.target);
//     onClose();
//   }


//   useEffect(() => {
//     document.addEventListener('click', eventAction);
//     return () => {
//       document.removeEventListener('click', eventAction);
//     }
//   }, [])

//   return (
//     <div ref={_self} className="all_apps_container" style={{ left: left - 100, top: top + 50 }}>
//       <div className="all_apps_add_new_app cursor" >
//         <img src={add_icon} className="all_apps_add_new_app_icon" />
//         <span className="all_apps_add_new_app_text">
//           Create New Version
//         </span>
//       </div>
//       {
//         history.map((item, index) => {
//           console.log("🚀 ~ file: index.js:46 ~ history.map ~ item:", item)
//           let text, color;
//           if (item.appStoreState === "READY_FOR_SALE") {
//             color = "#4ABF7E"
//             text = "Released";
//           } else if (item.appStoreState === "PREPARE_FOR_SUBMISSION") {
//             color = "#FFD43A"
//             text = "Prepare For Submission";
//           }
//           return (
//             <div onClick={async () => {
//               const { version } = await request("app/get/app/version", "post", { versionId: item._id });
//               appStore.setEditableVersion(version);
//               onClose();
//             }} key={"selectedVersion#" + index} className="all_apps_app_container cursor">
//               {editableVersion.versionString === item.versionString ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
//               <span className="all_apps_app_text">
//                 {item.versionString}
//               </span>
//               <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", flexDirection: "row", alignItems: "center", marginRight: 15 }}>
//                 <div className="dot" style={{ backgroundColor: color }} />
//                 <span style={{ color, fontSize: 12 }}>
//                   {text}
//                 </span>
//               </div>
//             </div>
//           )
//         })
//       }
//     </div>
//   )
// });

const VersionButton = observer(() => {

  const { editableVersion, history, current } = appStore;

  let color, text;

  if (editableVersion?.appStoreState === "READY_FOR_SALE") {
    color = "#4ABF7E"
    text = "Live";
  } else if (!editableVersion?.editable) {
    color = "#FFD43A";
    text = "Old Version";
  } else {
    color = "#FFD43A";
    if (editableVersion?.pushed) {
      text = "Synced";
    } else {
      text = "Local Draft";
    }
  }

  const divRef = useRef()

  const [show, setShow] = useState(false);

  return (
    <>
      <div onClick={() => {
        setShow(!show);
        if (!show) {
          mixpanel.track("ASO_VERSION_BUTTON_CLICK");
        }
      }} ref={divRef} className="version_button">
        <span style={{ color: "white", fontWeight: 500 }}>
          Version {editableVersion?.versionString}
        </span>
        <div className="dot" style={{ backgroundColor: color }} />
        <span style={{ fontWeight: 500, fontSize: 14, marginTop: 1, color }}>
          {text}
        </span>
        <img src={arrow} className="arrow" />
        {
          show ? <DropdownModal
            data={() => {
              return history.map((item, index) => {
                let text, color;
                if (item.appStoreState === "READY_FOR_SALE") {
                  color = "#4ABF7E"
                  text = "Released";
                } else if (item.appStoreState === "PREPARE_FOR_SUBMISSION") {
                  color = "#FFD43A"
                  text = "Prepare For Submission";
                }
                return (
                  <div onClick={async () => {
                    const { locale } = appStore;
                    const { version } = await request("app/get/app/version", "post", { versionId: item._id });
                    if (!version.metadata[locale]) {
                      appStore.setLocale(Object.keys(version.metadata)[0]);
                    }
                    appStore.setEditableVersion(version);
                    mixpanel.track("ASO_VERSION_BUTTON_SELECT");
                    setShow(false);
                  }} key={"selectedVersion#" + index} className="all_apps_app_container cursor">
                    {editableVersion?.versionString === item.versionString ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
                    <span className="all_apps_app_text">
                      {item.versionString}
                    </span>
                    <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", flexDirection: "row", alignItems: "center", marginRight: 15 }}>
                      <div className="dot" style={{ backgroundColor: color }} />
                      <span style={{ color, fontSize: 12 }}>
                        {text}
                      </span>
                    </div>
                  </div>
                )
              })
            }}
            addButtonText={"Create New Version"}
            addButtonDisabled={current.editable}
            addButtonAction={() => {
              mixpanel.track("ASO_VERSION_BUTTON_NEW_VERSION_CLICKED");
              modalStore.setContent("Create New Version", <CreateNewVersion />, false);
            }}
            onClose={() => {
              setShow(false);
            }} divRef={divRef} right={0} top={50} /> : null
        }
      </div>
    </>
  )
});

export default VersionButton;