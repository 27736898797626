import React, { useCallback, useState } from 'react'
import "./index.css"
import { useDropzone } from 'react-dropzone'
import dropzone_icon from "../../assets/dropzone-icon.webp";
import tick_rounded from "../../assets/tick-rounded.webp";
import { Store } from 'react-notifications-component';
import question from "../../assets/question.webp";
import mixpanel from '../../helpers/mixpanel';

function CustomDropzone({ setValue, value = null, info }) {
  const [val, setVal] = useState(value);
  const [showInfo, setShowInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const onDrop = useCallback(acceptedFiles => {
    setLoading(true);
    acceptedFiles.forEach((file) => {
      console.log('file: ', file);
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result
        const resp = {
          name: file.name,
          value: binaryStr,
          path: file.path,
        }
        setVal(resp);
        setValue(resp);
      }
      reader.readAsText(file);
    })
    setLoading(false);
    // Do something with the files
  }, [])
  const { getRootProps, getInputProps, fileRejections, isDragActive } = useDropzone({ onDrop, maxFiles: 1, accept: { "application/p8": [".p8"], } })

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    console.log('fileRejections: ', fileRejections);
    console.log('errors: ', errors);
    console.log('file: ', file);
    Store.addNotification({
      title: "Warning!",
      message: `${errors[0].code === "file-invalid-type" ? "File type must be '.p8'." : "Something went wrong"}`,
      container: "top-right",
      insert: "top",
      type: "warning",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    })
  });

  return (
    <>
      <div className='dropzone-container' style={{ borderColor: isDragActive ? "#5a5a67" : "#313141" }} {...getRootProps()}>
        <input {...getInputProps()} />
        <img className='dropzone-icon' src={val ? tick_rounded : dropzone_icon} />
        {
          loading ? <p className='dropzone-text' >Uploading...</p> : val ? <p className='dropzone-text' >{val.name}</p> : <p className='dropzone-text' >Drop your Key File here, or Browse</p>
        }
        {info && <div
          onMouseLeave={() => {
            setShowInfo(false);
          }}
          onMouseEnter={() => {
            mixpanel.track(`ASO_CONNECT_DEVELOPER_HOVER_INFO_FILE`)
            setShowInfo(true);
          }} style={{ top: 12, zIndex: 100 }} className='custom-input-question-container cursor' >
          {showInfo && info}
          <img className='custom-input-question-image' src={question} />
        </div>}
      </div>
    </>
  )
}

export default CustomDropzone;