import "./index.css"

import logo from '../../assets/logo.webp';
import arrow from '../../assets/bottom_arrow_white.webp';
import { observer } from "mobx-react-lite"
import appStore from "../../stores/appStores";
import { useEffect, useRef, useState } from "react";
import add_icon from "../../assets/add_icon.webp";
import tick from "../../assets/tick.webp";
import request from "../../helpers/request";
import { Link, useNavigate } from "react-router-dom";
import DropdownModal from "../DropdownModal";
import { useParams } from 'react-router-dom';
import accountStore from "../../stores/accountStore";
import help from "../../assets/question_cirlce.webp"
import exit from "../../assets/exit.webp"
import timer from "../../assets/timer.webp"
import profile_circle from "../../assets/profile_circle.webp"
import settings_mini from "../../assets/settings_mini.webp"
import mixpanel from "../../helpers/mixpanel";
import ratingStore from "../../stores/ratingStore";

const SecretList = observer(({ right, top, divRef, onClose, showConnection }) => {

  const _self = useRef()
  const navigate = useNavigate();
  const { secrets, selectedSecret } = appStore
  const { account } = accountStore;

  const eventAction = (e) => {
    if ((divRef.current?.contains(e.target) && true) || _self.current?.contains(e.target)) {
      return;
    }
    const k = divRef.current?.contains(e.target);
    onClose();
  }


  useEffect(() => {
    document.addEventListener('click', eventAction);
    return () => {
      document.removeEventListener('click', eventAction);
    }
  }, [])

  return (
    <div ref={_self} className="account_modal" style={{ right, top: top, height: null }}>
      <div className="all_apps_add_new_app" style={{ height: 70, borderBottomColor: "rgba(255,255,255,0.1)" }}>
        <img src={profile_circle} className="secret_list_profile_circle" style={{ marginLeft: 18 }} />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: 18 }}>
          <span className="settings_email_text">
            {account?.email}
          </span>
          <div onClick={() => {
            navigate("/settings");
            mixpanel.track("ASO_HEADER_ACCOUNT_DROPDOWN_SETTINGS_CLICK");
          }} className="settings_settings_text_container cursor">
            <img src={settings_mini} className="settings_settings_icon" />
            <span className="settings_settings_text">
              Settings
            </span>
          </div>

        </div>

      </div>
      {showConnection && <div style={{ maxHeight: 360, overflow: "scroll", borderBottomColor: "rgba(255,255,255,0.1)", paddingTop: 16, borderBottomWidth: 1, borderBottomStyle: "solid" }}>
        {
          secrets.map((item, index) => {
            return (
              <div onClick={async () => {
                const app = await appStore.setSelectedSecret(item);
                mixpanel.track("ASO_HEADER_ACCOUNT_DROPDOWN_SECRET_CLICK");
                if (!app) {
                  await appStore.getAllApps(item._id);
                  navigate(`/settings?tab=apps`);
                } else {
                  console.log("🚀 ~ file: index.js:72 ~ <divonClick={ ~ window.location:", window.location.href)
                  if (window.location.href.includes("settings")) {
                    onClose();
                  } else {
                    navigate(`/detail/${app._id}`)
                    onClose();
                  }
                }
              }} key={"selectedSecret#" + index} className="secret_list_secret_item cursor">
                {selectedSecret._id === item._id ? <img className="all_apps_app_tick" src={tick} /> : <img className="all_apps_app_tick" src={tick} style={{ opacity: 0 }} />}
                <span className="secret_list_secret_item_text">
                  {item.name}
                </span>
              </div>
            )
          })
        }
      </div>}
      <Link onClick={() => {
        mixpanel.track("ASO_HEADER_ACCOUNT_DROPDOWN_ADD_SECRET_CLICK");
      }} to={"/register/connection"} className="add_new_store_connection_button cursor" style={{ marginTop: 20, marginBottom: 20 }}>
        <img src={add_icon} className="all_apps_add_new_app_icon" style={{ marginLeft: 18 }} />
        <span className="settings_settings_text" style={{ marginLeft: 8 }}>
          Add New Store Connection
        </span>
      </Link>
      <div className="" style={{ marginBottom: 15, borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "rgba(255,255,255,0.1)" }}>
        <Link to={"mailto:hello@aso.studio"} onClick={() => {
          mixpanel.track("ASO_HEADER_ACCOUNT_DROPDOWN_HELP_CENTER_CLICK");
        }} className="add_new_store_connection_button cursor" style={{ marginTop: 20, marginBottom: 15, alignItems: "center" }}>
          <img src={help} className="all_apps_add_new_app_icon" style={{ marginLeft: 18, objectFit: "contain" }} />
          <span className="settings_settings_text" style={{ marginLeft: 8 }}>
            Help Center
          </span>
        </Link>
        <div onClick={() => {
          mixpanel.track("ASO_HEADER_ACCOUNT_DROPDOWN_LOGOUT_CLICK");
          accountStore.logout();
        }} className="add_new_store_connection_button cursor" style={{ marginTop: 10, marginBottom: 5, alignItems: "center" }}>
          <img src={exit} className="all_apps_add_new_app_icon" style={{ width: 20, height: 20, marginLeft: 20, objectFit: "contain" }} />
          <span className="settings_settings_text" style={{ marginLeft: 8 }}>
            Logout
          </span>
        </div>
      </div>
    </div>
  )
});

const Header = observer(({ showConnection = true, pathKey = "detail" }) => {

  const { secrets, selectedSecret, selectedApp } = appStore;
  const { account } = accountStore;

  useEffect(() => {
    if (secrets.length <= 0) return;
    const s = secrets.find((_s) => {
      return _s._id === selectedApp?.secret
    })
    if (s && selectedSecret._id !== s?._id) {
      appStore.setSelectedSecret(s);
    }
  }, [])

  const divRef = useRef()

  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const getTrialDiff = () => {
    const date1 = new Date(account?.subscription?.expireAt);
    const date2 = new Date();
    const diffTime = date1 - date2
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  return (
    <div className='header_container'>
      <div onClick={async () => {
        if (appStore.secrets.length === 0) {
          return;
        }
        if (appStore.apps.length > 0) {
          navigate(`/detail/${appStore.apps[0]._id}`);
        } else {
          const app = await appStore.getApps();
          navigate(`/detail/${app._id}`);
        }
      }} >
        <img className="logo cursor" src={logo} alt="aso.studio" />
      </div>
      <div className="contents">
        <div onClick={async () => {
          if (pathKey === "register") return null
          const { secrets, apps, selectedApp } = appStore;
          if (selectedApp) {
            navigate(`/detail/${selectedApp._id}`);
            return
          }
          if (secrets.length === 0) {
            return;
          }
          mixpanel.track("ASO_HEADER_APP_LISTING_CLICK");
          if (apps.length > 0) {
            navigate(`/detail/${apps[0]._id}`);
          } else {
            const app = await appStore.getApps();
            navigate(`/detail/${app._id}`);
          }
        }} className={pathKey === "detail" ? "header_button cursor" : "header_button_unselected cursor"}>
          <span className={pathKey === "detail" ? "header_button_text" : "header_button_text_unselect"}>
            App Listing
          </span>
        </div>
        <div onClick={async () => {
          if (pathKey === "register") return null
          const { secrets, apps, selectedApp } = appStore;
          if (selectedApp) {
            navigate(`/reviews/${selectedApp._id}`);
            return
          }
          if (secrets.length === 0) {
            return;
          }
          mixpanel.track("ASO_HEADER_APP_LISTING_CLICK");
          if (apps.length > 0) {
            navigate(`/reviews/${apps[0]._id}`);
          } else {
            const app = await appStore.getApps();
            navigate(`/reviews/${app._id}`);
          }
        }} className={pathKey === "rating" ? "header_button cursor" : "header_button_unselected cursor"}>
          <span className={pathKey === "rating" ? "header_button_text" : "header_button_text_unselect"}>
            Reviews
          </span>
        </div>
        <div onClick={async () => {
          if (pathKey === "register") return null
          const { secrets, apps, selectedApp } = appStore;
          if (selectedApp) {
            navigate(`/pricing/${selectedApp._id}`);
            return
          }
          if (secrets.length === 0) {
            return;
          }
          mixpanel.track("ASO_HEADER_APP_LISTING_CLICK");
          if (apps.length > 0) {
            navigate(`/pricing/${apps[0]._id}`);
          } else {
            const app = await appStore.getApps();
            navigate(`/pricing/${app._id}`);
          }
        }} className={pathKey === "pricing" ? "header_button cursor" : "header_button_unselected cursor"}>
          <span className={pathKey === "pricing" ? "header_button_text" : "header_button_text_unselect"}>
            Pricing Editor
          </span>
        </div>
        {account?.subscription?.isTrial ? <div style={{ display: "flex", flex: 1, alignSelf: "center", alignItems: "center", justifyContent: "flex-end" }} >
          <img src={timer} className="free_trial_timer_icon" />
          <span className="free_trial_timer_text" >
            {getTrialDiff()} days left to <span style={{ fontWeight: 600 }} >FREE TRIAL</span>
          </span>
          <span className={"heroTryButtonOuter"} >
            <Link onClick={() => {
              mixpanel.track("ASO_HEADER_FREE_TRIAL_SUBSCRIBE_CLICK", { daysLeft: getTrialDiff() });
            }} to={"/settings?tab=billing"} className={"heroTryButton"} >Subscribe</Link>
          </span>

        </div> : <div style={{ flex: 1 }} />}
        <div>
        </div>
        <div ref={divRef} onClick={() => {
          setShow(!show)
          if (!show) {
            mixpanel.track("ASO_HEADER_ACCOUNT_DROPDOWN_CLICK");
          }
        }} className="header_button_secret cursor" >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="header_button_text_title">
              {account?.name}
            </span>
            {showConnection && <span className="header_button_text_subtitle">
              {selectedSecret?.name}
            </span>}
          </div>
          {show ?
            <SecretList showConnection={showConnection} onClose={() => setShow(false)} divRef={divRef} right={0} top={50} /> : null}
          <img className="header_button_secret_arrow" src={arrow} />
        </div>
      </div>
    </div>
  );
});

export default Header;
