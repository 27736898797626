import axios from "axios";
import { Store } from 'react-notifications-component';
// CHECK: 

const SERVER = "https://api.aso.studio/v1/";
// const SERVER = "http://192.168.1.128:5001/v1/";
// const SERVER = "http://78.188.219.8:5001/v1/";

export default async function request(
  url,
  method = "GET",
  data = null
) {

  let REQ_URL = `${SERVER}${url}`;

  if ((method.toLocaleLowerCase() === "get") && data) {
    let keys = Object.keys(data);
    REQ_URL += "?";
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      REQ_URL += key + "=" + data[key] + "&";
    }
  }

  try {
    const resp = await axios(REQ_URL, {
      method: method,
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
      },
      data,
    });
    console.log(`[RESPONSE FOR] => ${url} `, resp.data);
    return resp.data;
  } catch (e) {
    if (e.response.status === 401) {
      localStorage.removeItem("token")
      window.location.replace("/login")
      return;
    } else if (e.response.status === 500) {
      if (!e.response.request.responseURL.includes("login") && !e.response.request.responseURL.includes("register")) {
        Store.addNotification({
          title: "Error",
          message: e.response.data.message,
          container: "top-right",
          insert: "top",
          type: "danger",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
      }
      return e.response.data;
    }
    //

    alert("Something went wrong")
  }




}