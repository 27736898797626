const mpanel = require("mixpanel-browser");

class Mixpanel {

  TOKEN = "29a1bbf5c613659ff006e7582bd4ef11";
  DEBUG = false;

  logger = (event, props) => {
    if (!this.DEBUG) return null;
    console.log(`[MIXPANEL] event => ${event}`);
    props && console.log(`[MIXPANEL PROPS] ${event} =>`, props);
  }

  init = () => {
    mpanel.init(this.TOKEN, { track_pageview: true, debug: this.DEBUG })
  }

  register = (account) => {
    mpanel.people.set({ $email: account.email, name: account.name })
  }

  track = (event_name, props = null) => {
    this.logger(event_name, props)
    mpanel.track(event_name, props);
  }

  identify = (id) => {
    mpanel.identify(id);
  }

}

const mixpanel = new Mixpanel();
export default mixpanel;