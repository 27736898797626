import CustomInput from '../../../components/CustomInput';
import './index.css';
import { observer } from "mobx-react-lite"
import appStore from '../../../stores/appStores';
import { MAX_LENGTH } from '../../../helpers/statics';
import warning_icon from "../../../assets/warning.webp";
import mixpanel from '../../../helpers/mixpanel';
import { useEffect } from 'react';

const Dups = () => {

  useEffect(() => {
    mixpanel.track("ASO_DUPLICATE_FOUND");

    return () => {
      console.log("asdlaşs");
      mixpanel.track("ASO_DUPLICATE_REMOVED");
    }
  }, [])

  return (
    <div style={{
      "--animate-duration": "1000ms"
    }} className='duplicate_container animate__fadeIn animate__animated'>
      <img className='warning_icon' src={warning_icon} />
      <span className='duplicate_text'>
        There are repeating words in the metadata. Not recommended for best ASO performance.
      </span>
    </div>
  )
}

const DuplicateBadge = observer(() => {

  const { duplicates } = appStore;

  if (
    duplicates.keywords.length > 0 ||
    duplicates.name.length > 0 ||
    duplicates.subtitle.length > 0
  ) {
    return (
      <Dups />
    )
  }
})

function Metadata() {
  const { editableVersion, locale } = appStore
  const editable = editableVersion?.editable;

  useEffect(() => {
    mixpanel.track("ASO_APP_DETAIL_METADATA_VIEW");

    return () => {

    }
  }, [])


  const metadata = editableVersion?.metadata[locale];

  if (!metadata) {
    return null;
  }

  return (
    <div className='metadata-container' >
      <DuplicateBadge />
      <div style={{ height: 16 }} />
      <CustomInput
        key={`metadata###${editableVersion.versionString}name###${locale}`}
        editable={editable}
        placeholder={"Name"}
        maxLength={MAX_LENGTH["name"]}
        onChange={(e) => appStore.editSelectedMetadataWithKey("name", e)}
        value={metadata.name}
        editKey={"name"}
        showEdited
        title={"Name"} />
      <CustomInput
        key={`metadata###${editableVersion.versionString}subtitle###${locale}`}
        editable={editable}
        placeholder={"Subtitle"}
        editKey={"subtitle"}
        showEdited
        maxLength={MAX_LENGTH["subtitle"]}
        onChange={(e) => appStore.editSelectedMetadataWithKey("subtitle", e)}
        value={metadata.subtitle}
        title={"Subtitle"} />
      <CustomInput
        key={`metadata###${editableVersion.versionString}keywords###${locale}###${appStore.keywordUpdateAt}`}
        editable={editable}
        placeholder={"Keywords"}
        separated
        onChange={(e) => appStore.editSelectedMetadataWithKey("keywords", e)}
        maxLength={MAX_LENGTH["keywords"]}
        editKey={"keywords"}
        value={metadata.keywords}
        title={"Keywords"} />
      <CustomInput
        key={`metadata###${editableVersion.versionString}description###${locale}`}
        editable={editable}
        placeholder={"Description"}
        maxLength={MAX_LENGTH["description"]}
        onChange={(e) => appStore.editSelectedMetadataWithKey("description", e)}
        value={metadata.description}
        multiline
        title={"Description"} />
      <CustomInput
        key={`metadata###${editableVersion.versionString}promotionalText###${locale}`}
        editable={editable}
        placeholder={"Promotional Text"}
        maxLength={MAX_LENGTH["promotionalText"]}
        onChange={(e) => appStore.editSelectedMetadataWithKey("promotionalText", e)}
        value={metadata.promotionalText}
        title={"Promotional Text"} />
      <CustomInput
        key={`metadata###${editableVersion.versionString}whatsNew###${locale}`}
        editable={editable}
        placeholder={"What's New in This Version"}
        maxLength={MAX_LENGTH["whatsNew"]}
        onChange={(e) => appStore.editSelectedMetadataWithKey("whatsNew", e)}
        value={metadata.whatsNew}
        multiline
        title={"What's New in This Version"} />
      <div className='metadata-input-row' >
        <CustomInput
          key={`metadata###${editableVersion.versionString}privacyPolicyText###${locale}`}
          editable={editable}
          placeholder={"Privacy Policy Text"}
          onChange={(e) => appStore.editSelectedMetadataWithKey("privacyPolicyText", e)}
          value={metadata.privacyPolicyText}
          extraStyle={{ marginRight: 32 }}
          title={"Privacy Policy Text"} />
        <CustomInput
          key={`metadata###${editableVersion.versionString}privacyPolicyUrl###${locale}`}
          editable={editable}
          placeholder={"Privacy Policy URL"}
          onChange={(e) => appStore.editSelectedMetadataWithKey("privacyPolicyUrl", e)}
          value={metadata.privacyPolicyUrl}
          title={"Privacy Policy URL"} />
      </div>
      <div className='metadata-input-row' >
        <CustomInput
          key={`metadata###${editableVersion.versionString}marketingUrl###${locale}`}
          editable={editable}
          placeholder={"Marketing URL"}
          onChange={(e) => appStore.editSelectedMetadataWithKey("marketingUrl", e)}
          value={metadata.marketingUrl}
          extraStyle={{ marginRight: 32 }}
          title={"Marketing URL"} />
        <CustomInput
          key={`metadata###${editableVersion.versionString}supportUrl###${locale}`}
          editable={editable}
          placeholder={"Support URL"}
          onChange={(e) => appStore.editSelectedMetadataWithKey("supportUrl", e)}
          value={metadata.supportUrl}
          title={"Support URL"} />
      </div>
      <CustomInput
        key={`metadata###${editableVersion.versionString}privacyChoicesUrl###${locale}`}
        editable={editable}
        placeholder={"Privacy Choices URL"}
        onChange={(e) => appStore.editSelectedMetadataWithKey("privacyChoicesUrl", e)}
        value={metadata.privacyChoicesUrl}
        title={"Privacy Choices URL"} />
    </div>
  );
}

export default observer(Metadata);
