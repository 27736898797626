import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import CustomInput from "../../../components/CustomInput";
import accountStore from "../../../stores/accountStore";
import mixpanel from "../../../helpers/mixpanel";

const Account = (() => {
  const { account } = accountStore;

  useEffect(() => {
    mixpanel.track("ASO_SETTINGS_ACCOUNT_TAB_VIEW");

  }, [])


  const [name, setName] = useState(account.name.toString())
  const [email, setEmail] = useState(account.email.toString())

  return (
    <div className="setting_page_container">
      <div className="settings_page_title_container">
        <span className="setting_page_title">
          Account
        </span>
        <Button style={{ backgroundColor: "white" }} textStyle={{ color: "black" }} text="Save" />
      </div>
      <div className="settings_account_container">
        <CustomInput onChange={(t) => setName(t)} copy={false} editable title={"Full Name"} placeholder={"Full Name"} value={name} />
        <CustomInput onChange={(t) => setEmail(t)} copy={false} editable title={"Work Email"} placeholder={"Work Email"} value={email} />
        <span className="setting_custom_title">
          Irreversible Action
        </span>
        <span className="setting_custom_desc">
          If you delete the account, you cannot get it back. If you have a problem, you can send us a request on the <span style={{ color: "white" }}>"Contact Us"</span> page.
        </span>
        <Button text="Delete Account" onClick={() => {
        }} style={{ backgroundColor: "transparent" }} textStyle={{ color: "#C1173C" }} className="delete_button" />
      </div>
    </div>
  )
});

export default Account;
