import { makeAutoObservable } from "mobx"
import request from "../helpers/request";
var CryptoJS = require("crypto-js");

class AccountStore {

  account = null;
  manageSubscriptionLink = null;

  constructor() {
    makeAutoObservable(this)
  }

  setAccount(account) {
    this.account = account;
    // window.Tawk_API.visitor = {
    //   name: account.name,
    //   email: account.email
    // };
  }

  logout() {
    localStorage.removeItem("token")
    window.location.replace("/login")
  }

  getMonthlySubscriptionLink = async (id) => {
    const resp = await request(`account/create/subscription/session/${id}`);
    window.location.replace(resp.url)
  }

  getManageSubscriptionLink = async () => {
    const resp = await request("account/manage/subscription");
    return resp.url

  }

  async getAccount() {
    const resp = await request("account")
    this.account = resp.account;
    // window.Tawk_API.visitor = {
    //   name: resp.account.name,
    //   email: resp.account.email
    // };
  }
}

const accountStore = new AccountStore();
export default accountStore;