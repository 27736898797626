import { observer } from 'mobx-react-lite';
import three_dots from "../../assets/three_dots.webp";
import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import "./index.css";

const ThreeDot = forwardRef(({children},ref) => {

  const [showTooltip, setShowTooltip] = useState(false);
  const divRef = useRef();
  const _self = useRef()

  useImperativeHandle(ref, () => ({
    closeModal(){
      setShowTooltip(false);
    }
  }));

  const eventAction = (e) => {
    if (divRef.current?.contains(e.target) || _self.current?.contains(e.target)) {
      return;
    }
    setShowTooltip(false);
  }

  useEffect(() => {
    document.addEventListener('click', eventAction);
    return () => {
      document.removeEventListener('click', eventAction);
    }
  }, [])

  

  return(
    <>
      <img ref={divRef} onClick={() => {
        setShowTooltip(!showTooltip);
      }} className="three_dot_icon_settings cursor" src={three_dots}/>
      { showTooltip ? 
        <div ref={_self} className='three_dot_tooltip'>
          {children}
        </div>
       : null}
    </>
  )
});

export default ThreeDot;