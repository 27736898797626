import "./index.css"
import SearchIcon from "../../assets/search.webp"

const Search = ({ onChange, placeholder }) => {
  return (
    <div className='search-container' >
      <img src={SearchIcon} className='search-icon' />
      <input onChange={(e) => {
        onChange(e);
      }} type="text" placeholder={placeholder} className='search-input' />
    </div>
  )
}

export default Search