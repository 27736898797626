import "./index.css"


import _bg from "../../assets/trial_end_gradient.webp";

import trial_end from "../../assets/logo_trial.webp";
import { useEffect, useState } from "react";
import accountStore from "../../stores/accountStore";
import blue_dot from "../../assets/blue_dot.webp";
import red_dot from "../../assets/red_dot.webp";
import staticStore from "../../stores/staticStore";
import { observer } from "mobx-react-lite";
import mixpanel from "../../helpers/mixpanel";

const CardDot = ({ text, icon }) => {
  return (
    <div className="dot_container">
      <img className="dot_style" src={icon} />
      <span className="dot_text">
        {text}
      </span>
    </div>
  )
}

const BlueCard = ({ id, amount }) => {
  return (
    <div className="blue_card">
      <div className="plan_title_container">
        <span className="blue_text_gradient">
          Starter
        </span>
      </div>
      <span className="plan_card_desc">
        Suitable for developers with a small number of apps
      </span>
      <span style={{ fontWeight: 500, fontSize: 80, color: "white", marginTop: 50 }}>
        <span style={{ color: "#3896E4", fontSize: 24, fontWeight: 400, verticalAlign: "text-top" }}>$</span>{Math.floor(amount / 100)}.<span style={{ fontSize: 48 }}>{amount % 100}</span>
      </span>
      <div className="dash_line" />
      <CardDot icon={blue_dot} text={<span>Total <span style={{ fontWeight: 600 }}>1 app</span> limit</span>} />
      <CardDot icon={blue_dot} text={"Access All Features"} />
      <ChooseButton onClick={async () => {
        mixpanel.track("ASO_TRIAL_END_BILLING_STARTER_CLICK");
        await accountStore.getMonthlySubscriptionLink(id)
      }} backgroundColor={"#426BFD"} />
    </div>
  )
}

const BlueCardV2 = () => {
  return (
    <div className="blue_card_v2">
      <div className="plan_title_container">
        <span className="blue_text_gradient">
          Need More?
        </span>
        <span className="plan_card_desc">
          Suitable for developers and teams with many apps
        </span>
      </div>
      <span className="blue_card_v2_custom_pricing" >
        Customized
        <br />
        <span style={{ fontWeight: 400 }} >
          Pricing
        </span>
      </span>
      <div className="plan_bottom_container" >
        <span className="blue_card_v2_desc" >
          After an online consultation, we will offer you a customized package that best suits your needs
        </span>
        <a onClick={() => {
          mixpanel.track("ASO_TRIAL_END_BILLING_ADVANCED_CLICK");
        }} target="_top" href="mailto:hello@aso.studio?subject=About custom pricing!" className="blue_card_v2_button cursor">
          <span className="blue_card_v2_button_text">
            Get in Touch
          </span>
        </a>
      </div>
    </div>
  )
}

const ChooseButton = ({ backgroundColor, onClick }) => {
  return (
    <div onClick={onClick} style={{ backgroundColor }} className="plan_choose_button cursor">
      <span className="plan_choose_text">
        Choose
      </span>
    </div>
  )
}

const RedCard = ({ amount, id }) => {
  return (
    <div style={{ alignItems: "center", flex: 2 }} className="plan_container_content">
      <div className="red_card">
        <div className="plan_title_container">
          <span className="red_text_gradient">
            Advanced
          </span>
        </div>
        <span className="plan_card_desc">
          Suitable for developers and teams with many apps
        </span>
        <span style={{ fontWeight: 500, fontSize: 80, color: "white", marginTop: 50 }}>
          <span style={{ color: "#DD61A0", fontSize: 30, fontWeight: 400, verticalAlign: "text-top" }}>$</span>{Math.floor(amount / 100)}.<span style={{ fontSize: 54 }}>{amount % 100}</span>
        </span>
        <div className="dash_line" />
        <CardDot icon={red_dot} text={<span>Total <span style={{ fontWeight: 600 }}>10 apps</span> limit</span>} />
        <CardDot icon={red_dot} text={"Access All Features"} />
        <ChooseButton onClick={async () => {
          await accountStore.getMonthlySubscriptionLink(id)
        }} backgroundColor={"#DD61A0"} />
      </div>
    </div>
  )
}

const Toggle = ({ isMonthly, setIsMontly }) => {
  return (
    <div className="custom_toggle_trial_end">

      <div onClick={() => {
        mixpanel.track("ASO_TRIAL_END_MONTHLY_SWITCH_CLICK");
        setIsMontly(true)
      }} className={isMonthly ? "selected_toggle_trial_end cursor" : "unselected_toggle_trial_end cursor"}>
        <span className={isMonthly ? "selected_toggle_trial_end_text" : "unselected_toggle_trial_end_text"}>
          Monthly
        </span>
      </div>

      <div onClick={() => {
        mixpanel.track("ASO_TRIAL_END_YEARLY_SWITCH_CLICK");
        setIsMontly(false)
      }} className={!isMonthly ? "selected_toggle_trial_end cursor" : "unselected_toggle_trial_end cursor"}>
        <span className={!isMonthly ? "selected_toggle_trial_end_text" : "unselected_toggle_trial_end_text"}>
          Yearly
        </span>
      </div>

    </div>
  )
}

const TrialEnd = observer(() => {

  const { account } = accountStore;
  const { plans, defaultPlan } = staticStore

  useEffect(() => {
    if (!account) {
      accountStore.getAccount().then(() => {

      })
    }
    if (!plans) {
      staticStore.getStatics();
    }
  })
  const [isMonthly, setIsMontly] = useState(false);


  console.log("🚀 ~ file: index.js:127 TOP ~ TrialEnd ~ plans:", plans)
  if (!plans) return null;
  console.log("🚀 ~ file: index.js:127 ~ TrialEnd ~ plans:", plans)

  const currentAdvance = plans.Advanced[isMonthly ? "month" : "year"];
  const currentStarter = plans[defaultPlan][isMonthly ? "month" : "year"];


  return (
    <div className="trial_end_container" style={{ paddingBottom: 100 }} >

      <div className="trial_end_header_container">
        <img src={trial_end} className="trial_end_icon" />
      </div>
      <div className="trial_end_plan_container">
        <span className="trial_end_plan_pick_plan">
          Pick Your Plan
        </span>
        <span className="trial_end_plan_pick_plan_desc">
          Your 14-day free trial is over, you can choose what best for you
        </span>
        <Toggle isMonthly={isMonthly} setIsMontly={setIsMontly} />
        <div style={{ display: "flex", marginTop: 32, paddingLeft: 60, paddingRight: 60, marginBottom: 48 }}>
          <BlueCard {...currentStarter} />
          <BlueCardV2 {...currentAdvance} />
        </div>
      </div>
      <img src={_bg} className="trial_end_bg" />
    </div>
  )
});


export default TrialEnd;