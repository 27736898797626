import React, { useState } from 'react'
import "./index.css";
import close_icon from "../../assets/close2.webp"
import plus from "../../assets/plus.webp"
import delete_icon from "../../assets/delete.webp"
import appStore from '../../stores/appStores';
import copy_icon from "../../assets/copy-clickboard.webp";
import copy_done from "../../assets/copy-done.webp";
import modalStore from '../../stores/modalStore';
import question_mark from "../../assets/question_cirlce.webp";
import { Store } from 'react-notifications-component';
import mixpanel from '../../helpers/mixpanel';

const KeyCard = ({ keyword, index, editable, onRemove }) => {

  return (
    <div
      key={index}
      onClick={() => {
        onRemove(index);
      }}
      className='custom_input_tag_keyword_div'
    >
      <span
        className={editable ? "custom_input_tag_keyword" : "custom_input_tag_keyword_inactive"}
      >
        {keyword}
      </span>
      {editable && <img src={close_icon} className='custom_input_tag_close' />}
    </div>
  )
}

const DeletedKeyCard = ({ keyword, index, editable, onAdd }) => {

  return (
    <div
      key={index}
      onClick={() => {
        onAdd(keyword);
      }}
      className='custom_input_tag_keyword_deleted_div'
    >
      <span
        className={"custom_input_tag_keyword_deleted"}
      >
        {keyword}
      </span>
      <img src={plus} className='custom_input_tag_deleted_plus' />
    </div>
  )
}

const KeywordInput = ({ value = [], onChange, maxLength, editable, isSmart }) => {
  const [inputValue, setInputValue] = useState('');
  const [keywords, setKeywords] = useState(value);
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const [copyDone, setCopyDone] = useState(false);

  const handleInputChange = (e) => {
    if (keywords.toString().length + e.target.value.length + 1 > 100) return null
    if (inputValue === e.target.value && e.nativeEvent.inputType === "deleteContentBackward") return
    if (e.target.value === " ") return
    if (e.nativeEvent.inputType === "deleteContentBackward" & e.target.value === "") {
      // const _new = [...keywords];
      // let lastKeyword = _new.pop();
      // setKeywords(_new);
      // onChange(_new.toString() + ',' + lastKeyword)
      // setInputValue(lastKeyword);
      // return
    }
    if (e.nativeEvent.inputType === "insertFromPaste") {
      let l = keywords.toString().length;
      let le = e.target.value.length;
      if (l + le > maxLength) return
      var replaced = e.target.value.trim().replace(/(^,)|(,$)/g, "");
      let tags = replaced.split(/[\s,]+/u);
      let _new = [...keywords];
      for (let i = 0; i < tags.length; i++) {
        const tag = tags[i];
        if (keywords.includes(tag)) {
          Store.addNotification({
            title: "Duplicate Keyword",
            message: "The keyword you write is already included!",
            container: "top-right",
            insert: "top",
            type: "warning",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          })
          continue
        }
        _new.push(tag);
      }
      setKeywords(_new);
      onChange(_new.toString())
      setInputValue("");
      return
    }
    if (e.nativeEvent.data === "," || e.nativeEvent.data === " ") return null
    if (e.target.value === "") {
      setInputValue(e.target.value);
      onChange("")
      return null
    }
    setInputValue(e.target.value);
    onChange(keywords.toString() + "," + e.target.value)
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      if (keywords.includes(inputValue)) {
        Store.addNotification({
          title: "Duplicate Keyword",
          message: "The keyword you write is already included!",
          container: "top-right",
          insert: "top",
          type: "warning",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
        return
      }
      const _new = [...keywords, inputValue.trim()];
      setKeywords(_new);
      onChange(_new.toString())
      setInputValue('');
    } else if (e.key === " " || e.key === ",") {
      if (!e.target.value) return
      if (keywords.includes(inputValue)) {
        Store.addNotification({
          title: "Duplicate Keyword",
          message: "The keyword you write is already included!",
          container: "top-right",
          insert: "top",
          type: "warning",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
        return
      }
      const _new = [...keywords, inputValue.trim()];
      setKeywords(_new);
      onChange(_new.toString())
      setInputValue('');
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Backspace" && inputValue.length === 1) return
    if (e.key === "Backspace" && !e.target.value) {
      let lastKeyword = keywords.pop();
      setKeywords(keywords);
      onChange(keywords.toString())
      setInputValue(lastKeyword);

    }
  };

  const removeWithIndex = (index) => {
    let _new = [...keywords];
    _new.splice(index, 1);
    setKeywords(_new);
    onChange(_new.toString() + ",")
  }

  const addBack = (keyword) => {
    let string = keywords.toString();
    let newString = string + ',' + keyword
    if (newString.length > maxLength) {
      return
    }
    const _new = [...keywords, keyword.trim()];
    setKeywords(_new);
    onChange(_new.toString())
  }

  const renderDeletedList = () => {
    const { current, locale } = appStore;
    let currentKeys = current?.metadata?.[locale]?.keywords?.split(/[\s,]+/u);
    const diff = currentKeys?.filter((key) => !keywords.includes(key));
    if (!diff) return null
    return (
      <div style={{ width: "100%", minHeight: 50, alignItems: "flex-start", backgroundColor: "transparent", justifyContent: "space-between" }} className={editable ? 'custom_tag_input_container' : 'custom_tag_input_container custom_tag_input_container_inactive'} >
        <div style={{ padding: "8px 8px", }} className='keywords-grid' >
          {diff.map((keyword, index) => (
            <DeletedKeyCard key={"keyword#" + index} onAdd={addBack} keyword={keyword} index={index} editable={editable} />
          ))}
        </div>
        {maxLength > 0 && <span className='keyword_input_maxlength' >{`${maxLength - (value?.toString()?.length)}/${maxLength}`}</span>}
      </div>
    )
  }

  const renderList = () => {
    return (
      <>
        {keywords.map((keyword, index) => (
          <KeyCard key={"keyword#" + index} onRemove={removeWithIndex} keyword={keyword} index={index} editable={editable} />
        ))}
      </>
    )
  }
  console.log("first", keywords.toString().length)
  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }} >
      <div style={{ borderColor: focus ? "white" : "transparent", width: "100%", marginBottom: 12 }} className={editable ? 'custom_tag_input_container' : 'custom_tag_input_container custom_tag_input_container_inactive'} >
        <input
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          type="text"
          disabled={!editable}
          className={editable ? "custom_input_tag" : "custom_input_tag custom_input_tag_inactive"}
          placeholder="Enter a keyword"
          value={inputValue}
          maxLength={maxLength - (keywords.toString().length + 1)}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />
      </div>
      <div
        onMouseEnter={() => {
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
        style={{ width: "100%", minHeight: 50, backgroundColor: "#101022" }} className={editable ? 'custom_tag_input_container' : 'custom_tag_input_container custom_tag_input_container_inactive'} >
        <div style={{ padding: "8px 8px", paddingRight: 80 }} className='keywords-grid' >
          {renderList()}
        </div>
        {hover &&
          <div onClick={() => {
            modalStore.setContentSure(
              "Delete Keywords",
              question_mark,
              "Are you sure you want to clear your keywords?",
              "Yes, Clear",
              "Cancel",
              async () => {
                setKeywords([]);
                onChange("")
              }
            )
          }} style={{ top: 74 }} className='copy-to-clickboard-container cursor' >
            <img className='copy-to-clickboard-image' src={delete_icon} />
          </div>}
        {hover &&
          <div onClick={() => {
            window.navigator.clipboard.writeText(value);
            setCopyDone(true);
            mixpanel.track("ASO_COPY_CLICK", { field: "Keywords" })
            setTimeout(() => {
              setCopyDone(false);
            }, 1000);
          }} style={{ top: 74, right: 46 }} className='copy-to-clickboard-container cursor' >
            <img className='copy-to-clickboard-image' src={copyDone ? copy_done : copy_icon} />
          </div>}
      </div>
      {renderDeletedList()}
    </div>
  )
}

export default KeywordInput