import "./index.css";

import arrow from "../../assets/bottom_arrow_white.webp";
import add_icon from "../../assets/add_icon.webp";
import tick from "../../assets/tick.webp";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite"
import appStore from "../../stores/appStores";
import request from "../../helpers/request";
import smartEditorStore from "../../stores/smartEditorStore";
import staticStore from "../../stores/staticStore";
import DropdownModal from "../DropdownModal";
import mixpanel from "../../helpers/mixpanel";

const AllFilters = observer(({ left, top, divRef, onClose, isOpen }) => {

  const _self = useRef()

  const { smartEditor } = staticStore;
  const { selectedFilter } = smartEditorStore;

  const eventAction = (e) => {
    if ((divRef.current?.contains(e.target) && true) || _self.current?.contains(e.target)) {
      return;
    }
    const k = divRef.current?.contains(e.target);
    onClose();
  }


  useEffect(() => {
    document.addEventListener('click', eventAction);
    return () => {
      document.removeEventListener('click', eventAction);
    }
  }, [])

  return (
    <div ref={_self} className="all_apps_container" style={{ left: left - 100, top: top + 60 }}>

      {
        smartEditor.map((item, index) => {
          return (
            <div onClick={async () => {
              smartEditorStore.setSelectedFilter(item)
              onClose()
            }} key={"selectedVersion#" + index} className="all_apps_app_container cursor">
              {selectedFilter.field === item.field ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
              <span className="all_apps_app_text">
                {item.displayName}
              </span>
            </div>
          )
        })
      }
    </div>
  )
});

const SmartEditorFilterButton = observer(() => {

  const { selectedFilter } = smartEditorStore;
  const { smartEditor } = staticStore;
  const divRef = useRef()

  useEffect(() => {
    smartEditorStore.setSelectedFilter(smartEditor[0]);
  }, [])

  const [show, setShow] = useState(false);

  return (
    <div onClick={() => {
      setShow(!show);
      if (!show) {
        mixpanel.track("ASO_SMART_EDITOR_FIELD_CLICK");
      }
    }} ref={divRef} className="country_button cursor">
      <span className="country_name">
        {(selectedFilter && selectedFilter.displayName) ? selectedFilter.displayName : ""}
      </span>
      <img src={arrow} className="country_bottom_arrow" />
      {
        show ? <DropdownModal
          data={() => {
            return smartEditor.map((item, index) => {
              return (
                <div onClick={async () => {
                  mixpanel.track("ASO_SMART_EDITOR_FIELD_SELECT", { field: item.field });
                  smartEditorStore.setSelectedFilter(item)
                  setShow(false);
                }} key={"selectedVersion#" + index} className="all_apps_app_container cursor">
                  {selectedFilter.field === item.field ? <img className="all_apps_app_tick" src={tick} /> : <div style={{ width: 40 }} />}
                  <span className="all_apps_app_text">
                    {item.displayName}
                  </span>
                </div>
              )
            })
          }}
          onClose={() => {
            setShow(false)
          }} divRef={divRef} right={-10} top={40} /> : null
      }
    </div>
  )
});

export default SmartEditorFilterButton;