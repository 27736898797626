import "./index.css";
import Search from "../../../components/Search"
import TableHeader from "../../../components/TableHeader";
import Selector from "../../../components/Selector";
import RightArrow from "../../../assets/right-arrow.webp";
import SubsEmpty from "../../../assets/sub-empty.webp";
import DropLine from "../../../assets/DropLine.webp";
import LLine from "../../../assets/l-line.webp";
import { useState } from "react";
import modalStore from "../../../stores/modalStore";
import PricingEditModal from "../../../components/PricingEditModal";
import pricingStore from "../../../stores/pricingStore";
import { observer } from "mobx-react-lite";
import { FormattedNumber } from 'react-intl';
import PricingTemplateModal from "../../../components/PricingTemplateModal";
import templateStore from "../../../stores/templateStore";
import { SUB_PERIODS, SUB_STATES } from "../../../helpers/statics";
import Button from "../../../components/Button";
import { useParams } from "react-router-dom";

const TableHeaderRow = () => {
  return (
    <div>
      <Search onChange={(e) => {
        pricingStore.searchReference(e.target.value);
      }} placeholder={"Search Reference"} />
      <TableHeader
        firstItem={"Reference Name"}
        items={[
          "Subscriptions",
          "Template"
        ]} />
    </div>
  )
}

const TableRow = ({ item }) => {
  const { templates } = templateStore;
  const [open, setOpen] = useState(false);
  const templateCount = item?.subscriptions.filter(e => e.template);
  return (
    <div>
      <div onClick={() => {
        setOpen(!open);
      }} className="table-row-container" >
        <span className="table-row-item-title" style={{ flex: 2, marginLeft: 40 }} >
          {open ? <img src={DropLine} className="table-row-drop-arrow" ></img> : <img src={RightArrow} className="table-row-right-arrow" ></img>}
          {item.name}</span>
        <div className="table-header-right-container" style={{ flex: 2 }} >
          <span className="table-row-item-title"  >{item?.subscriptions.length}</span>
          <span className="table-row-item-title" style={{ color: "#B8B8BC" }} >{templateCount.length + "  Template(s) applied."}</span>
        </div>
      </div>
      {open && <div className="table-sub-row-container" >
        {item?.subscriptions.map(e => {
          const temp = templates.find(t => t._id === e.template)
          return (
            <div className="pricing-subs-table-sub-row-item-container" >
              <div className="pricing-subs-table-sub-row-item-left" >
                <img src={LLine} className="table-sub-row-item-line" ></img>
                <span onClick={() => {
                  pricingStore.getSubscriptionDetails(item.app, item._id, e.id);
                  modalStore.setContent(e.name, <PricingEditModal data={{ appId: item.app, groupId: item._id, subsId: e.id }} isAll={true} />, true, "scroll");
                }} className="table-sub-row-item-title" >{e.name}</span>
                <span className="table-sub-row-item-badge" >
                  {e.productId}
                </span>
                <span className="table-sub-row-item-badge" >
                  {SUB_PERIODS[e.subscriptionPeriod].label}
                </span>
                <span className="table-sub-row-item-badge" >
                  {SUB_STATES[e.state]?.label ? SUB_STATES[e.state]?.label : "Missing State"}
                </span>
              </div>
              <div className="pricing-subs-table-sub-row-item-right" >
                <span className="pricing-subs-table-sub-row-item-template" onClick={async () => {
                  await pricingStore.setTemplatePrices(null);
                  modalStore.setContent(e.name, <PricingTemplateModal data={{ appId: item.app, groupId: item._id, subsId: e.id, template: temp, basePrice: e.basePrice }} isAll={true} />, true, "scroll");
                }} >
                  {temp ? temp.name : "No Template"}
                </span>
              </div>
            </div>
          )
        })}
      </div>}
    </div>
  )
}

const EmptyScreen = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  return (
    <div className='subs-page-empty-container' >
      <img src={SubsEmpty} />
      <p>
        This screen you will be able to view subscription groups and subscription packages, edit the price and apply templates to subscriptions.
      </p>
      <p>
        View your data now by pressing Pull.
      </p>
      <Button
        loading={loading}
        onClick={async () => {
          setLoading(true);
          await pricingStore.pull(id);
          setLoading(false);
        }}
        color="white"
        style={{ marginLeft: 0 }}
        textStyle={{ color: "black", padding: "0px 50px" }}
        text="Pull"
      />
    </div>
  )
}

function Subs() {
  const { subscriptions, filteredSubs } = pricingStore;
  let array = filteredSubs ? filteredSubs : subscriptions;
  if (!subscriptions || subscriptions.length <= 0) {
    return <EmptyScreen />
  }
  return (
    <div>
      <TableHeaderRow />
      {
        array.map(e => {
          return <TableRow key={e._id} item={e} />
        })
      }
    </div>
  )

}

export default observer(Subs);