import { observer } from "mobx-react-lite"
import modalStore from "../../stores/modalStore"
import "./index.css"
import { useLockBodyScroll, useToggle } from 'react-use';
import 'animate.css';
import close from "../../assets/close.webp";
import React, { useEffect, useRef, useState } from "react";
import mixpanel from "../../helpers/mixpanel";

const AskSure = observer(() => {
  const [isLoading, setLoading] = useState(false);
  const { icon, text, positiveText, negativeText, cb } = modalStore;
  return (
    <div className="modal_sure_container">
      <img src={icon} className="modal_sure_icon" />
      <span className="modal_sure_text">
        {text}
      </span>
      <div className="modal_sure_button_container">
        <div className="modal_sure_button" onClick={() => {
          if (isLoading) return;
          modalStore.setShow(false)
        }}>
          <span style={{ backgroundColor: "#08081A", textAlign: "center" }} className="modal_sure_button_text modal_white_border cursor">
            {negativeText}
          </span>
        </div>

        <div style={{ backgroundColor: "white" }} className="modal_sure_button cursor" onClick={async () => {
          if (isLoading) return;
          setLoading(true)
          await cb();
          modalStore.setShow(false)
        }}>
          <span style={{ color: "#08081A", textAlign: "center" }} className="modal_sure_button_text modal_white_border">
            {isLoading ? <div class="lds-ring"><div style={{ borderColor: "#08081A transparent transparent transparent" }}></div><div style={{ borderColor: "#08081A transparent transparent transparent" }}></div><div style={{ borderColor: "#08081A transparent transparent transparent" }}></div><div style={{ borderColor: "#08081A transparent transparent transparent" }}></div></div> : positiveText}
          </span>
        </div>

      </div>
    </div>
  )
});

const Modal = observer(() => {
  const { show, children, title, isSure, isBig, scroll } = modalStore;

  const divRef = useRef();
  useLockBodyScroll(show);

  const eventAction = (e) => {
    if (!e.target.classList.contains("modal_container")) {
      return;
    }
    const { title } = modalStore;
    mixpanel.track("ASO_MODAL_CLOSE", { title });
    const k = divRef.current?.contains(e.target);
    modalStore.setShow(false);
  }



  useEffect(() => {
    document.addEventListener('click', eventAction);
    return () => {
      document.removeEventListener('click', eventAction);
    }
  }, [])

  if (!show) return null;

  const height = document.body.offsetHeight;

  const classname = isBig ? "modal_content_big" : "modal_content_small";

  return (
    <div ref={divRef} className="modal_container animate__animated animate__fadeIn" >
      <div className={classname}>
        <div className="modal_content_header">
          <span className="modal_content_header_title">
            {title}
          </span>
          <div onClick={() => {
            modalStore.setShow(false);
            mixpanel.track("ASO_MODAL_CLOSE", { title });
          }} className="modal_content_header_cross_container">
            <img className="modal_content_header_cross cursor" src={close} />
          </div>
        </div>
        <div className="modal_footer" style={{ overflow: scroll }} >
          {isSure ? <AskSure /> : children}
        </div>
      </div>
    </div>
  )
})

export default Modal;